import { message } from "antd";

export const dispatchKickSocket = (payload) => {
  const key = payload.firebaseId;

  message.loading(`Kicking user...`);

  fetch(
    `${process.env.REACT_APP_WEBSOCKET}dispatch/kick/${payload.firebaseId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ payloadId: payload.firebaseId })
    }
  )
    .then((response) => {
      message.destroy(key);

      if (response.status === 200) {
        message.success(`${payload.name} logged out of Nobul`);
      } else {
        message.error("Kicking failed. Please notify the devs");
      }
    })
    .catch(() => {
      message.destroy(key);

      message.error("dispatchKickSocket failed. Please notify the devs");
    });
};
