import React, { useMemo, useState, useCallback } from "react";
import { Popconfirm, Input } from "antd";

export const PopoverWithInput = ({
  title,
  onCancel,
  onBlacklist,
  inputProps,
  children
}) => {
  const [value, setValue] = useState();

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const onConfirm = useCallback(
    (event) => {
      onBlacklist(value, event.target);
    },
    [onBlacklist, value]
  );

  const content = useMemo(
    () => (
      <form id="popover-with-input-form">
        <div style={{ marginBottom: 6 }}>{title}</div>
        <Input onChange={onChange} {...inputProps} />
      </form>
    ),
    [inputProps, onChange, title]
  );

  return (
    <Popconfirm
      title={content}
      okText="Blacklist"
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      {children}
    </Popconfirm>
  );
};

export default PopoverWithInput;
