import {
  ADVISOR_SIGNIN_PENDING,
  ADVISOR_SIGNOUT_PENDING,
  ADVISOR_AUTHENTICATED,
  ADVISOR_LOGGED_OUT,
  ADVISOR_ONLINE,
  ADVISOR_OFFLINE,
  ADVISOR_TALLY,
  ADD_TO_CURRENT_THREADS,
  UPDATE_CURRENT_THREAD,
  SET_CURRENT_THREADS,
  ADD_TO_UNASSIGNED_THREADS,
  UPDATE_UNASSIGNED_THREAD,
  SET_UNASSIGNED_THREADS,
  SET_ACTIVE_THREAD,
  PRESET_ACTIVE_THREAD,
  UPDATE_ACTIVE_THREAD,
  TOGGLE_THREAD_DRAWER
} from "../actionTypes";

const defaultState = {
  authState: null,
  online: false,
  advisorFirebaseId: null,
  firebaseUid: null,
  firstName: null,
  lastName: null,
  email: null,
  userType: "advisor",
  userId: null,
  currentThreads: [],
  unassignedThreads: [],
  activeThread: {},
  activeThreadOpen: undefined,
  onlineAdvisors: null,
  total: null
};

// signout pending state ommitted for now. too fast to notice anything
const advisorsReducer = (advisor = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADVISOR_SIGNIN_PENDING:
      return {
        ...advisor,
        authState: "pending"
      };

    case ADVISOR_SIGNOUT_PENDING:
      return {
        ...advisor,
        authState: "pending"
      };

    case ADVISOR_AUTHENTICATED:
      const {
        advisorFirebaseId,
        firebaseUid,
        firstName,
        lastName,
        email,
        userType,
        status,
        userId
      } = payload;

      return {
        ...advisor,
        authState: "success",
        online: status === "online" ? true : false,
        userType,
        firstName,
        lastName,
        email,
        firebaseUid,
        advisorFirebaseId,
        userId
      };

    case ADVISOR_LOGGED_OUT:
      return {
        ...advisor,
        authState: "required",
        online: false,
        userType: null,
        firstName: null,
        advisorFirebaseId: null
      };

    case ADVISOR_ONLINE:
      return {
        ...advisor,
        online: true
      };

    case ADVISOR_OFFLINE:
      return {
        ...advisor,
        online: false
      };

    case SET_CURRENT_THREADS:
      return {
        ...advisor,
        currentThreads: payload
      };

    case SET_UNASSIGNED_THREADS:
      return {
        ...advisor,
        unassignedThreads: payload
      };

    case ADD_TO_CURRENT_THREADS:
      return {
        ...advisor,
        currentThreads: Object.keys(payload).length
          ? [{ ...payload }, ...advisor.currentThreads]
          : advisor.currentThreads
      };

    case ADD_TO_UNASSIGNED_THREADS:
      return {
        ...advisor,
        unassignedThreads: Object.keys(payload).length
          ? [{ ...payload }, ...advisor.unassignedThreads]
          : advisor.unassignedThreads
      };

    case UPDATE_CURRENT_THREAD:
      const { message, threadId } = payload;

      return {
        ...advisor,
        currentThreads: advisor.currentThreads.map((thread) =>
          thread.threadId === threadId
            ? { ...thread, messages: [...thread.messages, { ...message }] }
            : thread
        )
      };

    case UPDATE_UNASSIGNED_THREAD:
      return {
        ...advisor,
        unassignedThreads: advisor.unassignedThreads.map((thread) =>
          thread.threadId === payload.threadId
            ? {
                ...thread,
                messages: [...thread.messages, { ...payload.message }]
              }
            : thread
        )
      };

    case SET_ACTIVE_THREAD:
      return {
        ...advisor,
        activeThread: payload
      };

    case PRESET_ACTIVE_THREAD:
      return {
        ...advisor,
        activeThread: {
          ...payload
        }
      };

    case UPDATE_ACTIVE_THREAD:
      return {
        ...advisor,
        activeThread: {
          ...advisor.activeThread,
          messages: [...advisor.activeThread.messages, payload]
        }
      };

    case TOGGLE_THREAD_DRAWER:
      return {
        ...advisor,
        activeThreadOpen: action.threadId
      };

    case ADVISOR_TALLY:
      const { onlineAdvisors, total } = payload;

      return {
        ...advisor,
        onlineAdvisors,
        total
      };

    default:
      return advisor;
  }
};

export default advisorsReducer;
