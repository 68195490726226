import React from "react";
import { useSelector } from "react-redux";
import {
  Empty,
  Avatar,
  Divider,
  Row,
  Col,
  Descriptions,
  Typography,
  Skeleton
} from "antd";
import startCase from "lodash/startCase";
import isPlainObject from "lodash/isPlainObject";
import isNull from "lodash/isNull";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import noAvatar from "assets/user.png";
import { convert2dollars, formatPhoneNumber, getPropertyType } from "helpers";

dayjs.extend(utc);

const ProfileTab = () => {
  const { bidSessions, consumerProfile, fetchingBidSessions } = useSelector(
    ({ consumersView }) => ({
      bidSessions: consumersView.bidSessions,
      consumerProfile: consumersView.consumerProfile,
      fetchingBidSessions: consumersView.fetchingBidSessions
    })
  );

  const { bidSessionItems, onboardingItems } = React.useMemo(() => {
    const { Item } = Descriptions;

    if (Array.isArray(bidSessions) && bidSessions.length) {
      const [bidSession] = bidSessions;
      // keys for the fields to display in the profile
      // add or remove fields as needed
      const bidSessionsKeys = Object.keys(bidSession).filter(
        (key) =>
          !~[
            "bid_session",
            "bids",
            "sell_attributes",
            "buy_attributes"
          ].indexOf(key)
      );

      const onboardingAttributeKeys = [
        "sell_attributes",
        "buy_attributes"
      ].filter((key) => Object.keys(bidSession[key] || {}).length);

      const handleValue = (value) => {
        if (typeof value === `boolean`) {
          return value ? `Yes` : `No`;
        }

        if (typeof value === `number` && !isNaN(value)) {
          return value;
        }

        return value || `N/A`;
      };

      const handleLabel = (label) => {
        if (label === `acceptance_window_ends_in`) {
          return `Acceptance Window`;
        }

        if (label === `transaction_type`) {
          return `Type`;
        }

        if (label === `manual_bid_count`) {
          return `Manual Bids`;
        }

        if (label === `bidding_ends_in`) {
          return `Deadline`;
        }

        if (label === `bid_session_start`) {
          return `Session Started`;
        }

        if (label === `bid_session_status`) {
          return `Session Status`;
        }

        if (label === `isNoAgentsArea`) {
          return `No Agents In Area`;
        }

        if (label === `quoteTimeLimit`) {
          return `Offer Deadline`;
        }

        if (label === `buy_attributes`) {
          return `Onboarding Buy Details`;
        }

        if (label === `sell_attributes`) {
          return `Onboarding Sell / Home Estimation Details`;
        }

        return startCase(label);
      };

      const onboardingMapper = (key) => {
        const field = bidSession[key];

        if (isPlainObject(field)) {
          if (key === `budget`) {
            return [
              [`Buy`, field.buy],
              [`Sell`, field.sell]
            ]
              .filter((values) => Boolean(values[1]))
              .map((values) => (
                <Item key={key} label={`${values[0]} Budget`}>
                  {values[1]}
                </Item>
              ));
          }

          if (key === `property_types`) {
            return [
              [`Buy`, field.buy],
              [`Sell`, field.sell]
            ]
              .filter((values) => Boolean(values[1]))
              .map((values) => (
                <Item key={key} label={`${values[0]} Property`}>
                  {Array.isArray(values[1]) ? values[1].join(" / ") : values[1]}
                </Item>
              ));
          }

          return (
            <Descriptions key={key} title={handleLabel(key)}>
              {Object.keys(field).map((subKey) => {
                const subField = field[subKey];

                if (subKey === `address`) {
                  return (
                    <Item key={subKey} label="Address">
                      {isPlainObject(subField)
                        ? subField.displayAddress
                        : subField}
                    </Item>
                  );
                }

                if (subKey === `moveInDate` || subKey === `moveOutDate`) {
                  return Object.keys(subField)
                    .filter((moveKey) => !isNull(subField[moveKey]))
                    .map((moveKey) => (
                      <Item key={moveKey} label={startCase(subKey)}>
                        {subField[moveKey]}
                      </Item>
                    ));
                }

                if (subKey === `priorities`) {
                  return (
                    <Item key={key} label="Priorities">
                      {subField ? subField.join(" > ") : handleValue(subField)}
                    </Item>
                  );
                }

                if (subKey === `features`) {
                  return (
                    <Item key={key} label="Features">
                      {subField ? subField.join(", ") : handleValue(subField)}
                    </Item>
                  );
                }

                // skip one of these since they need to be in pairs
                if (subKey === `buildingTypeId`) {
                  return null;
                }

                if (subKey === `propertyTypeId`) {
                  return (
                    <Item key={subKey} label="Property Type">
                      {handleValue(
                        getPropertyType({
                          propertyTypeId: field.propertyTypeId,
                          buildingTypeId: field.buildingTypeId
                        })?.caption.default
                      )}
                    </Item>
                  );
                }

                if (subKey === `preApprovedAmount`) {
                  return (
                    <Item key={key} label={handleLabel(subKey)}>
                      {convert2dollars(subField)}
                    </Item>
                  );
                }

                return (
                  <Item key={subKey} label={handleLabel(subKey)}>
                    {handleValue(subField)}
                  </Item>
                );
              })}
            </Descriptions>
          );
        }

        if (key === `quoteTimeLimit` || key === `bid_session_start`) {
          return (
            <Item key={key} label={handleLabel(key)}>
              {dayjs(field).format("MMM D, YYYY (h:mma)")}
            </Item>
          );
        }

        if (Array.isArray(key)) {
          return (
            <span key={key}>
              <Typography.Text>{bidSession[key]}</Typography.Text>
              <Divider type="vertical" />
            </span>
          );
        }

        return (
          <Item key={key} label={handleLabel(key)}>
            {handleValue(field)}
          </Item>
        );
      };

      return {
        bidSessionItems: bidSessionsKeys.map(onboardingMapper),
        onboardingItems: onboardingAttributeKeys.map(onboardingMapper)
      };
    } else {
      return (
        <Item>
          <Empty
            description="No deal data"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </Item>
      );
    }
  }, [bidSessions]);

  const profileItems = React.useMemo(() => {
    const { Item } = Descriptions;

    const profileKeys = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "timezone",
      "lookingFor",
      "registrationType",
      "verifiedPhone",
      "signupSource"
      // "userAddresses",
      // "brokerageAddresses",
      // "consentForNews",
      // "consentEmailNotification",
      // "event",
      // "proposal",
      // "posting",
      // "verified",
      // "businessProfileCreated",
      // "contest"
    ];

    return profileKeys.map((profileKey) => {
      let value = consumerProfile[profileKey] || "N/A";
      let label = startCase(profileKey);

      if (profileKey === "lookingFor" && value === "B") {
        value = "Buy";
      }

      if (profileKey === "lookingFor" && value === "S") {
        value = "Sell";
      }

      if (profileKey === "verifiedPhone") {
        value = startCase(value);
        label = "Phone Status";
      }

      if (profileKey === "signupSource") {
        value = startCase(value);
      }

      if (profileKey === `phone`) {
        value = formatPhoneNumber(value);
      }

      return (
        <Item key={profileKey} label={label}>
          {value}
        </Item>
      );
    });
  }, [consumerProfile]);

  const name = [consumerProfile.firstName, consumerProfile.lastName]
    .filter(Boolean)
    .join(" ") || <Typography.Text type="danger">"No Name"</Typography.Text>;

  const avatar = consumerProfile.profilePicturePath
    ? `${process.env.REACT_APP_AGENT_PROFILE_PHOTO}${consumerProfile.profilePicturePath}`
    : noAvatar;

  return (
    <>
      <Typography.Title level={3}>
        <Avatar
          style={{ marginRight: 16 }}
          src={avatar}
          alt="Icons made by Smashicons"
          role="presentation"
        />
        {name}
      </Typography.Title>
      <Divider />
      <Row>
        <Col span={4} className="photo-column">
          <img className="profile-photo" alt="agent profile" src={avatar} />
        </Col>
        <Col span={20} className="details-column">
          <Row>
            <Col span={24}>
              <Descriptions title="Consumer Details">
                {profileItems}
              </Descriptions>
            </Col>
            <Row>
              <Col span={24}>
                <Skeleton active loading={fetchingBidSessions}>
                  <Descriptions title="Bid Session Details">
                    {bidSessionItems}
                  </Descriptions>
                </Skeleton>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Skeleton active loading={fetchingBidSessions}>
                  {onboardingItems}
                </Skeleton>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProfileTab;
