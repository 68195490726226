export const nobul_updateAgentStatus = (credentials) => {
  return fetch(process.env.REACT_APP_UPDATE_AGENT_STATUS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => response.json())
    .then((confirmation) => confirmation);
};
