// Nobul Primary Colors
const nobulBlack = "#35475a";
const nobulWhite = "#ffffff";
const nobulTeal = "#00b2bf";
const nobulTealDark = "#257d98";
const nobulTealLight = "#ccf0f2";
const nobulTealHover = "#4cc9d2";

// Grey Colors
const greyDark = "#757c83";
const greyLight = "#d6d9db";
const greyXLight = "#eef1f2";

// Other Colors
const black = "#131e29";
const blue = "#1d6bb5";
const blueLight = "#d2e1f0";
const coral = "#ff5942";
const coralLight = "#ffded9";
const green = "#7aa94b";
const greenLight = "#e4eedb";
const red = "#e83f3f";
const yellow = "#f1ba0c";
const yellowLight = "#fcf1ce";
const darkBlue = "#257d98";

// Skeleton Colors
const skeleton01 = "#e5e5e5";
const skeleton02 = "#c6c6c6";

export {
  nobulBlack,
  nobulWhite,
  nobulTeal,
  nobulTealDark,
  nobulTealLight,
  nobulTealHover,
  greyDark,
  greyLight,
  greyXLight,
  black,
  blue,
  blueLight,
  coral,
  coralLight,
  green,
  greenLight,
  red,
  yellow,
  yellowLight,
  darkBlue,
  skeleton01,
  skeleton02
};
