import React, { useEffect, useState, Fragment } from "react";
import { Skeleton, Result } from "antd";
// Nobul imports
import { nobul_getAgentFeedMatches } from "../../api";
import AgentMatchesTopRow from "./AgentMatchesTopRow";
import AgentMatchesPendingRow from "./AgentMatchesPendingRow";
import AgentMatchesRow from "./AgentMatchesRow";
import AgentMatchesNoMatchesRow from "./AgentMatchesNoMatchesRow";

import "./AgentMatches.scss";

// TODO Switch over to API
const AgentMatches = ({ agentProfile, match }) => {
  const { agentId, avatarUrl, agentFirebaseId } = agentProfile;
  const profileUrl = `${
    process.env.NODE_ENV === "production"
      ? "https://www.nobul.com"
      : "https://www.nobul.pe"
  }/agent-profile/${agentFirebaseId}`;

  const [agentFeed, setAgentFeed] = useState({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (agentId) {
      nobul_getAgentFeedMatches(agentId).then((info) => {
        if (info && match.params.tab === "matches") {
          setAgentFeed(info);
          setloading(false);
        }
      });
    }

    return () => setloading(true);
  }, [agentId, agentProfile, match]);

  const { agent_info, matched_agents, pending_review, non_matches } = agentFeed;

  return (
    <Skeleton active loading={loading}>
      {agent_info ? (
        <Fragment>
          <AgentMatchesTopRow
            agentInfo={agent_info}
            avatarUrl={avatarUrl}
            profileUrl={profileUrl}
          />
          <AgentMatchesPendingRow
            pendingMatches={pending_review}
            userId={agentId}
          />
          <AgentMatchesRow matches={matched_agents} userId={agentId} />
          <AgentMatchesNoMatchesRow nonMatches={non_matches} userId={agentId} />
        </Fragment>
      ) : (
        <Result title={`No Matching Feed for ${agentProfile.agentName}`} />
      )}
    </Skeleton>
  );
};

export default AgentMatches;
