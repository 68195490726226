import React from "react";
import { Button } from "antd";

export const makeVideoStatusBadge = (status) => {
  switch (status) {
    case "verified":
      return "success";
    case "rejected":
      return "error";
    default:
      return "processing";
  }
};

export const makeVerifyButton = (
  status,
  size = "small",
  icon = "video-camera"
) => {
  switch (status) {
    case "verified":
      return (
        <Button icon={icon} type="danger" size={size}>
          Reject Video
        </Button>
      );
    case "rejected":
      return (
        <Button icon={icon} type="primary" size={size}>
          Verify Video
        </Button>
      );
    default:
      return (
        <Button icon={icon} size={size}>
          Verify or Reject Video
        </Button>
      );
  }
};
