import io from "socket.io-client";

// ! toggle to true to use a local instance of websockets on nobul-services
const debug = false;
const path = debug
  ? process.env.REACT_APP_WEBSOCKET_LOCAL
  : process.env.REACT_APP_WEBSOCKET;

const logs = process.env.REACT_APP_ENV === "dev";

// init socket.io instance and connect to the socket server anonymously
const socket = io(path, {
  autoConnect: false,
  reconnect: false
});

// connect to socket.io after firebase authChange observer returns the uid
export const connectToSockets = (
  firebaseID = "",
  connectionFirebaseId = "",
  connectionStatus
) => {
  // messages come through the advisorFirebaseId (@param firebaseID)
  // connection status is set via the firebaseUid (@param connectionFirebaseId)
  socket.io.opts.query = {
    firebaseID,
    connectionFirebaseId,
    connectionStatus,
    userType: "advisor",
    setFrom: "dashboard"
  };

  socket.connect();

  return socket;
};

const firebaseIDQuery = () => socket.io.opts.query.firebaseID || "anonymous";

// Main socket event handlers

// give up on reconnecting after 5 failed attempts
socket.on("reconnect_attempt", (attempt) => {
  if (attempt === 5) {
    socket.disconnect();
    console.error(
      "Failed to reconnect to the web sockets app engine. Disconnecting socket.io"
    );
  }
});

socket.on("connect", () => {
  logs &&
    console.log(
      `%c${String.fromCodePoint(0x27b0)} socket ${
        socket.id
      } connected as ${firebaseIDQuery()}`,
      "color: #1d6bb5"
    );
});

socket.on("disconnect", (reason) => {
  logs &&
    console.log(
      `%c${String.fromCodePoint(0x26d4)} ${reason} | ${firebaseIDQuery()}`,
      "color: #ff5942;"
    );
});

socket.on("reconnect", (attempt) => {
  const attempts = attempt > 1 ? `${attempt} attempts` : `${attempt} attempt`;

  logs &&
    console.log(
      `%c${String.fromCodePoint(0x267b)} socket ${
        socket.id
      } reconnected in ${attempts} as ${firebaseIDQuery()}`,
      "color: #257d98;"
    );
});

// some error handling
socket.on("reconnect_error", () => {
  console.error("socket.io reconnect attempt failed");
});

socket.on("connect_error", () => {
  console.error("socket.io connection attempt failed");
});

export default socket;
