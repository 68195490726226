import React from "react";
import { Card } from "antd";

import TabSwitch from "./tabs/TabSwitch";
// Presentational component
// receives the active tab as tab, tablist as tabs, and tab content as content

const ConsumerProfileContainer = ({
  match,
  history,
  loading,
  fetchBidSessions,
  getBidSession
}) => {
  const { tab } = match.params;

  const tabList = [
    {
      key: "profile",
      tab: "Consumer Profile"
    },
    {
      key: "bids",
      tab: "Consumer Bid Sessions"
    },
    {
      key: "communications",
      tab: "Consumer Communications"
    },
    {
      key: "recommendations",
      tab: "Recommended Properties"
    }
  ];

  return (
    <Card
      className="agent-profile"
      tabList={tabList}
      activeTabKey={tab}
      defaultChecked="profile"
      onTabChange={history.push}
    >
      <TabSwitch
        tab={tab}
        loading={loading}
        fetchBidSessions={fetchBidSessions}
        getBidSession={getBidSession}
      />
    </Card>
  );
};

export default ConsumerProfileContainer;
