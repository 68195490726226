import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Drawer } from "antd";
import { Route } from "react-router-dom";

import { AgentProfileContainer } from "components/AgentProfile";
import { BidDrawer } from "components/AgentProfile";
import CommunicationsMessages from "components/Communications/CommunicationsMessages";
import { nobul_getADP } from "api";
import {
  getAgentProfile,
  signalGetADPDispatched,
  signalGetADPSuccess,
  signalGetADPFail
} from "core/actions";

// either get the agent from the array or most likely make an api call for the profile
const AgentDrawer = ({
  match,
  history,
  bid,
  signalGetADPDispatched,
  getAgentProfile,
  signalGetADPSuccess,
  signalGetADPFail
}) => {
  const { tab, id } = match.params;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      signalGetADPDispatched();

      nobul_getADP(id)
        .then((profile) => {
          getAgentProfile(profile);
          signalGetADPSuccess();
        })
        .then(() => setLoading(false))
        .catch(() => {
          signalGetADPFail();
          setLoading(false);
        });
    }
  }, [
    id,
    getAgentProfile,
    signalGetADPDispatched,
    signalGetADPFail,
    signalGetADPSuccess
  ]);

  return (
    <Drawer
      visible={Boolean(tab)}
      onClose={() => history.push("/agents")}
      width="80%"
    >
      <AgentProfileContainer
        match={match}
        history={history}
        loading={loading}
      />
      <Route path="/:userRoute/:id/bids/:bid/">
        <BidDrawer viewingBid={bid} loading={loading} />
      </Route>
      <Route
        path="/:userRoute/:id/communications/:threadId"
        component={CommunicationsMessages}
      />
    </Drawer>
  );
};

const mapStateToProps = ({ agentsView }) => {
  return {
    bid: agentsView.viewingBid
  };
};

const mapDispatchToProps = {
  signalGetADPDispatched,
  getAgentProfile,
  signalGetADPSuccess,
  signalGetADPFail
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentDrawer);
