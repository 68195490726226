import React, { useEffect } from "react";
import { Layout } from "antd";
import { Switch, Route } from "react-router-dom";

import DashSider from "../DashSider";
import { AgentSearchContainer, AgentSearch } from "../AgentSearch";
import { AgentTable, ConsumerTable } from "../DashTables";
import { ConsumerDrawer } from "../ConsumerProfile";
import { AdvisorMessages } from "../Advisor";
import { AgentDrawer } from "../AgentProfile";
import GoogleReviews from "../GoogleReviews";

const { Content } = Layout;

const DashLayout = ({ match, history }) => {
  const { type } = match.params;

  useEffect(() => {
    if (history.location.pathname === `/`) {
      history.replace(`agents`);
    }
  }, [history]);

  return (
    <Layout>
      <DashSider />
      <Content style={{ padding: "0 24px", backgroundColor: "white" }}>
        {type !== "advisor" && <AgentSearchContainer SearchBar={AgentSearch} />}
        <Switch>
          <Route path="/agents">
            <AgentTable />
            <Route path="/:type/:id/:tab" component={AgentDrawer} />
          </Route>
          <Route path="/consumers">
            <ConsumerTable />
            <Route path="/:type/:id/:tab" component={ConsumerDrawer} />
          </Route>
          <Route path="/advisor">
            <AdvisorMessages history={history} />
          </Route>
          <Route path="/google-reviews" component={GoogleReviews} />
        </Switch>
      </Content>
    </Layout>
  );
};

export default DashLayout;
