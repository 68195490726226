import { stitchParams } from "helpers";

export const setStatus = (payload) =>
  fetch(process.env.REACT_APP_SET_STATUS, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: stitchParams(payload)
  })
    .then((response) => response.json())
    .then((statusObj) => statusObj || {})
    .catch((error) => {
      console.error("setStatus did errored out", error);

      return {};
    });
