export const addTabURL = (pathname, tab) => {
  const slash = !~pathname.indexOf("/", pathname.length - 1) ? "/" : "";
  return `${pathname}${slash}${tab}`;
};

export const clearTabURL = (pathname, tier) => {
  let splitPath = pathname.split("/");
  if (tier) {
    return "/" + splitPath.slice(1, splitPath.length - tier).join("/");
  }
  return "/" + splitPath.slice(1, 3).join("/");
};
