import React, { useState } from 'react';
import { Input, Select } from 'antd';

const InputGroup = Input.Group;
const { Search } = Input;
const { Option } = Select;
let placeholders = {
  name: 'enter the agent\'s name',
  email: 'enter the agent\'s email',
  phone: 'enter the agent\'s phone number'
};

const AgentSearch = () => {
  let [placeholder, setPlaceholder] = useState(placeholders['name']);

  return (
    <InputGroup compact style={{ textAlign: 'center'}}>
      <Select defaultValue="name" onSelect={value => setPlaceholder(placeholders[value])}>
        <Option value="name">Name</Option>
        <Option value="email">Email</Option>
        <Option value="phone">Phone</Option>
      </Select>
      <Search style={{ width: 320 }} placeholder={placeholder} onSearch={value => console.log(value)} />
    </InputGroup>
  );
}

export default AgentSearch;