import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback
} from "react";
import { Timeline, Drawer, Typography, Divider, Skeleton } from "antd";
import { connect } from "react-redux";

import { setActiveThread } from "core/actions";
import { sendMessage } from "core/thunks";
import { getChannel, nobul_userSettings } from "api";

import MessageInputSection from "../Messaging";
import MessageThread from "./MessageThread";

const { Title } = Typography;

const CommunicationsMessages = ({
  advisorFirebaseId,
  advisorFirstName,
  firebaseUid,
  activeThread,
  userTypeID,
  assignedTo,
  setActiveThread,
  messageUser,
  history,
  match
}) => {
  const { userRoute, id, threadId } = match.params;

  const isInitThread = threadId === "new-thread-id";

  const { contactFirstName, contactLastName, senderFirebaseId } = activeThread;

  const contactName =
    [contactFirstName, contactLastName].filter(Boolean).join(" ") || `No name`;

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [lastReadNode, setLastReadNode] = useState(null);

  useEffect(() => {
    if (threadId && !isInitThread) {
      const setToRead = userRoute === `advisor`;

      const channelHandler = (thread) => {
        nobul_userSettings(id, false).then((user) => {
          setUser({
            firstName: user.firstName || "No name",
            fullName:
              [user.firstName, user.lastName].filter(Boolean).join(" ") ||
              `No name`,
            userType: user.userType,
            firebaseUid: user.firebaseUid
          });

          setActiveThread(thread || {});

          setLoading(false);
        });
      };

      const params = {
        source: "csr-dashboard",
        userFirebaseId: id,
        setToRead
      };

      getChannel(threadId, params)
        .then(channelHandler)
        .catch((error) => {
          console.error(error, "retrying without source param");

          const params = {
            userFirebaseId: id,
            setToRead
          };

          getChannel(threadId, params)
            .then(channelHandler)
            .catch((error) => {
              console.error(error, "retrying without source param failed");

              setLoading(false);
            });
        });
    }
  }, [id, isInitThread, threadId, userRoute, setActiveThread]);

  useEffect(() => {
    if (isInitThread) {
      setLoading(false);
      setUser({
        firstName: isInitThread ? `You` : undefined,
        fullName: isInitThread ? `You` : undefined,
        userType: isInitThread ? `advisor` : undefined,
        messageId: isInitThread ? 12345 : undefined
      });

      return () => {
        setLoading(true);
        setUser({});
      };
    }
  }, [isInitThread]);

  const timelineContainer = useRef(null);

  const timelineContainerCallbackRef = useCallback((timeline) => {
    if (timeline) {
      timeline.scroll(0, timeline.scrollHeight);
    }

    timelineContainer.current = timeline;
  }, []);

  const lastReadRef = useCallback((ref) => {
    setLastReadNode(document.getElementById("last-read-messages"));
  }, []);

  useLayoutEffect(() => {
    if (timelineContainer.current) {
      const timeline = timelineContainer.current;
      timeline.scroll(0, timeline.scrollHeight);
    }
  }, [activeThread]);

  return (
    <Drawer
      closable
      width="50%"
      zIndex={9999}
      className="communication-drawer"
      visible={Boolean(threadId)}
      onClose={history.goBack}
    >
      <Skeleton active loading={loading}>
        <Title level={3}>
          {`Communications between ${user.fullName} and ${contactName}`}
        </Title>
        <Timeline id="last-read-messages" ref={lastReadRef} />
        <Divider />
        <div ref={timelineContainerCallbackRef} className="conversation-list">
          <Timeline>
            <MessageThread
              contactFirstName={contactFirstName}
              contactName={contactName}
              activeThread={activeThread}
              advisorFirebaseId={advisorFirebaseId}
              advisorFirstName={advisorFirstName}
              firebaseUid={firebaseUid}
              userTypeID={userTypeID}
              user={user}
              lastReadNode={lastReadNode}
            />
          </Timeline>
        </div>
        <MessageInputSection
          isCSR
          advisorFirebaseId={advisorFirebaseId}
          senderId={firebaseUid}
          receiverId={senderFirebaseId || id}
          threadId={isInitThread ? undefined : threadId}
          userTypeID={userTypeID}
          assignedTo={assignedTo}
          messageUser={messageUser}
          history={history}
        />
      </Skeleton>
    </Drawer>
  );
};

const mapStateToProps = ({
  advisor: {
    advisorFirebaseId,
    firebaseUid,
    userId,
    activeThread,
    firstName: advisorFirstName
  },
  pathData: { userTypeID }
}) => ({
  advisorFirebaseId,
  advisorFirstName,
  firebaseUid,
  assignedTo: userId,
  activeThread,
  userTypeID
});

const mapDispatchToProps = (dispatch) => ({
  setActiveThread: (thread) => dispatch(setActiveThread(thread)),
  messageUser: (message, token) => dispatch(sendMessage(message, token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationsMessages);
