import React, { useCallback } from "react";
import { Divider, List, Typography, message } from "antd";
import { startCase } from "lodash";

import ToggleFavouriteProperty from "widgets/ToggleFavouriteProperty";

import {
  cdnImageWrap,
  convert2dollars,
  mergeStreetAndUnit
} from "../../helpers";
import { nobul_setFavoriteListing } from "api";
import { recommendedPropertiesFavorite } from "core/actions";

import noProperty from "assets/no-property.jpg";

const { Text } = Typography;

const { Item } = List;
const { Meta } = Item;

const RecommendedPropertyCard = ({
  dispatch,
  property: {
    address,
    address_number,
    bedrooms,
    bathrooms,
    price,
    days_on_market,
    imageSrc,
    listingId,
    isFavorite
  },
  consumerProfile: { firebaseUid, firstName }
}) => {
  const fullAddress = mergeStreetAndUnit(address, address_number);

  const setFavorite = useCallback(() => {
    nobul_setFavoriteListing(firebaseUid, listingId)
      .then((added) => {
        dispatch(recommendedPropertiesFavorite(listingId, added));

        added
          ? message.success(
              <span>
                Favourited <strong>{fullAddress}</strong> for {firstName}
              </span>
            )
          : message.success(
              <span>
                Unfavourited <strong>{fullAddress}</strong> for {firstName}
              </span>
            );
      })
      .catch(() => message.error("Setting favourite property failed"));
  }, [firebaseUid, listingId, dispatch, fullAddress, firstName]);

  const description = {
    bedrooms,
    bathrooms,
    price,
    days_on_market
  };

  return (
    <Item
      key={listingId}
      actions={[
        <ToggleFavouriteProperty
          key="favourite"
          isFavorite={isFavorite}
          firstName={firstName}
          onClick={setFavorite}
          className="recommended-property-card__actions"
        />
      ]}
      extra={
        <div className="recommended-property-card__details">
          <a
            href={`https://www.nobul.com/en/home-details/${listingId}`}
            target="RecommendedListingFrame"
            rel="noopener noreferrer"
          >
            <img
              className="recommended-property-card__details--image"
              onError={({ target }) => {
                target.style.height = "140px";
                target.style.width = "224px";
                target.src = noProperty;
              }}
              src={cdnImageWrap(imageSrc, 224, 140) || noProperty}
              alt={address + " photo"}
            />
          </a>
        </div>
      }
    >
      <Meta
        title={
          <div>
            <Text>{fullAddress}</Text>&ensp;
            <Text type="secondary">{listingId}</Text>
          </div>
        }
        description={
          <div className="recommended-property-card__details--list">
            {Object.keys(description).map((item) => (
              <div key={item}>
                <Text type="secondary">{startCase(item)}</Text>:{" "}
                <Text>
                  {item === "price"
                    ? convert2dollars(description[item])
                    : description[item]}
                </Text>
              </div>
            ))}
            <Divider />
          </div>
        }
      />
    </Item>
  );
};

export default RecommendedPropertyCard;
