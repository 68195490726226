import { stitchParams } from "../helpers/stitchParams";
import { message } from "antd";

export const nobul_getLocationAutocomplete = (
  country = "CA",
  state_prov = "ON",
  query = "Toronto"
) =>
  fetch(process.env.REACT_APP_GET_LOCATION_AUTOCOMPLETE, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: stitchParams({
      country,
      state_prov,
      query
    })
  })
    .then((response) => response.json())
    .then((areas) => {
      if (!areas || !areas.length) {
        message.error("Location not found. Please try again.");

        return [];
      }

      return areas;
    })
    .catch((error) =>
      console.error("nobul_getLocationAutocomplete failed", error)
    );
