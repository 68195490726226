import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { message } from "antd";

import Sockets from "./sockets/Sockets";
import App from "./App";

import {
  signInAdvisor,
  signOutAdvisor,
  advisorAuthStatePending
} from "./core/actions";
import { ADVISOR_USER_TYPE } from "./constants";
import { dashboardStore } from "./core/";
import { nobul_userSettings } from "api";
import initFirebaseApp from "./auth";
import socket, { connectToSockets } from "sockets";
import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "index.scss";

const firebaseApp = initFirebaseApp();
const { dispatch, getState } = dashboardStore;

firebaseApp.auth().onAuthStateChanged((user) => {
  const { authState } = getState().advisor;

  // sign the user in
  if (user) {
    const { uid } = user;

    dispatch(advisorAuthStatePending(uid));

    // authenticate with postgress and set up message hub listener
    nobul_userSettings(uid, true).then(
      ({
        advisorFirebaseId,
        firebaseUid,
        userType,
        firstName,
        lastName,
        email,
        status,
        userId
      } = {}) => {
        if (userType === ADVISOR_USER_TYPE) {
          dispatch(
            signInAdvisor({
              userId,
              advisorFirebaseId,
              firebaseUid,
              userType,
              firstName,
              lastName,
              email,
              status
            })
          );

          connectToSockets(advisorFirebaseId, firebaseUid, status);
        } else {
          message.error(
            "This account is not authorized.\nIf this is a mistake, please ping the CSR Dashboard channel in Microsoft Teams.",
            6
          );
        }
      }
    );
  }

  // sing out and send to login route
  if (!user && authState) {
    dispatch(advisorAuthStatePending(user));
    dispatch(signOutAdvisor());

    socket.disconnect();
  }
});

ReactDOM.render(
  <Provider store={dashboardStore}>
    <Router>
      <Sockets />
      <Route path="/:type?/:id?/:tab?/:tid?">
        <App />
      </Route>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
