import { UPDATE_PATH_DATA, GET_CURRENT_SEARCH } from "core/actionTypes";

const derivePathData = (basePath) => {
  if (basePath === "consumers") {
    return {
      userType: "consumers",
      userTypeID: 1
    };
  }

  if (basePath === "advisor") {
    return {
      userType: "advisor",
      userTypeID: 3
    };
  }

  return {
    userType: "agents",
    userTypeID: 2
  };
};

export const pathReducer = (
  pathData = derivePathData(window.location.pathname.split("/")[1]),
  action
) => {
  if (action.type === UPDATE_PATH_DATA) {
    return derivePathData(action.payload);
  }
  return pathData;
};

const defaultSearch = {
  name: "",
  email: "",
  phone: "",
  areaIds: [],
  searchedCountry: "CA",
  searchedStateProv: "ON",
  searchedAreas: [{ key: 2742, label: "Toronto" }],
  searchedSuggestions: [{ key: 2742, label: "Toronto" }]
};

export const currentSearch = (state = defaultSearch, action) => {
  if (action.type === GET_CURRENT_SEARCH) {
    return {
      ...state,
      ...action.search
    };
  }

  return state;
};
