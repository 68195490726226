import {
  UPDATE_AGENT_LIST,
  UPDATE_BIDS,
  GET_AGENT_PROFILE,
  CACHE_AGENT_PROFILE,
  GET_BID,
  UPDATE_AGENT_STATUS_PROFILE,
  GET_MORE_AGENTS,
  UPDATE_PROFILE_VIDEO_STATUS,
  UPDATE_AGENT_STATUS_SEARCH,
  UPDATE_AGENT_REVIEW,
  UPDATE_PROFILE_VIDEO_STATUS_SEARCH,
  UPDATE_AGENT_TRANSACTIONS,
  UPDATE_USER_TAGS,
  UPDATE_AGENT_PROFILE
} from "../actionTypes";

export const updateAgentList = (agents) => {
  return {
    type: UPDATE_AGENT_LIST,
    agents
  };
};

export const updateBids = (bids) => {
  return {
    type: UPDATE_BIDS,
    bids
  };
};

export const getAgentProfile = (profile) => {
  return {
    type: GET_AGENT_PROFILE,
    profile
  };
};

export const cacheAgentProfile = (profile) => {
  return {
    type: CACHE_AGENT_PROFILE,
    profile
  };
};

export const getBid = (index) => {
  return {
    type: GET_BID,
    index
  };
};

export const getMoreAgents = (agents = []) => {
  return {
    type: GET_MORE_AGENTS,
    agents
  };
};

export const updateProfileVideoStatus = (status) => {
  return {
    type: UPDATE_PROFILE_VIDEO_STATUS,
    status
  };
};

export const updateProfileVideoStatusSearch = (
  status,
  profileCompletionScore,
  userId
) => {
  return {
    type: UPDATE_PROFILE_VIDEO_STATUS_SEARCH,
    profileCompletionScore,
    status,
    userId
  };
};

export const updateAgentStatusProfile = (status) => {
  return {
    type: UPDATE_AGENT_STATUS_PROFILE,
    status
  };
};

export const updateAgentStatusSearch = (status, user_key) => {
  return {
    type: UPDATE_AGENT_STATUS_SEARCH,
    status,
    user_key
  };
};

export const updateAgentReview = (reviews) => ({
  type: UPDATE_AGENT_REVIEW,
  reviews
});

export const updateAgentTransactions = (transactions) => ({
  type: UPDATE_AGENT_TRANSACTIONS,
  transactions
});

export const updateUserTags = (firebase_uid, tagData) => ({
  type: UPDATE_USER_TAGS,
  firebase_uid,
  tagData
});

export const updateAgentProfile = (payload) => ({
  type: UPDATE_AGENT_PROFILE,
  payload
});
