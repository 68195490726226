import { keyframes } from "styled-components";

export const loading = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.8);
  }`;

export const widthGrow = keyframes`
  0% {
    width: 0;
  }
  80% {
    width: 120%;
  }
  100% {
    width: 110%;
    transform: translateX(-5%);
  }`;
