import { nobul_updateAgent } from "api/nobul_updateAgent";
import { updateAgentReview } from "./../core/actions/agentActions";
import { message } from "antd";

export const verifyRejectReview = (e, data, advisor, dispatch) => {
  // TODO: this can be extended to update review and rating
  const { email: verifiedByEmail } = advisor;
  const { agentFirebaseId, reviewId, isVerified: action } = data;

  message.loading("Updating review status...");

  nobul_updateAgent({ agentFirebaseId, reviewId, action, verifiedByEmail })
    .then((reviews) => {
      message.destroy();
      message.success("Review status updated");
      dispatch(updateAgentReview(reviews));
    })
    .catch((error) => {
      message.destroy();
      message.error("Review verification failed");
      console.error(error);
    });
};
