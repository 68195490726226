export const verifyAgentVideo = (payload) =>
  fetch(process.env.REACT_APP_VERIFY_AGENT_VIDEO, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())
    .then((verification) => {
      if (verification && verification.msg) {
        return verification;
      }

      return {
        msg: "Video not verified",
        profileCompletionScore: null
      };
    });
