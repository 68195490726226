import breakpoints from "./breakpoints";

export const mobileMQ = `(max-width: ${breakpoints.mobile})`;
export const tabletMQ = `(max-width: ${breakpoints.tabletMax}) and (min-width: ${breakpoints.tabletMin})`;
export const desktopMQ = `(min-width: ${breakpoints.desktop})`;

export const mediaQueries = {
  [mobileMQ]: "mobile",
  [tabletMQ]: "tablet",
  [desktopMQ]: "desktop"
};
