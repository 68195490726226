import React, { useEffect } from "react";
import { List, Row, Col, Skeleton, Typography } from "antd";
import { useDispatch } from "react-redux";

import RecommendedPropertyCard from "./RecommendedPropertyCard";
import LeafletMapContainer from "../LeafletMap";
import ConsumerListingsRow from "../ConsumerListingsRow";

import { getRecommendedListings } from "api";
import {
  fetchRecommendedProperties,
  setRecommendedProperties,
  recommendedPropertiesError,
  setRecommendedPropertiesPage
} from "core/actions";

import "./styles.scss";

const { Title } = Typography;

const RecommendationsSection = ({ consumerProfile, recommendedProperties }) => {
  const { userId, firstName, lastName } = consumerProfile;
  const {
    fetching,
    properties,
    page,
    userId: propertiesFor
  } = recommendedProperties;

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (userId !== propertiesFor) {
      dispatch(fetchRecommendedProperties());
      getRecommendedListings(userId, signal)
        .then((recommendations) => {
          dispatch(setRecommendedProperties(recommendations, userId));
        })
        .catch(() => dispatch(recommendedPropertiesError()));
    }

    return () => controller.abort();
  }, [dispatch, propertiesFor, userId]);

  return (
    <div>
      <Title level={3}>
        Recommended Properties for {firstName} {lastName}
      </Title>
      <Skeleton active={fetching} loading={fetching}>
        <ConsumerListingsRow consumerProfile={consumerProfile} />
      </Skeleton>
      <Row type="flex" className="recommended-properties-section">
        <Col span={10} style={{ paddingRight: 12 }}>
          <Skeleton active={fetching} loading={fetching}>
            <List
              itemLayout="vertical"
              pagination={{
                pageSize: 4,
                current: page,
                hideOnSinglePage: true,
                total: properties.length,
                showTitle: false,
                onChange: (current) =>
                  dispatch(setRecommendedPropertiesPage(current))
              }}
              dataSource={properties}
              renderItem={(property) => (
                <RecommendedPropertyCard
                  dispatch={dispatch}
                  property={property}
                  consumerProfile={consumerProfile}
                />
              )}
            />
          </Skeleton>
        </Col>
        <Col span={14} className={`active-map-page-${page}`}>
          <Skeleton active={fetching} loading={fetching}>
            <LeafletMapContainer properties={properties} />
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
};

export default RecommendationsSection;
