import React, { useState } from "react";
import { connect } from "react-redux";
import { Popconfirm } from "antd";

import "./styles.scss";

const PopconfirmUpdater = ({
  dispatch,
  children,
  PopconfirmForm,
  popconfirmFormProps,
  okText = "Update",
  onConfirm,
  advisor,
  data,
  ...rest
}) => {
  const [_data, set_data] = useState(data);

  return (
    <Popconfirm
      overlayClassName="status-popper"
      title={
        <PopconfirmForm
          {...popconfirmFormProps}
          data={_data}
          setData={set_data}
          advisor={advisor}
        />
      }
      okText={okText}
      onConfirm={(e) => onConfirm(e, _data, advisor, dispatch)}
      icon={null}
      {...rest}
    >
      {children}
    </Popconfirm>
  );
};

const mapStateToProps = ({ advisor }) => ({
  advisor
});

export default connect(mapStateToProps)(PopconfirmUpdater);
