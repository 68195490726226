import { stitchParams } from "../helpers";

export const nobul_setFavoriteListing = (firebaseuid, listingId) =>
  fetch(process.env.REACT_APP_SET_FAVOURITE_LISTING, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: stitchParams({
      userFirebaseId: firebaseuid,
      listingId,
      createdOn: "csr"
    })
  })
    .then((response) => response.json())
    .then(
      ([{ create_favorite_for_firebase_user }]) =>
        create_favorite_for_firebase_user === "added favorite"
    )
    .catch((error) =>
      console.error("nobul_setFavoriteListing errored out", error)
    );
