import React, { useCallback } from "react";
import dayjs from "dayjs";
import { Icon, Typography, Divider, Empty, Descriptions } from "antd";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";
import CommunicationMessage from "./CommunicationMessage/index";
import { ReactComponent as NobulIcon } from "assets/nobul-logo.svg";
import { convert2dollars, getPropertyType } from "helpers";

const { Text, Paragraph } = Typography;

const interpolateParameter = (param, text) => {
  if (!text) {
    console.error("parameterse missing from the message payload");
    return null;
  }

  if (!param) {
    return text;
  }

  return text.replace(/({{\s*[\w]+\s*}})/g, param);
};

const DefaultMessage = ({ content, id }) => {
  if (!content) {
    console.error("content was null in handleDefaultMessage");

    return "No message content";
  }

  if (typeof content === "object") {
    console.error(
      `Received message content as an object instead of string.\nCheck user: ${id}\n`,
      content
    );

    // just a fallback in case objects get through
    return Object.keys(content).map((entry) => {
      if (typeof content[entry] === "object") {
        console.error(`found a nested object ${entry} in`, content);

        return (
          <Text key={entry}>
            <Text strong>{entry}: </Text>
            Message improperly formatted.
          </Text>
        );
      }

      return (
        <Text key={entry[1]}>
          <Text strong>{entry[0]}: </Text>
          {entry[1]}
        </Text>
      );
    });
  }

  return content;
};

const MessageThread = ({
  contactFirstName,
  contactName,
  advisorFirebaseId,
  advisorFirstName,
  firebaseUid,
  user,
  userTypeID,
  activeThread,
  lastReadNode
}) => {
  const { messages } = activeThread;

  const matchUserTypeToId = useCallback(
    (id, user, activeThread) => {
      const data = {
        ...user
      };

      if (id === "00000000000000000000000000000000" || !id) {
        data.userType = "system";
        data.icon = "robot";
        data.firstName = "Nobul System";
      } else if (id === advisorFirebaseId || id === firebaseUid) {
        data.icon = "aliwangwang";
        data.userType = "advisor";
        data.firstName = advisorFirstName;
      } else if (id === user.firebaseUid) {
        data.icon = user.userType === "agent" ? "qq" : "user";
      } else {
        data.userType = activeThread.contactUserType;
        data.firstName = activeThread.contactFirstName;
        data.icon = activeThread.contactUserType === "agent" ? "qq" : "user";
      }

      return data;
    },
    [advisorFirebaseId, advisorFirstName, firebaseUid]
  );

  if (!messages?.length) {
    return <Empty description="Can't find any messages for this thread" />;
  }

  return messages.map((message, index, array) => {
    const { content, senderId, messageType, messageId, receiverId, createdAt } =
      message;

    const isLastRead = activeThread.lastReadMessage === String(messageId);
    const isContactLastRead =
      activeThread.contactLastReadMessage === String(messageId);
    const showLastRead =
      (activeThread.hasNewMessages && isLastRead && index < array.length - 1) ||
      (isContactLastRead && index < array.length - 1);

    const makeUserIcon = (url, isSystem) => {
      const icon = isSystem
        ? "robot"
        : matchUserTypeToId(senderId, user, activeThread).icon;

      if (messageId === 12345) {
        return <NobulIcon height="18" width="18" viewBox="0 0 21 21" />;
      }

      if (url) {
        const path = url[0] === "/" ? url.slice(1) : url;

        return (
          <a
            href={`${process.env.REACT_APP_NOBUL_DOMAIN}/${path}`}
            target="NobulFrame"
          >
            <Icon type={icon} />
          </a>
        );
      }

      return <Icon type={icon} />;
    };

    if (messageType === "general") {
      if (typeof content === "object") {
        const { message } = content;

        return (
          <CommunicationMessage
            lastReadNode={lastReadNode}
            showLastRead={showLastRead}
            userType={makeUserIcon()}
            color="grey"
            key={index + messageType}
            date={createdAt}
            messageType="General"
          >
            <div className="message-contents">
              <Text>
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Text>
            </div>
          </CommunicationMessage>
        );
      }

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon()}
          color="grey"
          key={index + messageType}
          date={createdAt}
          messageType="General"
        >
          <div className="message-contents">
            <Text>
              <Text strong>
                {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
              </Text>
              {content}
            </Text>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-shared-property") {
      const { message, listingId, url } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="grey"
          key={index + messageType}
          date={createdAt}
          messageType="Shared Property"
        >
          <div className="message-contents">
            <Paragraph key="listingId">
              {contactFirstName} shared{" "}
              <a
                href={process.env.REACT_APP_NOBUL_DOMAIN + url}
                target="NobulFrame"
              >
                listing {listingId}
              </a>
            </Paragraph>

            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-quote-accepted") {
      const { buyRebate, sellRebate, services } = content;
      const hasBuyRebate = typeof buyRebate === "number";
      const hasSellRebate = typeof sellRebate === "number";

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="grey"
          key={index + messageType}
          date={createdAt}
          messageType="Proposal Accepted"
        >
          <div className="message-contents">
            <Paragraph key="cashback">
              {hasBuyRebate && (
                <Paragraph key="buy-rebate">
                  <Text strong>Buy Rebate: </Text>
                  {`${buyRebate}%`}
                </Paragraph>
              )}
              {hasSellRebate && (
                <Paragraph key="sell-rebate">
                  <Text strong>Sell Rebate: </Text>
                  {`${sellRebate}%`}
                </Paragraph>
              )}
            </Paragraph>
            <Paragraph>
              <Text strong>Services: </Text>
              {services &&
                services.map((service, index) => {
                  return (
                    <span key={service}>
                      <Text>{service}</Text>
                      {index === services.length - 1 ? null : (
                        <Divider type="vertical" />
                      )}
                    </span>
                  );
                })}
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-quote-submitted") {
      const { buyRebate, sellRebate, services, consumerUrl } = content;

      const hasBuyRebate = typeof buyRebate === "number";
      const hasSellRebate = typeof sellRebate === "number";

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(consumerUrl, true)}
          color="grey"
          key={index + messageType}
          date={createdAt}
          messageType="Proposal Submitted"
        >
          <div className="message-contents">
            {hasBuyRebate && (
              <Paragraph key="buy-rebate">
                <Text strong>Buy Rebate: </Text>
                {`${buyRebate}%`}
              </Paragraph>
            )}
            {hasSellRebate && (
              <Paragraph key="sell-rebate">
                <Text strong>Sell Rebate: </Text>
                {`${sellRebate}%`}
              </Paragraph>
            )}
            <Paragraph key="bid-page">
              <Text strong>Bid page: </Text>
              <a
                href={`${process.env.REACT_APP_NOBUL_DOMAIN}${consumerUrl}`}
                target="NobulFrame"
                rel="noopener noreferrer"
              >
                See the bid session
              </a>
            </Paragraph>
            <Paragraph>
              <Text strong>Services: </Text>
              {services &&
                services.map((service, index) => {
                  return (
                    <span key={service}>
                      <Text>{service}</Text>
                      {index === services.length - 1 ? null : (
                        <Divider type="vertical" />
                      )}
                    </span>
                  );
                })}
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-csr-connected") {
      const { message, consumerUrl } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(consumerUrl, true)}
          color="grey"
          key={index + messageType}
          date={createdAt}
          messageType="CSR Connected"
        >
          <div className="message-contents">
            <Text>
              <Text strong>
                {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
              </Text>
              <a
                target="NobulFrame"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_NOBUL_DOMAIN}${consumerUrl}`}
              >
                {message || "Consumer connected with an agent"}
              </a>
            </Text>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-call-afterhours") {
      const { message, time, callType } = content;

      const [hour, minute] = time.split(":");
      const adjustedHour = Number(hour) % 12 || 12;
      const ampm = adjustedHour < 12 ? "am" : "pm";

      const adjustedTime = `${adjustedHour}:${minute} ${ampm}`;

      const { firstName, userType } = matchUserTypeToId(
        senderId,
        user,
        activeThread
      );

      const body =
        userType === "consumer"
          ? `${message} at ${adjustedTime}`
          : `missed an after hours call at ${adjustedTime}`;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon()}
          key={index + messageType}
          date={createdAt}
          messageType={"Missed Call"}
        >
          <div className="message-contents">
            <Paragraph key="message">
              <Text strong>{firstName}</Text>{" "}
              <Icon type={callType === "video" ? "video-camera" : callType} />{" "}
              {body}
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    // TODO ADD THIS
    // if (messageType === "system-call-submitted") {
    // }

    if (messageType === "system-call-ended") {
      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon()}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Call Ended"
        >
          <div className="message-contents">
            <Paragraph key="message">
              {matchUserTypeToId(senderId, user, activeThread).firstName} made a{" "}
              {content.duration} second call
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "proposal") {
      const { message, agentUrl, cashback, services } = content;
      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(agentUrl)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Proposal"
        >
          <div className="message-contents">
            <Paragraph key="message">
              <Text strong>
                {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
              </Text>
              {message}
            </Paragraph>
            <Paragraph key="cashback">
              <Text strong>Cashback: </Text>
              {`$${cashback}`}
            </Paragraph>
            <Paragraph>
              <Text strong>Services: </Text>
              {services &&
                services.map((service, index) => {
                  return (
                    <span key={service}>
                      <Text>{service}</Text>
                      {index === services.length - 1 ? null : (
                        <Divider type="vertical" />
                      )}
                    </span>
                  );
                })}
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "pdp_question") {
      const { message, url } = content;
      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon()}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Inquiry"
        >
          <div className="message-contents">
            <Paragraph key="message">
              <Text strong>
                {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
              </Text>
              {message}
            </Paragraph>
            <Paragraph key="property">
              <Text strong>Property: </Text>
              <a
                href={`${process.env.REACT_APP_NOBUL_DOMAIN}${url}`}
                target="NobulFrame"
                rel="noopener noreferrer"
              >
                view listing
              </a>
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-initial") {
      const {
        date,
        period,
        message,
        tourType,
        totalHomes,
        listingIds = []
      } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Booked Tour"
        >
          <div className="message-contents">
            <Paragraph key="tour">
              <Text strong>{startCase(tourType)} Tour: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              Touring{" "}
              {totalHomes > 1 ? totalHomes + " homes" : totalHomes + " home"}{" "}
              {tourType === "video" ? "virtually " : "in-person "}
              on {dayjs(date).format(`dddd [${period}], MMMM D, YYYY`)}
            </Paragraph>
            {listingIds.length && (
              <Paragraph key="listings">
                <Text strong>Listings: </Text>
                {listingIds.map((id) => (
                  <a
                    style={{ marginRight: 12 }}
                    key={id}
                    rel="noopener noreferrer"
                    target="NobulFrame"
                    href={`${process.env.REACT_APP_NOBUL_DOMAIN}/en/home-details/${id}`}
                  >
                    {id}
                  </a>
                ))}
              </Paragraph>
            )}
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-rescheduled") {
      const { date, period, message, tourType, startingAddress } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Tour Rescheduled"
        >
          <div className="message-contents">
            <Paragraph key="date">
              <Text strong>Date: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              {upperFirst(tourType)} tour date changed to{" "}
              {dayjs(date).format(`dddd [${period}], MMMM D, YYYY`)}
            </Paragraph>
            <Paragraph key="address">
              <Text strong>Starting address: </Text>
              {startingAddress}
            </Paragraph>

            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-added-listings") {
      const {
        date,
        period,
        message,
        tourType,
        listingIds = [],
        totalHomes
      } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Added Tour Property"
        >
          <div className="message-contents">
            <Paragraph key="date">
              <Text strong>Tour: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              Added listing to{" "}
              {dayjs(date).format(`dddd [${period}], MMMM D, YYYY`)} {tourType}{" "}
              tour for{" "}
              {totalHomes > 1 ? totalHomes + " homes" : totalHomes + " home"}
            </Paragraph>
            {listingIds.length && (
              <Paragraph key="listings">
                <Text strong>Listings: </Text>
                {listingIds.map((id) => (
                  <a
                    style={{ marginRight: 12 }}
                    key={id}
                    rel="noopener noreferrer"
                    target="NobulFrame"
                    href={`${process.env.REACT_APP_NOBUL_DOMAIN}/en/home-details/${id}`}
                  >
                    {id}
                  </a>
                ))}
              </Paragraph>
            )}
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-listing-expired") {
      const { address, message, tourType } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Tour Listing Expired"
        >
          <div className="message-contents">
            <Paragraph key="address">
              <Text strong>Address: </Text>
              Listing expired for {tourType} tour at {address}
            </Paragraph>
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-cancelled") {
      const { address, message, tourType, date, period } = content;
      const { address: street, city, region, country } = address;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Tour Cancelled"
        >
          <div className="message-contents">
            <Paragraph key="date">
              <Text strong>Date: </Text>
              {dayjs(date).format(`dddd [${period}], MMMM D, YYYY`)}
            </Paragraph>
            <Paragraph key="address">
              <Text strong>Location: </Text>
              {street}, {city}, {region}, {country}
            </Paragraph>
            <Paragraph key="type">
              <Text strong>Tour Type: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              {tourType}
            </Paragraph>
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-time-confirmation") {
      const { date, time, message, tourType, totalHomes } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index}
          date={createdAt}
          messageType="Tour Confirmed"
        >
          <div className="message-contents">
            <Paragraph key="address">
              <Text strong>Date: </Text>
              {dayjs(date).format(`dddd, MMMM D, YYYY`)}
            </Paragraph>
            <Paragraph key="time">
              <Text strong>Time: </Text>
              {time}
            </Paragraph>
            <Paragraph key="homes">
              <Text strong>Total Homes: </Text>
              {totalHomes}
            </Paragraph>
            <Paragraph key="type">
              <Text strong>Tour Type: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              {tourType}
            </Paragraph>
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-removed-listings") {
      const { date, time, message, tourType, totalHomes } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index}
          date={createdAt}
          messageType="Tour Removed"
        >
          <div className="message-contents">
            <Paragraph key="address">
              <Text strong>Date: </Text>
              {dayjs(date).format(`dddd, MMMM D, YYYY`)}
            </Paragraph>
            <Paragraph key="time">
              <Text strong>Time: </Text>
              {time}
            </Paragraph>
            <Paragraph key="homes">
              <Text strong>Total Homes: </Text>
              {totalHomes}
            </Paragraph>
            <Paragraph key="type">
              <Text strong>Tour Type: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              {tourType}
            </Paragraph>
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-tour-user-cancelled") {
      const { address, date, time, message, tourType, totalHomes } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index}
          date={createdAt}
          messageType="Tour Cancelled"
        >
          <div className="message-contents">
            <Paragraph key="date">
              <Text strong>Date: </Text>
              {dayjs(date).format(`dddd, MMMM D, YYYY`)}
            </Paragraph>
            <Paragraph key="time">
              <Text strong>Time: </Text>
              {time}
            </Paragraph>
            <Paragraph key="homes">
              <Text strong>Total Homes: </Text>
              {totalHomes}
            </Paragraph>
            <Paragraph key="type">
              <Text strong>Tour Type: </Text>
              <Icon
                type={tourType === "video" ? "video-camera" : "home"}
              />{" "}
              {tourType}
            </Paragraph>
            <Paragraph key="address">
              <Text strong>Address: </Text>
              {Object.keys(address).map((key) => {
                return key ? (
                  <Paragraph key={key}>{address[key]}</Paragraph>
                ) : (
                  <Text key={key}>{address[key]} </Text>
                );
              })}
            </Paragraph>
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-quote-requested-csr") {
      const { agentUrl, message, consumerUrl } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="green"
          key={index + messageType}
          date={createdAt}
          messageType="Quote Request"
        >
          <div className="message-contents">
            <Paragraph key="address">
              <Text strong>Quote Requested: </Text>
              <a
                href={process.env.REACT_APP_NOBUL_DOMAIN + agentUrl}
                target="NobulFrame"
                rel="noopener noreferrer"
              >
                View quote
              </a>{" "}
              |{" "}
              <a
                href={process.env.REACT_APP_NOBUL_DOMAIN + consumerUrl}
                target="NobulFrame"
                rel="noopener noreferrer"
              >
                View agent profile
              </a>
            </Paragraph>
            {message && (
              <Paragraph key="message">
                <Text strong>
                  {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
                </Text>
                {message}
              </Paragraph>
            )}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-quote-requested") {
      // ? there is no content for now
      // const { message, duration } = content;
      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(false, true)}
          color="grey"
          key={index + messageType}
          date={createdAt}
          messageType="Quote requested"
        >
          <div className="message-contents">
            <Paragraph key="message">
              {contactName} requested a quote from {user.fullName}
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "phone call" || messageType === "system-call") {
      const { message, duration } = content;
      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(receiverId, true)}
          color="red"
          key={index + messageType}
          date={createdAt}
          messageType="Phone Call"
        >
          <div className="message-contents">
            <Paragraph key="message">
              {message} from{" "}
              {matchUserTypeToId(senderId, user, activeThread).firstName} after{" "}
              {duration} seconds
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-notification") {
      const { caption, agent_message, consumer_message, message, url } =
        content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(receiverId, true)}
          color="blue"
          key={index + messageType}
          date={createdAt}
          messageType="System Notification"
        >
          <div className="message-contents">
            {caption && (
              <Paragraph key="caption">
                <Text strong>Caption: </Text>
                <a href={url} target="NobulFrame">
                  {caption}
                </a>
              </Paragraph>
            )}
            <Paragraph key="message">
              {userTypeID < 2 ? consumer_message : agent_message}
            </Paragraph>
            <Paragraph key="message-2">{message}</Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "system-home-estimation-request") {
      const {
        address,
        baths,
        beds,
        budget,
        buildingTypeId,
        country,
        features,
        hasMortgage,
        intent,
        isMortgageBalanceHigher,
        message,
        nobulEstimation,
        propertySqft,
        propertyTypeId,
        sellReason,
        yearBuilt
      } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(receiverId, true)}
          key={index + messageType}
          date={createdAt}
          messageType="Home Estimation Request"
        >
          <div className="message-contents">
            <Descriptions size="small" column={2}>
              <Descriptions.Item label="Address">
                {address || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Country">
                {country || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Baths">
                {baths || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Beds">
                {beds || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Budget">
                {budget
                  ? `${convert2dollars(budget.min || 0)} - ${convert2dollars(
                      budget.max || 0
                    )}`
                  : `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Property Type">
                {getPropertyType({ buildingTypeId, propertyTypeId })?.caption
                  .default || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Property Sqft.">
                {propertySqft || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Year Built">
                {yearBuilt || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Features">
                {features?.join(", ") || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Intent">
                {startCase(intent) || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Nobul Estimation">
                {convert2dollars(nobulEstimation)}
              </Descriptions.Item>
              <Descriptions.Item label="Reasons for Selling">
                {sellReason?.join(", ") || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Has Mortgage">
                {startCase(hasMortgage) || `N/A`}
              </Descriptions.Item>
              <Descriptions.Item label="Mortgage Balance">
                {isMortgageBalanceHigher === `yes`
                  ? `Higher Than Property Value`
                  : isMortgageBalanceHigher === `no`
                  ? `Lower Than Property Value`
                  : startCase(isMortgageBalanceHigher) || `N/A`}
              </Descriptions.Item>
            </Descriptions>
            {message && <Paragraph key="message-2">{message}</Paragraph>}
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "sms") {
      const { sms } = content;
      const msg_body = sms ? sms.msg_body : content.message;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(receiverId)}
          color="blue"
          key={index + messageType}
          date={createdAt}
          messageType="SMS"
        >
          <div className="message-contents">
            <Paragraph key="message">{msg_body}</Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    // TODO: check for links in subject lines
    if (messageType === "email") {
      const { emailTemplate, parameters, subject } = content;

      // do not display New message emails (they're already in chat)
      // if this fails the subject line has been changed
      if (subject.indexOf("message") === 4) {
        return null;
      }

      if (parameters) {
        var {
          agent_name,
          agent_first_name,
          consumer_name,
          consumer_first_name,
          agent_submit_bid_url,
          consumer_view_proposals_url,
          hub_url,
          buyer_seller,
          high_price,
          location,
          low_price,
          property_type
        } = parameters;
      }

      if (receiverId === advisorFirebaseId) {
        return null;
      }

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(receiverId)}
          color="blue"
          key={index + messageType}
          date={createdAt}
          messageType="Email"
        >
          <div className="message-contents">
            <Paragraph strong>{emailTemplate}</Paragraph>
            <Paragraph key="email-1">
              <Text strong>From: </Text>
              {agent_name || agent_first_name || "Nobul Advisor"}
            </Paragraph>
            <Paragraph key="email-2">
              <Text strong>To: </Text>
              {buyer_seller ||
                consumer_first_name ||
                consumer_name ||
                agent_name ||
                agent_first_name}
            </Paragraph>
            <Paragraph key="email-3">
              <Text strong>Subject: </Text>
              <a
                href={
                  agent_submit_bid_url || consumer_view_proposals_url || hub_url
                }
                target="NobulFrame"
                rel="noreferrer noopener"
              >
                {interpolateParameter(
                  agent_name ||
                    agent_first_name ||
                    consumer_first_name ||
                    consumer_name,
                  subject
                )}
              </a>
            </Paragraph>
            <Paragraph key="email-5">
              <Text strong>Property Type: </Text>
              {property_type}
            </Paragraph>
            <Paragraph key="email-6">
              <Text strong>Location: </Text>
              {location}
            </Paragraph>
            <Paragraph key="email-7">
              <Text strong>Price Range: </Text>
              {low_price} - {high_price}
            </Paragraph>
          </div>
        </CommunicationMessage>
      );
    }

    if (messageType === "meeting-request") {
      const { datetime, meetingType, message } = content;

      return (
        <CommunicationMessage
          lastReadNode={lastReadNode}
          showLastRead={showLastRead}
          userType={makeUserIcon(receiverId)}
          key={index + messageType}
          date={createdAt}
          messageType="Meeting Request"
        >
          <div className="message-contents">
            <Paragraph>
              {matchUserTypeToId(senderId, user, activeThread).firstName}{" "}
              requested a {meetingType} meeting for{" "}
              {dayjs(datetime).format(`h:mma [on] dddd, MMMM D, YYYY`)}.
            </Paragraph>
            {message && <Paragraph key="message">{message}</Paragraph>}
          </div>
        </CommunicationMessage>
      );
    }

    return (
      <CommunicationMessage
        lastReadNode={lastReadNode}
        showLastRead={showLastRead}
        userType={makeUserIcon()}
        color="grey"
        key={`${index} + messageType`}
        date={createdAt}
        messageType="General"
      >
        <Text className="message-contents">
          <Text strong>
            {matchUserTypeToId(senderId, user, activeThread).firstName}:{" "}
          </Text>
          <DefaultMessage content={content} id={senderId} />
        </Text>
      </CommunicationMessage>
    );
  });
};

export default MessageThread;
