import dayjs from "dayjs";
import socket from "sockets";
import { message } from "antd";
import isPlainObject from "lodash/isPlainObject";

import {
  signalSendingMessage,
  signalSendingMessageFailed,
  updateActivethread
} from "core/actions";

import { COMM_HUB_UPDATE } from "../../constants";

export const sendMessage = (commHubMsg, token) => (dispatch) => {
  commHubMsg.content = {
    message: commHubMsg.content
  };

  commHubMsg.createdAt = dayjs(commHubMsg.createdAt).format(
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  );

  const {
    messageId,
    senderId,
    receiverId,
    assignedTo,
    createdAt,
    createdOn,
    messageType,
    content
  } = commHubMsg;

  dispatch(signalSendingMessage());
  dispatch(updateActivethread(commHubMsg));

  return fetch(process.env.REACT_APP_SEND_MESSAGE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      ...commHubMsg,
      returnAgent: false,
      isCSR: true
    })
  })
    .then((result) => result.json())
    .then((thread) => {
      const _thread =
        isPlainObject(thread) && thread["0"]
          ? thread["0"]
          : Array.isArray(thread)
          ? thread[0]
          : thread;
      // * isCSR flag returns the thread object in an array
      // * thread = array / thread[0] = object

      // emit socket event on successful message record
      if (_thread) {
        socket.emit(COMM_HUB_UPDATE, {
          threadId: _thread.threadId,
          message: {
            assignedTo,
            senderId,
            receiverId,
            createdAt,
            createdOn,
            messageType,
            content
          }
        });

        return _thread;
      } else {
        console.error(
          "sendMessage did not return a thread object. Message may not be saved. "
        );

        message.error(
          "Message not confirmed by the database.\nPlease ping the CSR Dashboard teams channel."
        );

        return {};
      }
    })
    .catch(() => {
      if (messageId) {
        dispatch(signalSendingMessageFailed());
      }
    });
};

export const updateUserStatus =
  ({ userId, status }) =>
  (dispatch) =>
    fetch(process.env.REACT_APP_UPDATE_USER_STATUS, {
      headers: {
        "Content-Type": "application/x-www-url-formencoded"
      },
      body: `userId, status`
    })
      .then((result) => {
        if (result.data) {
          // dispatch(
          //   updateNobulUser({
          //     status
          //   })
          // );

          // * Can enable this later to help CSRs see who is online
          // socket.emit(USER_STATUS_UPDATE, {
          //   userId,
          //   status
          // });

          return { status: true };
        } else {
          return { status: false };
        }
      })
      .catch((err) => {
        return { status: false, message: err };
      });
