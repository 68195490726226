export const nobul_getAgentFeedMatches = (userId) =>
  fetch(process.env.REACT_APP_GET_AGENT_FEED_MATCHES, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: "POST",
    body: `userId=${userId}`
  })
    .then((response) => response.json())
    .then((feed) => {
      return feed;
    })
    .catch((error) =>
      console.error("nobul_getAgentFeedMatches errored out ", error)
    );
