import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
// import { messagesMiddleware } from "core/middleware";

// if (localStorage.getItem("dashboardState")) {
//   dashboardState = JSON.parse(localStorage.getItem("dashboardState"));
// }

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));
export const dashboardStore = createStore(rootReducer, enhancer);
