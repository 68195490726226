import React from "react";
import { connect } from "react-redux";

import { getBidSession } from "core/actions";

import { BidSessionTable } from "components/DashTables";

const BidsTab = ({
  history,
  fetchBidSessions,
  getBidSession,
  bidSessions,
  firebaseUid,
  userId,
  loading
}) => (
  <BidSessionTable
    loading={loading}
    history={history}
    firebaseUid={firebaseUid}
    userId={userId}
    getBidSession={getBidSession}
    bidSessions={bidSessions}
    fetchBidSessions={fetchBidSessions}
  />
);

const mapStateToProps = ({
  consumersView: {
    bidSessions,
    consumerProfile: { firebaseUid, userId }
  }
}) => ({
  bidSessions,
  firebaseUid,
  userId
});

const mapDispatchToProps = {
  getBidSession
};

export default connect(mapStateToProps, mapDispatchToProps)(BidsTab);
