export const nobul_updateAgentFeedMatches = (
  userId,
  matchAgentIds,
  returnMatches
) =>
  fetch(process.env.REACT_APP_UPDATE_AGENT_FEED_MATCHES, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: "POST",
    body: `userId=${userId}&matchAgentIds=${matchAgentIds}&returnMatches=${returnMatches}`
  })
    .then((response) => response.json())
    .then((confirmation) => {
      return confirmation;
    })
    .catch((error) =>
      console.error("nobul_updateAgentFeedMatches errored out ", error)
    );
