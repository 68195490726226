export const getRecommendedListings = (user_id, signal) =>
  fetch(process.env.REACT_APP_GET_RECOMMENDED_LISTINGS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      signal
    },
    body: JSON.stringify({
      user_id,
      top_k: 40,
      incremental: false,
      recommendation_type: "web_desktop",
      display_type: "card_small"
    })
  })
    .then((response) => response.json())
    .then((recommendations) => {
      if (
        !recommendations ||
        !recommendations.user_recommendation ||
        !recommendations.user_recommendation.length
      ) {
        console.warn("getRecommendedListings did not return results");
        return [];
      }
      const { user_recommendation } = recommendations;

      return user_recommendation;
    })
    .catch((error) => {
      console.error("getRecommendedListings errored out", error);

      return [];
    });
