import React from "react";
import { Avatar, List } from "antd";

import defaultAvatar from "assets/user-2.png";

const AgentMatchesTopRow = ({ agentInfo, avatarUrl, profileUrl }) => {
  const { agent_name, brokerage_name } = agentInfo;
  // TODO Look into the listings prop
  // TODO Add verification buttons here
  return (
    <List className="matches-info">
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={avatarUrl || defaultAvatar} />}
          title={
            <a href={profileUrl} target="_blank" rel="noopener noreferrer">
              {agent_name}
            </a>
          }
          description={brokerage_name}
        />
      </List.Item>
    </List>
  );
};

export default AgentMatchesTopRow;
