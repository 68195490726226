import { rgba } from "polished";
import { nobulBlack, nobulTealDark } from "./colors";

// Shadows
const topDrawer = `0px 2px 7px ${rgba(nobulBlack, 0.1)}, 0px 6px 4px ${rgba(
  nobulBlack,
  0.06
)}`;
const rightDrawer = `-2px 0px 7px ${rgba(nobulBlack, 0.1)}, -6px 0px 4px ${rgba(
  nobulBlack,
  0.06
)}`;
const bottomDrawer = `0px -2px 7px ${rgba(
  nobulBlack,
  0.1
)}, 0px -6px 4px ${rgba(nobulBlack, 0.06)}`;
const leftDrawer = `2px 0px 7px ${rgba(nobulBlack, 0.1)}, 6px 0px 4px ${rgba(
  nobulBlack,
  0.06
)}`;
const cta = `0px -1px 4px ${rgba(nobulBlack, 0.1)}, 0px 4px 4px ${rgba(
  nobulBlack,
  0.15
)}`;
const elevationLevel1 = `0px 1px 4px ${rgba(nobulBlack, 0.1)}`;
const elevationLevel1Faded = `0px 1px 8px ${rgba(nobulBlack, 0.07)}`;
const elevationLevel2 = `0px 5px 10px ${rgba(
  nobulBlack,
  0.2
)}, 0px 1px 4px ${rgba(nobulBlack, 0.1)}`;
const teal = `0px 2px 13px ${rgba(nobulTealDark, 0.5)}, 0px 1px 10px ${rgba(
  nobulTealDark,
  0.4
)}`;
const inputFocusShadow = `0 0 0 2px ${rgba(nobulTealDark, 0.2)}`;

export {
  topDrawer,
  rightDrawer,
  bottomDrawer,
  leftDrawer,
  cta,
  elevationLevel1,
  elevationLevel1Faded,
  elevationLevel2,
  teal,
  inputFocusShadow
};
