import React, { useRef } from "react";
import { Button, Popover, message } from "antd";
import styled, { StyleSheetManager } from "styled-components";
import { kebabCase } from "lodash";

import { colors, shadows } from "styles";

// these are coming from production nobul-public-assets bucket
const nobulCircleLogo = `${process.env.REACT_APP_ASSETS_CDN_BASE}/nobul-circle.png`;
const almostStarsPublic = `${process.env.REACT_APP_ASSETS_CDN_BASE}/almost-stars.png`;
const perfectStarsPublic = `${process.env.REACT_APP_ASSETS_CDN_BASE}/perfect-stars.png`;

const Backlink = styled.a.attrs({ className: "nobul-backlink" })`
  font: 700 normal 20px/20px "Mulish", Helvetica, Arial, sans-serif;
  color: ${colors.nobulBlack};
  text-decoration: none;
  display: flex;

  &:visited {
    color: ${colors.nobulBlack};
  }

  &:hover {
    color: ${colors.nobulBlack};
    text-decoration: none;
  }

  .nobul-backlink-box {
    box-sizing: border-box;
    display: flex;
    background: white;
    align-items: center;
    box-shadow: ${shadows.elevationLevel1};
    transition: box-shadow 333ms linear;
    white-space: nowrap;
    border-radius: 10px;
    max-width: 500px;
    padding: 25px;

    &:hover {
      box-shadow: ${shadows.elevationLevel1Faded};
    }
  }

  .nobul-backlink-text,
  .nobul-backlink-logo {
    white-space: normal;
  }

  .nobul-backlink-logo {
    align-self: flex-start;
    margin-right: 20px;

    img {
      max-height: 55px;
      max-width: 55px;
    }
  }

  .nobul-backlink-stars {
    color: ${colors.nobulTealDark};
    white-space: normal;
    margin-top: 10px;
    font-size: 15px;

    img {
      height: 15px;
      margin-right: 10px;
    }

    span {
      white-space: nowrap;
    }
  }
`;

const backlinkHOC = ({
  brokerage,
  agentFirebaseId,
  agentName,
  reviewCount,
  stars,
  ref
}) =>
  brokerage.includes("AimHome") ? (
    <div
      ref={ref}
      className="nobul-link"
      style={{
        padding: "10px",
        fontFamily: "Muli, Arial, sans-serif",
        fontWeight: "bold",
        display: "inline-block",
        background: "#fff",
        borderRadius: 10,
        boxShadow: "0px 1px 4px rgb(53 71 90 / 10%)"
      }}
    >
      <a
        target="NobulFrame"
        href={`${
          process.env.REACT_APP_NOBUL_DOMAIN
        }/top-real-estate-agent/${kebabCase(
          agentName
        )}/${agentFirebaseId}?utm_campaign=agent-backlinks&utm_medium=referral&utm_source=${kebabCase(
          agentName
        )}&utm_content=agent-profile-widget`}
        style={{
          textDecoration: "none"
        }}
      >
        <img
          style={{
            verticalAlign: "middle",
            float: "left",
            marginRight: 10
          }}
          width="44"
          src="https://assetimages.nobul.com/nobul-circle.png"
          alt=""
        />
      </a>
      <div style={{ paddingLeft: 54 }}>
        <a
          target="NobulFrame"
          href={`${
            process.env.REACT_APP_NOBUL_DOMAIN
          }/top-real-estate-agent/${kebabCase(
            agentName
          )}/${agentFirebaseId}?utm_campaign=agent-backlinks&utm_medium=referral&utm_source=${kebabCase(
            agentName
          )}&utm_content=agent-profile-widget`}
          style={{
            color: "#35475a",
            textDecoration: "none",
            lineHeight: "19px"
          }}
        >
          {reviewCount} verified reviews on Nobul
        </a>
        <div style={{ lineHeight: 1 }}>
          <a
            target="NobulFrame"
            href={`${
              process.env.REACT_APP_NOBUL_DOMAIN
            }/top-real-estate-agent/${kebabCase(
              agentName
            )}/${agentFirebaseId}?utm_campaign=agent-backlinks&utm_medium=referral&utm_source=${kebabCase(
              agentName
            )}&utm_content=agent-profile-widget`}
            style={{
              textDecoration: "none",
              lineHeight: 1,
              verticalAlign: "middle"
            }}
          >
            <img
              width="100"
              style={{
                marginRight: 10,
                marginBottom: 2
              }}
              src={stars < 5 ? almostStarsPublic : perfectStarsPublic}
              alt=""
            />
          </a>
          <a
            target="NobulFrame"
            href={`${
              process.env.REACT_APP_NOBUL_DOMAIN
            }/top-real-estate-agent/${kebabCase(
              agentName
            )}/${agentFirebaseId}?utm_campaign=agent-backlinks&utm_medium=referral&utm_source=${kebabCase(
              agentName
            )}&utm_content=agent-profile-widget`}
            style={{
              color: "#35475a",
              textDecoration: "none",
              lineHeight: 1,
              verticalAlign: "middle",
              whiteSpace: "nowrap"
            }}
          >
            {stars} / 5 stars
          </a>
        </div>
      </div>
    </div>
  ) : (
    <StyleSheetManager disableVendorPrefixes>
      <Backlink
        target="NobulFrame"
        href={`${
          process.env.REACT_APP_NOBUL_DOMAIN
        }/top-real-estate-agent/${kebabCase(
          agentName
        )}/${agentFirebaseId}?utm_campaign=agent-backlinks&utm_medium=referral&utm_source=${kebabCase(
          agentName
        )}&utm_content=agent-profile-widget`}
        ref={ref}
      >
        <div className="nobul-backlink-box">
          <div className="nobul-backlink-logo">
            <img src={nobulCircleLogo} alt="nobul logo" role="presentation" />
          </div>
          <div className="nobul-backlink-text">
            <span>{reviewCount} verified reviews on Nobul</span>
            <div className="nobul-backlink-stars">
              <img
                src={stars < 5 ? almostStarsPublic : perfectStarsPublic}
                alt="nobul stars"
                role="presentation"
              />
              <span>{stars} / 5 stars</span>
            </div>
          </div>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href={`https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap&text=${reviewCount}%20verified%20reviews%20on%20Nobul%204.5/5%20stars`}
            rel="stylesheet"
          />
          <style type="text/css">{`.nobul-backlink{font:700 normal 20px/20px "Mulish",Helvetica,Arial,sans-serif;color:#35475a;text-decoration:none;display:flex;}.nobul-backlink:visited{color:#35475a;}.nobul-backlink:hover{color:#35475a;text-decoration:none;}.nobul-backlink .nobul-backlink-box{box-sizing:border-box;display:flex;background:white;align-items:center;box-shadow:0px 1px 4px rgba(53,71,90,0.1);transition:box-shadow 333ms linear;white-space:nowrap;border-radius:10px;max-width:500px;padding:25px;}.nobul-backlink .nobul-backlink-box:hover{box-shadow:0px 1px 8px rgba(53,71,90,0.07);}.nobul-backlink .nobul-backlink-text,.nobul-backlink .nobul-backlink-logo{white-space:normal;}.nobul-backlink .nobul-backlink-logo{align-self:flex-start;margin-right:20px;}.nobul-backlink .nobul-backlink-logo img{max-height:55px;max-width:55px;}.nobul-backlink .nobul-backlink-stars{color:#257d98;white-space:normal;margin-top:10px;font-size:15px;}.nobul-backlink .nobul-backlink-stars img{height:15px;margin-right:10px;}.nobul-backlink .nobul-backlink-stars span{white-space:nowrap;}`}</style>
        </div>
      </Backlink>
    </StyleSheetManager>
  );

const GenerateBacklink = ({
  brokerage,
  agentFirebaseId,
  agentName,
  agentReviews
}) => {
  const ref = useRef();

  const reviewCount = agentReviews.reviews?.length || "See";
  const stars = !agentReviews.reviews ? 4.5 : 5;

  return (
    <Popover
      placement="bottom"
      content={backlinkHOC({
        brokerage,
        agentFirebaseId,
        agentName,
        agentReviews,
        reviewCount,
        stars,
        ref
      })}
    >
      <Button
        type="ghost"
        onClick={() => {
          navigator.clipboard
            .writeText(ref.current.cloneNode(true).outerHTML)
            .then(() =>
              message.success(
                "Code copied. Share it with an agent to add to their website."
              )
            );
        }}
      >
        Get backlink
      </Button>
    </Popover>
  );
};

export default GenerateBacklink;
