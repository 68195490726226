import React, { useState, useEffect } from "react";
import { Table, Icon } from "antd";
import { getColumns, getTransactions } from "helpers";

const AgentMatchesTable = ({ data, tableName, title, userId }) => {
  const tableData = data ? data : [];
  const [_loading, set_loading] = useState(true);
  useEffect(() => {
    set_loading(false);

    return () => set_loading(true);
  }, [data]);

  if (tableName === "matched_agents" || tableName === "pending_review") {
    return (
      <Table
        className="matches-tables"
        title={() => title}
        pagination={false}
        dataSource={tableData}
        columns={getColumns(tableName, userId)}
        loading={_loading}
        rowKey={(data) => data.agent_id}
        onRow={(data, index) => ({
          onClick: (event) => {},
        })}
        expandIcon={({ expandable, expanded, record, onExpand }) => {
          if (!expandable) {
            return null;
          }

          return expanded ? (
            <Icon type="line" onClick={(event) => onExpand(record, event)} />
          ) : (
            <Icon type="menu" onClick={(event) => onExpand(record, event)} />
          );
        }}
        expandedRowRender={(data) => getTransactions(data)}
      />
    );
  }

  return (
    <Table
      className="matches-tables"
      title={() => title}
      pagination={false}
      dataSource={tableData}
      loading={_loading}
      columns={getColumns(tableName, userId)}
    />
  );
};

export default AgentMatchesTable;
