import React from "react";
import { useSelector } from "react-redux";

import ConsumerRecommendations from "components/ConsumerRecommendations";

const RecommendationsTab = () => {
  const { consumerProfile, recommendedProperties } = useSelector(
    ({ consumersView: { consumerProfile }, recommendedProperties }) => ({
      consumerProfile,
      recommendedProperties
    })
  );

  return (
    <ConsumerRecommendations
      consumerProfile={consumerProfile}
      recommendedProperties={recommendedProperties}
    />
  );
};

export default RecommendationsTab;
