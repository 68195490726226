import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Tag, Typography, Button } from "antd";
import dayjs from "dayjs";

import { getChannels } from "api";
import {
  setCurrentThreads,
  setUnassignedThreads
} from "core/actions/advisorActions";
import { toggleThreadDrawer } from "core/actions/advisorActions";

const columns = [
  {
    title: "First Name",
    dataIndex: "contactFirstName",
    key: "contactId"
  },
  {
    title: "Last Name",
    dataIndex: "contactLastName"
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) =>
      status && (
        <span>
          {status.map((tag, index) => {
            let color;
            if (tag === "agent") {
              color = "geekblue";
            }
            if (tag === "unverified") {
              color = "volcano";
            }
            if (tag === "csr") {
              color = "green";
            }
            return (
              <Tag key={index} color={color}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      )
  },
  {
    title: "Phone number",
    dataIndex: "contactPhoneNumber"
  },
  {
    title: "Last updated",
    dataIndex: "updatedAt",
    render: (date) => dayjs(date).format("ddd, MMM D, YYYY [at] (h:mma)")
  }
];

const { Title } = Typography;

export const AdvisorMessagesTable = ({
  firebaseUid,
  threadList,
  setThreadlist,
  title = `Your Current Messages`
}) => {
  const history = useHistory();

  const [refreshCount, setRefreshCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getChannels(firebaseUid)
      .then(setThreadlist)
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("getChannels failed in <CurrentThreads />", error);

        setLoading(false);
      });
  }, [firebaseUid, setThreadlist, refreshCount]);

  return (
    <Fragment>
      <Title level={3}>
        {title}{" "}
        <Button
          type="link"
          icon="reload"
          loading={loading}
          onClick={() => {
            setLoading(true);
            setRefreshCount((state) => ++state);
          }}
        >
          Refresh this list
        </Button>
      </Title>
      <Table
        bordered
        loading={loading}
        className="communications-list"
        rowKey="contactId"
        dataSource={threadList}
        columns={columns}
        size="small"
        onRow={(thread) => {
          return {
            onClick: () => {
              history.push(`${thread.contactFirebaseId}/${thread.threadId}`);
            }
          };
        }}
      />
    </Fragment>
  );
};

const currentThreadsProps = ({ advisor: { currentThreads, firebaseUid } }) => ({
  threadList: currentThreads,
  firebaseUid
});

const currentThreadsDispatch = (dispatch) => ({
  setThreadlist: (threadList) => dispatch(setCurrentThreads(threadList)),
  toggleMessagesDrawer: (toggle) => dispatch(toggleThreadDrawer(toggle))
});

const unassignedThreadsProps = ({
  advisor: { unassignedThreads, advisorFirebaseId }
}) => ({
  threadList: unassignedThreads,
  firebaseUid: advisorFirebaseId
});

const unassignedThreadsDispatch = (dispatch) => ({
  setThreadlist: (threadList) => dispatch(setUnassignedThreads(threadList)),
  toggleMessagesDrawer: (toggle) => dispatch(toggleThreadDrawer(toggle))
});

export const AdvisorCurrentThreads = connect(
  currentThreadsProps,
  currentThreadsDispatch
)(AdvisorMessagesTable);

export const AdvisorUnassignedThreads = connect(
  unassignedThreadsProps,
  unassignedThreadsDispatch
)(AdvisorMessagesTable);
