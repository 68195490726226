import agentsReducer from "./agentsReducer";
import consumersReducer from "./consumersReducer";
import advisorsReducer from "./advisorsReducer";
import messagesReducer from "./messagesReducer";
import { pathReducer, currentSearch } from "./generalReducers";
import jwtReducer from "./jwtReducer";
import recommendedPropertiesReducer from "./recommendedProperties";
import consumerListingsReducer from "./consumerListings";

import { combineReducers } from "redux";

export default combineReducers({
  agentsView: agentsReducer,
  consumersView: consumersReducer,
  recommendedProperties: recommendedPropertiesReducer,
  consumerListings: consumerListingsReducer,
  advisor: advisorsReducer,
  pathData: pathReducer,
  activeThreads: messagesReducer,
  jwt: jwtReducer,
  currentSearch
});
