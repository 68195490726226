import React from "react";
import { Card, List } from "antd";

import { setConsumerListingsPage } from "core/actions";
import { cdnImageWrap, convert2dollars } from "helpers";

import noProperty from "assets/no-property.jpg";

const { Meta } = Card;

const ConsumerListings = ({ listings, pageKey, page, dispatch }) => {
  return (
    <List
      pagination={{
        size: "small",
        pageSize: 4,
        current: page,
        hideOnSinglePage: true,
        total: listings.length,
        showTitle: false,
        onChange: (current) =>
          dispatch(setConsumerListingsPage(current, pageKey))
      }}
      dataSource={listings}
      renderItem={(listing) => {
        const { listingId, address, price } = listing;
        const url = `https://listingimages.nobul.com/${listingId}/1.jpeg`;

        return (
          <Card
            key={listingId}
            className="consumer-favourites-similar--property-card"
            cover={
              <a
                href={`https://www.nobul.com/en/home-details/${listingId}`}
                target="RecommendedListingFrame"
                rel="noopener noreferrer"
              >
                <img
                  onError={({ target }) => (target.src = noProperty)}
                  src={cdnImageWrap(url, 240, 140) || noProperty}
                  alt={address + " listing"}
                />
              </a>
            }
          >
            <Meta title={address} description={convert2dollars(price)} />
          </Card>
        );
      }}
    />
  );
};

export default ConsumerListings;
