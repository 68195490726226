import React from "react";
import { createPortal } from "react-dom";
import { Timeline, Typography } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const { Text, Paragraph } = Typography;

const CommunicationMessage = ({
  children,
  date,
  userType,
  messageType,
  color,
  showLastRead,
  lastReadNode
}) => {
  const userTypeText = showLastRead
    ? userType.props.type === `qq`
      ? `Agent`
      : `Consumer`
    : undefined;
  const className = showLastRead
    ? `last-read-anchor last-read-${userTypeText}`
    : undefined;

  return (
    <>
      <Timeline.Item
        dot={userType}
        color={color}
        key={date}
        className={className}
      >
        {showLastRead && (
          <div className="last-read-marker">
            <div></div>
            <span data-usertype={`Unread for ${userTypeText}`}></span>
          </div>
        )}
        <Paragraph type="secondary" strong>
          <Text>{messageType}: </Text>
          {dayjs(date).format("MMM D, YYYY (h:mma)")}
        </Paragraph>
        {children}
      </Timeline.Item>
      {showLastRead &&
        lastReadNode &&
        createPortal(
          <Timeline.Item
            dot={userType}
            color="blue"
            key={date}
            onClick={() => {
              document
                .querySelector(`.conversation-list .last-read-${userTypeText}`)
                .scrollIntoView();
            }}
          >
            <Paragraph type="secondary" strong>
              {dayjs(date).format("MMM D, YYYY (h:mma)")}
            </Paragraph>
            <Text strong>Show {userTypeText}'s last read message</Text>
          </Timeline.Item>,
          lastReadNode
        )}
    </>
  );
};

export default CommunicationMessage;
