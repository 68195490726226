export const searchAgents = (userId, areaIds) => {
  return fetch(process.env.REACT_APP_SEARCH_AGENTS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      limit: 3,
      userId,
      areaIds
    })
  }).then((response) => response.json());
};
