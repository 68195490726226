import { message } from "antd";
import { getJWTtoken } from "./getJWTtoken";

export const processAgentTransactions = ({
  agentId,
  action,
  transactionIds,
  verifiedByEmail
}) => {
  const plural = transactionIds.length > 1 ? "all transactions" : "transaction";

  message.loading(`Verifying ${plural}...`);

  return getJWTtoken().then(({ token }) =>
    fetch(process.env.REACT_APP_PROCESS_AGENT_TRANSACTIONS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        agentId,
        action,
        verifiedByEmail,
        transactionIds
      })
    })
      .then((response) => response.json())
      .then((verification) => {
        if (
          !verification ||
          verification.status !== "success" ||
          verification.error
        ) {
          message.destroy();
          message.error("Could not verify the transaction at this time");

          return null;
        }

        const noun =
          transactionIds.length > 1 ? "All transactions" : "Transaction";
        const verb = action === "verify" ? "verified" : "rejected";

        message.destroy();
        message.success(`${noun} ${verb} successfully`);
        // this is an object with list, pending, and rejected arrays
        return verification.data.transactions;
      })
      .catch((error) => console.error("processAgentTransactions failed", error))
  );
};
