import { message } from "antd";

import { getJWTtoken } from "./getJWTtoken";

export const blacklistCredentials = (payload) =>
  getJWTtoken().then(({ token }) => {
    message.loading(
      {
        key: "blacklist",
        content: "Blacklisting contact info..."
      },
      0
    );

    return fetch(process.env.REACT_APP_BLACKLIST_CREDENTIALS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((response) => {
        message.destroy("blacklist");

        if (response.error) {
          message.error(response.error);
        } else {
          message.success(response.message);
        }

        return response;
      })
      .catch((error) => {
        console.error(error);

        message.destroy("blacklist");
        message.error(error.error);
      });
  });
