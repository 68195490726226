import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Avatar, List, Table, Typography, Button, Icon } from "antd";
import toLower from "lodash/toLower";

import { searchAgents, geocodeLocation } from "api";
import { filterAndJoin, setBidStatusbadge } from "helpers";

import noAvatar from "assets/user-2.png";

const BidSessionTable = ({
  history,
  loading,
  firebaseUid,
  userId,
  bidSessions,
  getBidSession,
  fetchBidSessions
}) => {
  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "bid_session_start",
        key: "bid_session_start",
        render: (text) => text.split(" ")[0]
      },
      {
        title: "Status",
        dataIndex: "bid_session_status",
        key: "bid_session_status",
        render: (text) => setBidStatusbadge(text)
      },
      {
        title: "Buy/Sell",
        dataIndex: "transaction_type",
        key: "transaction_type"
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        render: (text) => <span>{text}</span>
      },
      {
        title: "Type",
        dataIndex: "property_types",
        key: "property_types"
      },
      {
        title: "Price",
        dataIndex: "budget",
        key: "budget"
      }
    ],
    []
  );

  const [searchedAgents, setSearchedAgents] = useState();
  const [agentsTitle, setAgentsTitle] = useState(
    `Fetching suggested agents...`
  );

  const onRow = useCallback(
    (data, index) => {
      return {
        onClick: () => {
          history.push(`/consumers/${firebaseUid}/bids/${index}`);
          getBidSession(data.bids || []);
        }
      };
    },
    [getBidSession, firebaseUid, history]
  );

  const experiment = bidSessions.find(
    (bidSession) =>
      toLower(bidSession.bid_session_status) === "system-bid_no-quote"
  );

  const { title, agents, text } = useMemo(() => {
    const allExpQuotes = experiment?.bids
      ? experiment.bids.filter(
          (bid) =>
            bid.bid_status === "System-Bid_No-Quote" ||
            bid.bid_status === "System-Bid_Declined"
        )
      : [];

    const declinedExpQuotes =
      allExpQuotes.filter((bid) => bid.bid_status === "System-Bid_Declined") ||
      [];

    const declinedCount = declinedExpQuotes.length;
    const allCount = allExpQuotes.length;

    return {
      title: experiment ? (
        <>
          Bid Session Experiment{" "}
          <Typography.Text code>
            <Icon type="experiment" style={{ color: "#00b2bf" }} /> No Default
            Quotes
          </Typography.Text>
        </>
      ) : (
        "Consumer Bid Sessions"
      ),
      text: experiment?.bids
        ? `${allCount}/${
            experiment.bids.length
          } agents without quote requests.{" "}
          ${Boolean(declinedCount) ? `$${declinedCount} declined.` : ""}`
        : null,
      agents: experiment?.bids ? (
        <List bordered size="small">
          {experiment.bids.map((agent) => (
            <List.Item
              key={agent.user_key}
              extra={
                agent.adpUrl ? (
                  <a target="NobulExternalFrame" href={agent.adpUrl}>
                    View Profile
                  </a>
                ) : null
              }
            >
              <List.Item.Meta
                avatar={<Avatar src={agent.avatarUrl || noAvatar} />}
                title={agent.agent_name}
                description={`Score: ${agent.score} | Buyer Rebate: ${
                  agent.rebate_cash.buy || `N/A`
                } | Seller Rebate: ${agent.rebate_cash.sell || `N/A`}`}
              />
            </List.Item>
          ))}
        </List>
      ) : null
    };
  }, [experiment]);

  useEffect(() => {
    const openSession = bidSessions.find((bidSession) => bidSession.location);

    if (openSession) {
      const { location } = openSession;
      const [city, region] = location[0].split(", ");

      const payload = {
        city: toLower(city),
        region: toLower(region)
      };

      geocodeLocation(payload)
        .then(({ areaId }) => {
          if (areaId) {
            searchAgents(userId, areaId)
              .then(({ results }) => {
                if (results.length) {
                  setSearchedAgents(
                    <List bordered>
                      {results.map((agent) => (
                        <List.Item
                          key={agent.profile.agentFirebaseId}
                          extra={
                            <a
                              target="NobulExternalFrame"
                              href={`${
                                process.env.REACT_APP_NOBUL_DOMAIN
                              }/top-real-estate-agent/${filterAndJoin([
                                toLower(agent.profile.firstName),
                                toLower(agent.profile.lastName)
                              ])}/${agent.profile.agentFirebaseId}`}
                            >
                              View Profile
                            </a>
                          }
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={agent.profile.avatarUrl} />}
                            title={agent.profile.agentName}
                            description={`Score: ${
                              agent.stats.agentMatch
                            } | Buyer Rebate: ${
                              agent.quote.buyRebate || `N/A`
                            } | Seller Rebate: ${
                              agent.quote.sellRebate || `N/A`
                            }`}
                          />
                        </List.Item>
                      ))}
                    </List>
                  );
                }

                setAgentsTitle(
                  `${results?.length || `No`} agents matches found`
                );
              })
              .catch(() => setAgentsTitle(`Failed to fetch agents`));
          } else {
            setAgentsTitle(`No agents in this area`);
          }
        })
        .catch(() => setAgentsTitle(`Failed to fetch agents`));
    }
  }, [bidSessions, userId]);

  return (
    <div>
      <Typography.Title level={3}>
        {title}
        <Button
          type="link"
          icon="reload"
          loading={loading}
          onClick={() => {
            fetchBidSessions(true);
          }}
        >
          Refresh bid sessions
        </Button>
      </Typography.Title>
      <Table
        bordered
        loading={loading}
        className="bids-table"
        dataSource={bidSessions}
        rowKey={(data) => data.bid_session}
        columns={columns}
        pagination={{ pageSize: 10 }}
        size="small"
        onRow={onRow}
      />
      <Typography.Title level={4}>{text || agentsTitle}</Typography.Title>
      {agents || searchedAgents}
    </div>
  );
};

export default BidSessionTable;
