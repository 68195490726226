import {
  FETCH_RECOMMENDED_PROPERTIES,
  SET_RECOMMENDED_PROPERTIES,
  RECOMMENDED_PROPERTIES_ERROR,
  RECOMMENDED_PROPERTIES_FAVORITE,
  SET_RECOMMENDED_PROPERTIES_PAGE
} from "../actionTypes";

export const fetchRecommendedProperties = () => {
  return {
    type: FETCH_RECOMMENDED_PROPERTIES
  };
};

export const setRecommendedProperties = (properties, userId) => {
  return {
    type: SET_RECOMMENDED_PROPERTIES,
    userId,
    properties
  };
};

export const recommendedPropertiesError = () => {
  return {
    type: RECOMMENDED_PROPERTIES_ERROR
  };
};

export const recommendedPropertiesFavorite = (listingId, isFavorite) => {
  return {
    type: RECOMMENDED_PROPERTIES_FAVORITE,
    listingId,
    isFavorite
  };
};

export const setRecommendedPropertiesPage = (page) => {
  return {
    type: SET_RECOMMENDED_PROPERTIES_PAGE,
    page
  };
};
