import { getJWTtoken } from "./getJWTtoken";

export const getConsumerProfile = (consumerId, agentId) =>
  getJWTtoken().then(({ token }) =>
    fetch(
      `${process.env.REACT_APP_GET_CONSUMER_PROFILE}?consumerId=${consumerId}&agentId=${agentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then((response) => response.json())
      .then(
        (consumerFile) =>
          consumerFile || { reporting: {}, profile: {}, quote: {} }
      )
      .catch(console.error)
  );

export const getAgentNotes = (consumerId, agentId, token) =>
  getConsumerProfile(consumerId, agentId, token)
    .then((consumerProfile) => consumerProfile.reporting.note || [])
    .catch((error) => {
      console.error(error);

      return [];
    });
