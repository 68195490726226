import firebase from "firebase/app";
import "firebase/auth";

export const createUserWithEmailPassword = (email, password) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

const getPersistanceMethod = (persistence) => {
  if (!persistence) {
    return firebase.auth.Auth.Persistence.NONE;
  }

  return firebase.auth.Auth.Persistence.LOCAL;
};

export const signInFirebase = async (email, password, remember) => {
  // await firebase.auth().signOut();
  await firebase.auth().setPersistence(getPersistanceMethod(remember));
  await firebase.auth().signInWithEmailAndPassword(email, password);

  const { uid } = firebase.auth().currentUser;

  return uid;
};

export const signOutFirebase = async () => {
  // signout success handled separately by components
  return firebase
    .auth()
    .signOut()
    .catch((error) => {
      console.error("signOutFirebase errored out ", error);
    });
};

export const resetUserPassword = (email, actionCodeSettings = null) =>
  firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
