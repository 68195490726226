import {
  UPDATE_ACTIVE_THREADS,
  UPDATE_MESSAGE_IN_THREAD,
  ADD_MESSAGE_THREAD
} from "core/actionTypes";

const defaultThreads = [];

const messagesReducer = (threads = defaultThreads, action) => {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_ACTIVE_THREADS:
      return payload.slice();

    case UPDATE_MESSAGE_IN_THREAD:
      const { message, threadId } = payload;

      const updatedThreadMessages = threads.map((thread) => {
        if (thread.threadId === threadId) {
          return { ...thread, messages: [...thread.messages, { ...message }] };
        }

        return thread;
      });
      return updatedThreadMessages;

    case ADD_MESSAGE_THREAD:
      const { initThread } = action;

      // if the user already has threads but CSR started a new one
      if (initThread) {
        // it will be the last one in the array
        const tempThreadRemoved = threads.slice(0, threads.length - 1);
        return [...tempThreadRemoved, { ...payload }];
      }

      return [...threads, { ...payload }];

    default:
      return threads;
  }
};

export default messagesReducer;
