import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { Input, Select, Form, Switch, Row } from "antd";
import { debounce } from "lodash";

import { nobul_getLocationAutocomplete } from "api";
import { getCurrentSearch } from "core/actions";

import { PROVINCES_AND_TERRITORIES_ABBREVIATIONS } from "constants/provinces";
import { STATE_ABBREVIATIONS } from "constants/states";

const { Option } = Select;
const { Item } = Form;

const debouncedLocationAutocomplete = debounce(
  nobul_getLocationAutocomplete,
  555,
  {
    leading: true,
    trailing: false,
    maxWait: 555
  }
);

const LocationSearchPart = ({ setFormFields, currentSearch, dispatch }) => {
  const {
    searchedCountry,
    searchedStateProv,
    searchedAreas,
    searchedSuggestions
  } = currentSearch;

  const statesProvincesAbbreviations = useMemo(
    () => ({
      CA: PROVINCES_AND_TERRITORIES_ABBREVIATIONS,
      US: STATE_ABBREVIATIONS
    }),
    []
  );

  const [locationActive, setLocationActive] = useState(false);

  // for locationAutocomplete
  const [country, setCountry] = useState(searchedCountry);
  const [stateProv, setStateProv] = useState(searchedStateProv);

  // for Angelica
  const [areas, setAreas] = useState(searchedAreas);

  // for input elements
  const [suggestions, setSuggestions] = useState(searchedSuggestions);

  const locationAutocompleteCallback = useCallback(
    (query) => {
      debouncedLocationAutocomplete(country, stateProv, query).then(
        (areasArray) => {
          return areasArray
            ? setSuggestions(
                areasArray.map(({ areaname, areaid }) => ({
                  key: areaid,
                  label: areaname
                }))
              )
            : [];
        }
      );
    },
    [country, stateProv]
  );

  const getStateProvAreaID = useCallback(
    (country, stateProv) => {
      return [
        statesProvincesAbbreviations[country].indexOf(stateProv) +
          (country === "CA" ? 1 : 14)
      ];
    },
    [statesProvincesAbbreviations]
  );

  useEffect(() => {
    if (!locationActive) {
      setFormFields((state) => ({
        ...state,
        areaIds: []
      }));
    } else {
      setFormFields((state) => ({
        ...state,
        areaIds: areas.length
          ? areas.map(({ key }) => key)
          : getStateProvAreaID(country, stateProv)
      }));
    }

    return () =>
      dispatch(
        getCurrentSearch({
          searchedCountry: country,
          searchedStateProv: stateProv,
          searchedAreas: [...areas],
          searchedSuggestions: [...suggestions]
        })
      );
  }, [
    setFormFields,
    locationActive,
    areas,
    getStateProvAreaID,
    country,
    stateProv,
    dispatch,
    suggestions
  ]);

  return (
    <Row type="flex" style={{ alignItems: "center" }}>
      <Input.Group style={{ marginTop: 16, flex: 1 }}>
        <Item label="Country">
          <Select
            disabled={!locationActive}
            style={{ width: 100 }}
            size="large"
            defaultValue={country}
            onChange={(country) => {
              setCountry(country);
              setAreas([]);
            }}
          >
            <Option value="CA">Canada</Option>
            <Option value="US">USA</Option>
          </Select>
        </Item>
        <Item label="State/Province">
          <Select
            disabled={!locationActive}
            style={{ width: 80 }}
            size="large"
            defaultValue={stateProv}
            onChange={(stateProv) => {
              setStateProv(stateProv);
              setAreas([]);
            }}
          >
            {statesProvincesAbbreviations[country].map((abbreviation) => (
              <Option key={abbreviation} value={abbreviation}>
                {abbreviation}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="City (multiple)">
          <Select
            disabled={!locationActive}
            allowClear
            labelInValue
            filterOption
            autoClearSearchValue
            size="large"
            mode="multiple"
            optionLabelProp="children"
            optionFilterProp="label"
            value={areas}
            style={{ minWidth: 420 }}
            onSearch={locationAutocompleteCallback}
            onChange={setAreas}
          >
            {suggestions.map(({ key, label }) => (
              <Option key={label} value={key} label={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Item>
      </Input.Group>
      <span style={{ verticalAlign: "middle", lineHeight: 1 }}>
        <strong>Search by location:</strong>{" "}
        <Switch
          checked={locationActive}
          onChange={setLocationActive}
          checkedChildren={"ON"}
          unCheckedChildren={"OFF"}
        />
      </span>
    </Row>
  );
};

const mapStateToProps = ({ currentSearch }) => ({
  currentSearch
});

export default connect(mapStateToProps, null)(LocationSearchPart);
