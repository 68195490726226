import { propertyTypes } from "constants/properties";

export const getPropertyType = ({ propertyTypeId, buildingTypeId, key }) => {
  const areArraysEqual = (a, b) =>
    a && b && a.length === b.length && a.every((v, i) => v === b[i]);

  const propertyTypeIds = propertyTypeId
    ? Array.isArray(propertyTypeId)
      ? propertyTypeId.length
        ? [...propertyTypeId]
        : null
      : [propertyTypeId]
    : null;
  const buildingTypeIds = buildingTypeId
    ? Array.isArray(buildingTypeId)
      ? buildingTypeId.length
        ? [...buildingTypeId]
        : null
      : [buildingTypeId]
    : null;

  const propertyType = propertyTypes.find((type) => {
    if (key) {
      return type.key === key;
    } else if (buildingTypeIds) {
      if (areArraysEqual(buildingTypeIds, type.buildingTypeId)) {
        return true;
      } else {
        if (
          propertyTypes.find((item) =>
            areArraysEqual(buildingTypeIds, item.buildingTypeId)
          )
        ) {
          return false;
        } else if (Array.isArray(type.buildingTypeId)) {
          return type.buildingTypeId.some((a) =>
            buildingTypeIds.some((b) => Number(a) === Number(b))
          );
        }
      }
    } else if (propertyTypeIds) {
      if (areArraysEqual(propertyTypeIds, type.propertyTypeId)) {
        return true;
      } else if (type.propertyTypeId === propertyTypeIds) {
        return true;
      }
    } else {
      return false;
    }

    return false;
  });

  return propertyType || null;
};
