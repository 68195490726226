import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  Drawer,
  List,
  Skeleton,
  Typography,
  Avatar,
  Rate,
  Button,
  message
} from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";
import { startCase } from "lodash";

import { getNobulReviews, verifyNobulReviews } from "api";

import "./GoogleReviews.scss";
import userAvatar from "assets/user.png";

const { Title, Text } = Typography;

const GoogleReviews = ({ name, email }) => {
  const [reviews, setReviews] = useState();

  useEffect(() => {
    getNobulReviews(
      `${process.env.REACT_APP_GET_NOBUL_REVIEWS}?reviewStatus=pending`
    )
      .then((reviews) => setReviews(reviews || []))
      .catch(() => message.warn("Couldn't fetch any reviews"));
  }, []);

  const verifyNobulReview = useCallback(
    (reviewId, status) => {
      verifyNobulReviews({ name, email, reviewId, status })
        .then((verification) => {
          if (verification.success) {
            const msg = verification.confirmation.split(",")[0];
            message.success(startCase(msg));

            setReviews((reviews) =>
              reviews.filter((review) => review.reviewId !== reviewId)
            );
          } else {
            message.error(startCase(verification.confirmation));
          }
        })
        .catch((verification) =>
          message.error(startCase(verification.confirmation))
        );
    },
    [name, email]
  );

  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Drawer
      className="google-reviews-drawer"
      visible={match.path === "/google-reviews"}
      width="60%"
      onClose={() => history.goBack()}
    >
      <Skeleton
        active={Boolean(!reviews)}
        loading={Boolean(!reviews)}
        paragraph={{
          rows: 9
        }}
      >
        <Title level={3}>Pending Google Reviews</Title>
        <List
          pagination={{
            pageSize: 6
          }}
          dataSource={reviews}
          renderItem={(review) => (
            <List.Item
              actions={[
                <Button
                  type="primary"
                  size="small"
                  onClick={() => verifyNobulReview(review.reviewId, "verified")}
                >
                  Verify
                </Button>,
                <Button
                  type="danger"
                  size="small"
                  onClick={() => verifyNobulReview(review.reviewId, "rejected")}
                >
                  Reject
                </Button>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={review.reviewerPhoto || userAvatar} />}
                title={
                  <Text>
                    {review.reviewerName}&ensp;
                    <Rate
                      onHoverChange={() => {}}
                      style={{ margin: 0 }}
                      value={review.starRating}
                    />
                  </Text>
                }
                description={<Text>{review.comment}</Text>}
              />
            </List.Item>
          )}
        />
      </Skeleton>
    </Drawer>
  );
};

const mapStateToProps = ({ advisor }) => ({
  name: advisor.firstName,
  email: advisor.email
});
export default connect(mapStateToProps)(GoogleReviews);
