export const COMM_HUB_UPDATE = "comm_hub_update";
export const USER_STATUS_UPDATE = "user_status_update";
export const ADVISOR_STATUS_UPDATE = "advisor_status_update";

// Message Types
export const GENERAL_MESSAGE_TYPE = "general";

// User Types
export const ADVISOR_USER_TYPE = "advisor";

// Object types
export const AGENT_STATUSES = [
  "verified",
  "unverified",
  "away",
  "suspended",
  "report_pending",
  "platinum",
  "shadow_banned",
  "blocked",
  "blacklisted"
];
export const AGENT_STATUS_COLORS = [
  "blue",
  "orange",
  "gold",
  "volcano",
  "cyan",
  "purple",
  "volcano",
  "volcano",
  "volcano"
];

export const SEARCH_AGENTS_CSV_FIELDS = [
  "tags",
  "email",
  "user_name",
  "phone_number",
  "profileCompletionScore"
];
