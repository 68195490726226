import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Button } from "antd";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { generateAgentReport } from "api";

import LocationSearchPart from "./LocationSearch";

import {
  signalSearch,
  updateAgentList,
  updateConsumerList,
  signalSearchSuccess,
  getCurrentSearch
} from "core/actions";
import { nobul_searchUsers } from "api";

const { Item } = Form;

// pull this out to it's component
const AgentSearch = ({ history, dispatch, pathData, jwt, firebaseUid }) => {
  const { userType, userTypeID } = pathData;

  let [loading, setLoading] = useState(false);
  let [formFields, setFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    userTypeId: userTypeID,
    areaIds: []
  });

  const generateCSV = useCallback(() => {
    const { name, email, phone, areaIds } = formFields;
    const filename = areaIds.length ? areaIds.join("_") : "_all";

    generateAgentReport(
      { name, email, phone, areaIds },
      filename,
      jwt,
      firebaseUid
    );
  }, [formFields, jwt, firebaseUid]);

  const trimEmail = (e) => {
    let email = e.target.value;
    if (email) {
      return setFormFields((fields) => ({
        ...fields,
        email: email.trim().toLowerCase()
      }));
    }
  };

  useEffect(() => {
    setFormFields((fields) => ({
      ...fields,
      userTypeId: userTypeID
    }));
  }, [history.location.pathname, userType, userTypeID]);

  const selectDispatch = (userTypeID, users) => {
    return userTypeID === 1
      ? updateConsumerList(users)
      : updateAgentList(users);
  };

  let debouncedInput = debounce(
    (e) => {
      let field = e.target.name;
      let value = e.target.value;
      setFormFields((fields) => ({
        ...fields,
        [field]: value,
        userTypeId: userTypeID
      }));
    },
    222,
    {
      leading: true,
      trailing: false
    }
  );

  const submitSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    // request users list
    dispatch(signalSearch());
    nobul_searchUsers(formFields)
      .then((users) => {
        dispatch(selectDispatch(userTypeID, users));
        setLoading(false);
      })
      .then(() => dispatch(signalSearchSuccess()))
      .then(() => dispatch(getCurrentSearch(formFields)))
      .catch((e) => {
        history.push("/" + userType + "/");
        alert(`Didn't find any ${pathData.userType}`);
        setLoading(false);
      });
  };

  return (
    <Form layout="inline" onSubmit={submitSearch}>
      <Item label="Name">
        <Input
          size="large"
          style={{ width: 250 }}
          onInput={debouncedInput}
          name="name"
          placeholder={`enter the ${userType}' name`}
        />
      </Item>
      <Item label="Email">
        <Input
          size="large"
          style={{ width: 250 }}
          onInput={debouncedInput}
          onBlur={trimEmail}
          name="email"
          placeholder={`enter the ${userType}' email`}
        />
      </Item>
      <Item pattern="[0-9]+" label="Phone">
        <Input
          size="large"
          style={{ width: 250 }}
          onInput={debouncedInput}
          name="phone"
          placeholder={`enter the ${userType}' phone number`}
        />
      </Item>
      <Item>
        <Button.Group>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            icon="search"
            loading={loading}
          >
            Search
          </Button>
          <Button
            type="secondary"
            htmlType="submit"
            size="large"
            icon="reload"
            loading={loading}
          >
            Refresh
          </Button>
        </Button.Group>
      </Item>
      {userTypeID === 2 && (
        <Button type="link" size="large" onClick={generateCSV}>
          Download CSV
        </Button>
      )}
      {pathData.userTypeID === 2 && (
        <LocationSearchPart setFormFields={setFormFields} />
      )}
    </Form>
  );
};

const mapStateToProps = ({ pathData, jwt, advisor: { firebaseUid } }) => ({
  pathData,
  jwt,
  firebaseUid
});

export default withRouter(connect(mapStateToProps)(AgentSearch));
