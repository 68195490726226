import React from "react";
import { connect } from "react-redux";
import { PageHeader, Typography } from "antd";

import AuthControls from "./AuthControls";
import { ReactComponent as Logo } from "assets/nobul_logo.svg";

const { Title } = Typography;

const DashHeader = ({
  dispatch,
  online,
  firebaseUid,
  onlineAdvisors,
  total
}) => {
  return (
    <PageHeader style={{ borderBottom: "1px solid #e8e8e8", paddingTop: 6 }}>
      <div className="flex align-center">
        <Title level={3} style={{ marginBottom: 0 }}>
          <Logo style={{ verticalAlign: "baseline", height: 20 }} />
          Customer Service Dashboard
        </Title>
        <AuthControls
          dispatch={dispatch}
          firebaseUid={firebaseUid}
          onlineAdvisors={onlineAdvisors}
          total={total}
          online={online}
        />
      </div>
    </PageHeader>
  );
};

const mapStateToProps = ({
  advisor: { online, firebaseUid, onlineAdvisors, total }
}) => ({
  online,
  firebaseUid,
  onlineAdvisors,
  total
});

export default connect(mapStateToProps)(DashHeader);
