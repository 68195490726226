import { stitchParams } from "helpers";

export const getChannel = (threadId, params) =>
  fetch(
    `${process.env.REACT_APP_GET_CHANNEL}/${threadId}?${stitchParams(params)}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (!data || !Object.keys(data).length) {
        return [];
      }

      return data;
    })
    .catch((error) => {
      throw new Error(`Error getting communication data ${error}`);
    });
