import { stitchParams } from "helpers";

export const verifyNobulReviews = (payload) =>
  fetch(
    `${process.env.REACT_APP_VERIFY_NOBUL_REVIEWS}?${stitchParams(payload)}`
  )
    .then((response) => response.json())
    .then((verification) => {
      if (!verification) {
        return {
          confirmation: "Review status not set"
        };
      }

      return verification;
    })
    .catch((error) => {
      console.erro("verifyNobulReviews errored out ", error);

      return {
        confirmation: "Review status not set"
      };
    });
