export const nobul_consumerBidSessions = (userKey, details = false) => {
  return fetch(process.env.REACT_APP_CONSUMER_BID_SESSIONS, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    body: `userId=${userKey}`
  })
    .then((response) => response.json())
    .then(({ success, data }) => {
      if (!success) {
        return [];
      }

      const { bid_sessions } = data;

      return bid_sessions || [];
    });
};
