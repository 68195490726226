export const nobul_updateAgent = (payload) => {
  return fetch(process.env.REACT_APP_UPDATE_AGENT_REVIEW, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())
    .then((data) => {
      // skipping profile completion field for now. buggy
      // TODO: payload doesn't match
      if (data && data.reviews) {
        return data.reviews;
      }

      return [];
    });
};
