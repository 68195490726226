import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import socket from "sockets";
import {
  handleNewCSRMessage,
  handleAdvisorStatusUpdate
} from "sockets/message-handlers";
import { COMM_HUB_UPDATE, ADVISOR_STATUS_UPDATE } from "../constants";

const Sockets = ({ history }) => {
  useEffect(() => {
    socket.on(COMM_HUB_UPDATE, (payload) => {
      handleNewCSRMessage(payload);
    });

    socket.on(ADVISOR_STATUS_UPDATE, (payload) => {
      handleAdvisorStatusUpdate(payload, history);
    });

    return () => {
      socket.off(COMM_HUB_UPDATE);
      socket.off(ADVISOR_STATUS_UPDATE);
    };
  }, [history]);

  return null;
};

export default withRouter(Sockets);
