import {
  UPDATE_CONSUMER_LIST,
  GET_CONSUMER_PROFILE,
  UPDATE_BID_SESSIONS,
  GET_BID_SESSION,
  GET_MORE_CONSUMERS,
  FETCHING_CONSUMER_BID_SESSIONS,
  UPDATE_USER_TAGS
} from "core/actionTypes";

export const updateConsumerList = (consumers) => {
  return {
    type: UPDATE_CONSUMER_LIST,
    consumers
  };
};
export const getConsumerProfile = (profile) => {
  return {
    type: GET_CONSUMER_PROFILE,
    profile
  };
};
export const updateBidSessions = (bidSessions) => {
  return {
    type: UPDATE_BID_SESSIONS,
    bidSessions
  };
};
export const getBidSession = (bids = []) => {
  return {
    type: GET_BID_SESSION,
    bids
  };
};

export const getMoreConsumers = (consumers = []) => {
  return {
    type: GET_MORE_CONSUMERS,
    consumers
  };
};

export const fetchingConsumerBidSessions = () => ({
  type: FETCHING_CONSUMER_BID_SESSIONS
});

export const updateUserTags = (firebase_uid, tagData) => ({
  type: UPDATE_USER_TAGS,
  firebase_uid,
  tagData
});
