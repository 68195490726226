export const UPDATE_SEARCH_LIST = "UPDATE_SEARCH_LIST";
export const UPDATE_AGENT_LIST = "UPDATE_AGENT_LIST";
export const UPDATE_CONSUMER_LIST = "UPDATE_CONSUMER_LIST";
export const UPDATE_BIDS = "UPDATE_BIDS";
export const UPDATE_BID_SESSIONS = "UPDATE_BID_SESSIONS";
export const CLOSE_PROFILE = "CLOSE_PROFILE";
export const CHANGE_ROUTE = "CHANGE_ROUTE";
export const ADD_TO_SIDE = "ADD_TO_SIDE";
export const REMOVE_FROM_SIDE = "REMOVE_FROM_SIDE";
export const GET_AGENT_PROFILE = "GET_AGENT_PROFILE";
export const UPDATE_AGENT_PROFILE = "[data] Update agent profile";
export const CACHE_AGENT_PROFILE = "CACHE_AGENT_PROFILE";
export const GET_CONSUMER_PROFILE = "GET_CONSUMER_PROFILE";
export const GET_BID = "GET_BID";
export const GET_BID_SESSION = "GET_BID_SESSION";
export const FETCHING_CONSUMER_BID_SESSIONS = "Fetching consumer bid sessions";
export const GET_CURRENT_SEARCH = "[state] Get Current Search";
export const GET_MORE_AGENTS = "[data] Get More Agents";
export const GET_MORE_CONSUMERS = "[data] Get More Consumers";
export const UPDATE_PROFILE_VIDEO_STATUS = "[state] Update Video Status";
export const UPDATE_PROFILE_VIDEO_STATUS_SEARCH =
  "[state] Update Video Status in Search";
export const UPDATE_AGENT_STATUS_SEARCH =
  "[data] Update agent status in search";
export const UPDATE_AGENT_STATUS_PROFILE =
  "[data] Update agent status in profile";
export const UPDATE_AGENT_REVIEW = "UPDATE_AGENT_REVIEW";
export const UPDATE_AGENT_TRANSACTIONS = "[state] Update agent transactions";

export const ADVISOR_SIGNIN_PENDING = "[state] Advisor signing in...";
export const ADVISOR_SIGNOUT_PENDING = "[state] Advisor signing out...";
export const ADVISOR_AUTHENTICATED = "[auth] Advisor signed in Firebase";
export const ADVISOR_LOGGED_OUT = "[auth] Advisor signed out Firebase";
export const ADVISOR_ONLINE = "[status] Advisor is online";
export const ADVISOR_OFFLINE = "[status] Advisor is offline";
export const ADVISOR_TALLY = "[data] Display advisor tally";

// GENERIC REDUCERS
export const UPDATE_PATH_ID = "UPDATE_PATH_ID";
export const UPDATE_PATH_DATA = "UPDATE_PATH_DATA";

// MESSAGES
export const ADD_MESSAGE_THREAD = "[data] Add a message to the thread list";
export const UPDATE_ACTIVE_THREADS = "[data] Updated active thread list";
export const UPDATE_MESSAGE_IN_THREAD = "[data] Added a message to the thread";
export const ADD_TO_CURRENT_THREADS =
  "[data] Add a thread to the advisor's current pool";
export const UPDATE_CURRENT_THREAD =
  "[data] Update the advisor's current thread";
export const SET_CURRENT_THREADS =
  "[data] Set the advisor's current thread list";
export const SET_UNASSIGNED_THREADS = "[data] Set unasigned message threads";
export const ADD_TO_UNASSIGNED_THREADS =
  "[data] Add a thread to the unassigned message pool";
export const UPDATE_UNASSIGNED_THREAD = "[data] Update an unassigned thread";
export const SET_ACTIVE_THREAD = "[data] Set active thread in drawer";
export const PRESET_ACTIVE_THREAD = "[data] Preset active thread with IDs";
export const UPDATE_ACTIVE_THREAD =
  "[data] Active advisor thread updated with a new message";
export const TOGGLE_THREAD_DRAWER = "[state] Toggle active thread drawer";

// SIGNALS TYPES
export const SIGNAL_SEARCH = "[signal] SEARCH_DISPATCHED";
export const SIGNAL_SEARCH_SUCCESS = "[signal] SEARCH_SUCCESSFUL";
export const SIGNAL_SEARCH_FAILED = "[signal] SEARCH_FAILED";
export const SIGNAL_GETADP_DISPATCHED = "[signal] GETADP_DISPATCHED";
export const SIGNAL_GETADP_SUCCESSFUL = "[signal] GETADP_SUCCESSFUL";
export const SIGNAL_GETADP_FAILED = "[signal] GETADP_FAILED";
export const SIGNAL_ADVISOR_SIGNIN_FAILED =
  "[signal] Advisor authentication failed";
export const SIGNAL_FETCHING_MESSAGES = "[signal] fetching message threads...";
export const SIGNAL_FETCHED_MESSAGES = "[signal] message threads retreived";
export const SIGNAL_FETCHING_MESSAGES_FAILED =
  "[signal] failed to fetch message threads";
export const SIGNAL_SENDING_MESSAGE = "[signal] sending message...";
export const SIGNAL_SENDING_MESSAGE_FAILED = "[signal] message failed to send";
export const SET_TOKEN = "[jwt] Set JWT token";
export const FETCH_RECOMMENDED_PROPERTIES =
  "[signal] [state] Fetching recommended properties";
export const SET_RECOMMENDED_PROPERTIES = "[data] Set recommended properties";
export const RECOMMENDED_PROPERTIES_ERROR =
  "[signal] [error] Failed to get recommended properties";
export const SET_RECOMMENDED_PROPERTIES_PAGE =
  "[ui] Set recommended properties page";
export const RECOMMENDED_PROPERTIES_FAVORITE =
  "[data] Toggle favorite recommended property";
export const FETCH_CONSUMER_LISTINGS =
  "[signal] [flag] Fetching consumer listings";
export const SET_CONSUMER_LISTINGS = "[data] Set consumer listings";
export const CONSUMER_LISTINGS_ERROR =
  "[signal] [error] Fetching consumer listings failed";
export const SET_CONSUMER_LISTINGS_PAGE =
  "[ui] Set consumer listing properties page";
export const UPDATE_USER_TAGS = "[data] Update user tags";
