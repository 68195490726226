export const stitchParams = (queryObj = {}) => {
  const keys = Object.keys(queryObj);
  const KEYS_LENGTH = keys.length;
  const queries = [];

  if (!KEYS_LENGTH) {
    return "";
  }

  const isObject = (value) =>
    typeof value === "object" && value.constructor === Object;

  for (let i = 0; i < KEYS_LENGTH; i++) {
    const key = keys[i];
    const value = queryObj[key];
    if (value || typeof value === "boolean") {
      // this iteration goes one level deep. refactor to recursion
      if (isObject(value)) {
        const objectKeys = Object.keys(value);
        for (let k = 0; k < objectKeys.length; k++) {
          const objectKey = objectKeys[k];
          if (isObject(value[objectKey])) {
            const subObjectKeys = Object.keys(value[objectKey]);
            for (let m = 0; m < subObjectKeys.length; m++) {
              const subObjectKey = subObjectKeys[m];
              queries.push(
                `${key}[${objectKey}][${subObjectKey}]=${value[objectKey][subObjectKey]}`
              );
            }
          } else {
            queries.push(`${key}[${objectKey}]=${value[objectKey]}`);
          }
        }
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const item = queryObj[key][i];
          queries.push(`${key}[]=${item}`);
        }
      } else {
        queries.push(`${key}=${value}`);
      }
    }
  }

  return queries.join("&");
};

// export const stitchParams = queryObj => {
//   let keys = Object.keys(queryObj);
//   const KEYS_LENGTH = keys.length;
//   let queries = [];

//   for (let i = 0; i < KEYS_LENGTH; i++) {
//     const key = keys[i];
//     let value = queryObj[key];
//     if (value || typeof value === "boolean") {
//       if (Array.isArray(value)) {
//         for (let i = 0; i < value.length; i++) {
//           const item = queryObj[key][i];
//           queries.push(`${key}[]=${item}`);
//         }
//       } else {
//         queries.push(`${key}=${value}`);
//       }
//     }
//   }
//   return queries.join("&");
// };
