import { message } from "antd";
import { nobul_updateAgentStatus } from "api";
import {
  updateAgentStatusProfile,
  updateAgentStatusSearch
} from "core/actions/agentActions";

export const updateAgentStatus = (_, data, advisor, dispatch) => {
  // loading indicator before success or error resolves
  message.loading({
    content: "Updating agent status...",
    duration: 0
  });

  if (data.agentStatusId === 4 && !data.statusReason) {
    message.destroy();
    return message.warning("Please provide a reason for suspension");
  }

  const {
    firstName: statusChangedByFirstName,
    lastName: statusChangedByLastName,
    email: statusChangedByEmail
  } = advisor;

  const payload = {
    statusChangedByFirstName,
    statusChangedByLastName,
    statusChangedByEmail,
    ...data
  };

  // TODO: update this to use nobul_updateAuth
  nobul_updateAgentStatus(payload)
    .then((response) => {
      const {
        message: responseMessage,
        status,
        status_type,
        agent_id
      } = response;
      let profileDrawerOpen =
        window.location.pathname.split("/")[3] === "profile";

      message.destroy();

      if (status_type === `STATUS_UPDATED`) {
        message.success(responseMessage, 2.5);

        if (profileDrawerOpen) {
          dispatch(updateAgentStatusProfile(status));
          dispatch(updateAgentStatusSearch(status, agent_id));
        } else {
          dispatch(updateAgentStatusSearch(status, agent_id));
        }
      } else {
        message.warning(responseMessage, 2.5);
      }
    })
    .catch((error) => {
      message.destroy();
      message.error("Agent verification failed.", 2.5);
      console.error("agentVerification vailed ", error);
    });
};
