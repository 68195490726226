import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Table, Icon, Tag, Typography } from "antd";
import { upperCase } from "lodash";

import { verifyRejectReview } from "helpers";
import PopconfirmUpdater from "widgets/UpdateAgentStatus";
import PopconfirmVerifyReview from "widgets/UpdateAgentStatus/PopconfirmVerifyReview";

import "./DashTables.scss";

const { Title } = Typography;

const setTagColor = (isVerified) => {
  switch (isVerified) {
    case "verified":
      return "blue";
    case "rejected":
      return "orange";
    default:
      return "";
  }
};

export const AgentReviews = ({ reviews, agentProfile }) => {
  const columns = [
    {
      title: "Reviewer Name",
      dataIndex: "author",
      width: 228,
      render: (text) => <span>{text}</span>
    },
    {
      title: "Review",
      dataIndex: "message",
      render: (text) => <p>{text}</p>
    },
    {
      title: "Rating",
      dataIndex: "rate",
      width: 114,
      render: (text) =>
        [0, 0, 0, 0, 0].map((star, index) => (
          <Icon
            key={index}
            theme={Number(text) > index ? "filled" : "outlined"}
            type="star"
          />
        ))
    },
    {
      title: "Status",
      dataIndex: "isVerified",
      render: (text, record) => {
        const { reviewId, isVerified, author } = record;
        const { firstName, agentFirebaseId } = agentProfile;

        return (
          <PopconfirmUpdater
            key={reviewId}
            placement="left"
            PopconfirmForm={PopconfirmVerifyReview}
            popconfirmFormProps={{
              name: firstName
            }}
            onConfirm={verifyRejectReview}
            data={{
              isVerified,
              agentFirebaseId,
              reviewId,
              author
            }}
          >
            <Tag
              key={reviewId}
              color={setTagColor(isVerified)}
              style={{ cursor: "pointer" }}
            >
              {upperCase(isVerified)}
            </Tag>
          </PopconfirmUpdater>
        );
      }
    }
  ];

  const onRow = useCallback((record) => record.reviewId, []);

  return (
    <>
      <Title level={3}>{agentProfile.agentName}'s Reviews</Title>
      <Table
        bordered
        loading={false}
        columns={columns}
        dataSource={reviews.reviews}
        rowKey={onRow}
      />
    </>
  );
};

const mapStateToProps = ({
  agentsView: { agentProfile },
  verifyingCSRAgent
}) => ({
  verifyingCSRAgent,
  agentProfile
});

export default connect(mapStateToProps)(AgentReviews);
