import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Skeleton, Table, Typography, Button, Tag } from "antd";
import dayjs from "dayjs";

import { updateActiveThreads } from "core/actions";
import { getChannels } from "../../api/getChannels";
import { presetActiveThread } from "core/actions";
import { formatPhoneNumber } from "helpers";

import "./style.scss";

const { Title } = Typography;

const Communications = ({
  match,
  rows = 10,
  dispatch,
  activeThreads,
  agentFirebaseId,
  advisorId,
  firebaseUid,
  consumerFirebaseId,
  consumerFirstName,
  consumerLastName,
  agentFirstName,
  agentLastName,
  advisorName,
  userTypeID,
  presetActiveThread,
  updateActiveThreads,
  extraColumns,
  history
}) => {
  const firstName = userTypeID === 2 ? agentFirstName : consumerFirstName;
  const lastName = userTypeID === 2 ? agentLastName : consumerLastName;

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [hideInit, setHideInit] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);

  const onRow = useCallback(
    (thread) => {
      return {
        onClick: () => {
          history.push(`communications/${thread.threadId}`);
        }
      };
    },
    [history]
  );

  // TODO update status possibilites
  const columns = useMemo(
    () =>
      [
        {
          title: "First Name",
          dataIndex: "contactFirstName",
          key: "contactFirstName"
        },
        {
          title: "Last Name",
          dataIndex: "contactLastName"
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (tags, record) => {
            let allTags = tags
              ? tags.map((tag, index) => {
                  let color;
                  if (tag === "agent") {
                    color = "geekblue";
                  }
                  if (tag === "unverified") {
                    color = "volcano";
                  }
                  if (tag === "csr") {
                    color = "green";
                  }
                  return (
                    <Tag key={index} color={color}>
                      {tag.toUpperCase()}
                    </Tag>
                  );
                })
              : [];

            record.hasNewMessages &&
              allTags.push(
                <Tag key="hasNewMessages" color="#00b2bf">
                  UNREAD MESSAGES
                </Tag>
              );

            return allTags;
          }
        },
        {
          title: "Phone number",
          dataIndex: "contactPhoneNumber",
          render: formatPhoneNumber
        },
        {
          title: "Last updated",
          dataIndex: "updatedAt",
          render: (date) => dayjs(date).format("ddd, MMM D, YYYY [at] (h:mma)")
        }
      ].concat(extraColumns || []),
    [extraColumns]
  );

  useEffect(() => {
    const userID = () => {
      const isConsumer = userTypeID === 1 && consumerFirebaseId;
      const isAgent = userTypeID === 2 && agentFirebaseId;

      return isAgent || isConsumer;
    };

    userID() &&
      getChannels(userID())
        .then((data) => {
          updateActiveThreads(data);
          setLoading(false);
          setTableLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setTableLoading(false);
        });
  }, [
    dispatch,
    agentFirebaseId,
    consumerFirebaseId,
    updateActiveThreads,
    userTypeID,
    refreshCount
  ]);

  useEffect(() => {
    if (activeThreads.length) {
      for (let index = 0; index < activeThreads.length; index++) {
        const thread = activeThreads[index];
        if (thread.isCSR) {
          break;
        } else if (index === activeThreads.length - 1) {
          setHideInit(false);
        }
      }
    } else {
      setHideInit(false);
    }
  }, [activeThreads]);

  // TODO disabled logic is wrong. it needs to initate a thread
  return (
    <Skeleton loading={loading} active>
      <div className="flex">
        <Title level={3}>
          Communications for {firstName} {lastName}{" "}
          <Button
            type="link"
            icon="reload"
            loading={tableLoading}
            onClick={() => {
              setTableLoading(true);
              setRefreshCount((state) => ++state);
            }}
          >
            Refresh this list
          </Button>
        </Title>
        {!hideInit && (
          <Button
            type="primary"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              presetActiveThread({
                isCSR: true,
                threadId: null,
                assignedTo: advisorId,
                senderId: firebaseUid,
                date: new Date().toISOString(),
                messages: [
                  {
                    messageId: 12345,
                    createdAt: new Date().toISOString(),
                    senderId: firebaseUid,
                    receiverId: match.params.id,
                    messageType: "general",
                    content: `Start your chat with ${firstName} ${lastName}`
                  }
                ],
                contactFirstName: `Nobul Advisor - ${advisorName}`,
                contactLastName: "",
                status: ["csr"],
                contactPhoneNumber: "(416) 304-9044",
                tempMessage: true
              });

              // TODO: look into this so it still works
              history.push(`${match.url}/new-thread-id`);
            }}
          >
            New message
          </Button>
        )}
      </div>
      <Table
        bordered
        loading={tableLoading}
        pagination={{ pageSize: rows }}
        className="communications-list"
        rowKey="contactId"
        dataSource={activeThreads}
        columns={columns}
        size="small"
        onRow={onRow}
      />
    </Skeleton>
  );
};

const mapStateToProps = ({
  agentsView,
  consumersView,
  activeThreads,
  advisor,
  pathData: { userTypeID }
}) => ({
  agentFirebaseId: agentsView.agentProfile.agentFirebaseId,
  consumerFirebaseId: consumersView.consumerProfile.firebaseUid,
  consumerFirstName: consumersView.consumerProfile.firstName,
  consumerLastName: consumersView.consumerProfile.lastName,
  agentFirstName: agentsView.agentProfile.firstName,
  agentLastName: agentsView.agentProfile.lastName,
  advisorName: advisor.firstName,
  advisorId: advisor.advisorId,
  firebaseUid: advisor.firebaseUid,
  activeThread: advisor.activeThread,
  activeThreads,
  userTypeID
});

const mapDispatchToProps = {
  presetActiveThread,
  updateActiveThreads
};

export default connect(mapStateToProps, mapDispatchToProps)(Communications);
