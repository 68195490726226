import React from "react";
import { Row, Typography } from "antd";

import LoginForm from "./Form";

import "./DashLogin.scss";

const { Title } = Typography;

const DashLogin = ({ dispatch, firebaseID, authState }) => (
  <Row className="dash-login">
    <Title level={3}>Nobul Advisor Login</Title>
    <LoginForm
      firebaseID={firebaseID}
      authState={authState}
      dispatch={dispatch}
    />
  </Row>
);

export default DashLogin;
