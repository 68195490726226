/**
 * Filter out falsy values and return a string joined by dashes or another char.
 * @param {Array} itemsArray array of items to stitch
 * @param {string} [joiner] the character to join with (defaults to dash)
 */
export const filterAndJoin = (itemsArray, joiner = `-`) => {
  if (Array.isArray(itemsArray)) {
    return itemsArray.length ? itemsArray.filter(Boolean).join(joiner) : ``;
  } else {
    console.error(`filterAndJoinWith expects an array as the first argument`);
  }
};
