import React from "react";
import { Typography, Icon, notification } from "antd";

import { dashboardStore } from "core/index";
import {
  advisorTally,
  updateActivethread,
  toggleThreadDrawer
} from "core/actions";
import { nobul_userSettings } from "api";
import { isActiveThread } from "../helpers";

import mikeChime from "assets/audio/mike-mail.weba";
import defaultChime from "assets/audio/chime.mp3";

const { getState, dispatch } = dashboardStore;
const { Paragraph } = Typography;

const mikeAudio = new Audio(mikeChime);
const defaultAudio = new Audio(defaultChime);

const openNotification = (advisorLastName, config) => {
  advisorLastName === "Vorbeck" ? mikeAudio.play() : defaultAudio.play();

  return notification.open(config);
};

export const handleNewCSRMessage = ({ threadId, message }) => {
  const {
    advisor: {
      lastName: advisorLastName,
      userId,
      activeThread,
      activeThreadOpen
    }
  } = getState();
  const { assignedTo, senderId, messageId, content } = message;

  const assignedToThisAdvisor = assignedTo === userId;
  const isUnassigned = assignedTo === null;

  // show notification if the advisor doesn't have the thread open
  nobul_userSettings(senderId).then((response) => {
    const { firstName, lastName, userType } = response;
    const icon = userType === "consumer" ? "user" : "qq";
    const title = isUnassigned
      ? `Message to Nobul Advisor`
      : `Message from ${firstName} ${lastName}`;

    const config = {
      icon: <Icon type={icon} />,
      onClick: () => {
        dispatch(toggleThreadDrawer(threadId));

        notification.destroy();
      },
      message: (
        <div style={{ cursor: "default" }}>
          <Paragraph style={{ marginBottom: 0 }} strong>
            {title}
          </Paragraph>
          <Paragraph
            type="secondary"
            style={{ marginBottom: 0 }}
            ellipsis={content.message.length > 50}
          >
            {content.message}
          </Paragraph>
        </div>
      ),
      duration: isUnassigned ? 3 : 0,
      placement: "topLeft",
      top: 96,
      key: messageId
    };

    // messages assigned to the logged in csr advisor or
    // unassigned messages from the advisor pool (for csrs to self-assign)
    if (assignedToThisAdvisor || isUnassigned) {
      if (isActiveThread(threadId, activeThread.threadId)) {
        dispatch(updateActivethread(message));

        if (!activeThreadOpen) {
          openNotification(advisorLastName, config);
        }
      } else {
        openNotification(advisorLastName, config);
      }
    }
  });
};

export const handleAdvisorStatusUpdate = (statusData) => {
  const { onlineAdvisors, total } = statusData;

  dispatch(advisorTally({ onlineAdvisors, total }));
};
