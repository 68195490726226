import React from "react";
import AgentMatchesTables from "./AgentMatchesTables";

const AgentMatchesPendingRow = ({ pendingMatches, userId }) => (
  <AgentMatchesTables
    data={pendingMatches}
    tableName="pending_review"
    title="Pending Matches"
    userId={userId}
  />
);

export default AgentMatchesPendingRow;
