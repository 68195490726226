import React from "react";
import { Tag } from "antd";
import { upperCase } from "lodash";
import { ReactComponent as Badge } from "assets/safty-badge.svg";
import { updateAgentStatus } from "helpers/verifyAgent.js";

import PopconfirmUpdater from "widgets/UpdateAgentStatus";
import PopconfirmStatusForm from "widgets/UpdateAgentStatus/PopconfirmStatusForm";

import { AGENT_STATUSES, AGENT_STATUS_COLORS } from "../constants";

export const renderTag = (tag, record) => {
  const updaterData = {
    statusReason: "",
    agentStatusId: 0,
    agentUserKey: record.user_key
  };

  let color;
  const tagIndex = AGENT_STATUSES.indexOf(tag);

  if (~tagIndex) {
    return (
      <PopconfirmUpdater
        key={tag}
        PopconfirmForm={PopconfirmStatusForm}
        popconfirmFormProps={{ name: record.user_name }}
        onConfirm={updateAgentStatus}
        data={updaterData}
      >
        <Tag
          key={tag}
          color={AGENT_STATUS_COLORS[tagIndex]}
          style={{ cursor: "pointer" }}
        >
          {upperCase(tag)}{" "}
          {tagIndex === 0 && <Badge className="safety-badge" />}
        </Tag>
      </PopconfirmUpdater>
    );
  }

  if (tag === "agent") {
    return null;
  }

  if (tag === "blocked") {
    color = "red";
  }

  return (
    <Tag key={tag} color={color}>
      {tag.toUpperCase()}
    </Tag>
  );
};
