import React from "react";
import { Badge, Icon, Typography } from "antd";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

export const setBidStatusbadge = (status, blank = false) => {
  const text = startCase(status);
  const loweredStatus = toLower(status);

  switch (loweredStatus) {
    case "open":
      return (
        <Badge
          status="processing"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "accepted":
      return (
        <Badge
          status="success"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "declined":
      return (
        <Badge
          status="error"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "expired":
      return (
        <Badge
          status="warning"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "closed":
      return (
        <Badge
          status="error"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "cancelled":
      return (
        <Badge
          color="volcano"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "agents not available":
      return (
        <Badge
          color="yellow"
          text={text}
          className={blank ? "blank-badge" : ""}
          title={status}
        />
      );
    case "system-bid_no-quote":
      return blank ? (
        <Icon type="experiment" style={{ color: "#00b2bf" }} />
      ) : (
        <Typography.Text>
          <Icon type="experiment" style={{ color: "#00b2bf" }} /> Experiment
        </Typography.Text>
      );
    case "system-bid_declined":
      return blank ? (
        <Icon type="experiment" style={{ color: "#00b2bf" }} />
      ) : (
        <Typography.Text>
          <Icon type="experiment" style={{ color: "#00b2bf" }} /> Experiment
        </Typography.Text>
      );
    default:
      return (
        <Badge
          status="default"
          text="No status"
          className={blank ? "blank-badge" : ""}
          title="No status"
        />
      );
  }
};

export default setBidStatusbadge;
