import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { clearTabURL } from "routes/routeTabs";
import { Typography, Drawer, Descriptions } from "antd";
import { startCase } from "lodash";

const { Item } = Descriptions;

export const BidDrawer = ({ viewingBid }) => {
  let { bid } = useParams();
  let history = useHistory();
  let { pathname } = history.location;

  const bidKeys = [
    "created_at",
    "submitted",
    "bid_status",
    "time_remaining_to_bid",
    "transaction_type",
    "area_names",
    "array_agg",
    "budget",
    "client_name",
    "bid_submitted_at",
    "buyer_rebate_offer",
    "seller_rebate_offer",
    "services",
    "message"
  ];

  const bidDetails = bidKeys.map((key) => {
    if (key === "services" && viewingBid["services"]) {
      let services = viewingBid["services"].join(", ");
      return {
        title: startCase(key),
        description: services
      };
    }

    if (key === "budget") {
      return {
        title: "Budget:",
        description: (
          <span>
            {viewingBid[key].sell && <span>Sell: {viewingBid[key].sell}</span>}
            {viewingBid[key].buy && <span>Buy: {viewingBid[key].buy}</span>}
          </span>
        )
      };
    }

    // property_types ommitted for now

    return {
      title: startCase(key),
      description: viewingBid[key] || "N/A"
    };
  });

  let [visibleBid, setVisibleBid] = useState(Boolean(bid));

  useEffect(() => {
    setVisibleBid(Boolean(bid));
  }, [viewingBid, bid, bidDetails]);

  return (
    <Drawer
      visible={visibleBid}
      closable
      width="50%"
      onClose={() => history.push(clearTabURL(pathname, 1))}
    >
      <Typography.Title level={3}>Full Bid Details</Typography.Title>
      <Descriptions bordered layout="vertical">
        {bidDetails.map((detail) => (
          <Item key={detail.title} label={detail.title}>
            {detail.description}
          </Item>
        ))}
      </Descriptions>
    </Drawer>
  );
};

export default BidDrawer;

// {/* <List title="Full Bid Details" layout="vertical" dataSource={bidList} renderItem={item => (
//   <Item key={item.title}><Meta title={item.title} description={item.description} /></Item>
// )}>
// </List> */}
