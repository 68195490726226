export const propertyTypes = [
  {
    propertyTypeId: [1],
    buildingTypeId: null,
    key: `house-townhouse`,
    caption: {
      default: `House / Townhouse`,
      us: `House / Townhouse`
    },
    pluralCaption: {
      default: `House / Townhouses`,
      us: `House / Townhouses`
    },
    seoCaption: {
      default: { single: `Houses`, plural: `Houses` },
      us: { single: `Houses`, plural: `Houses` }
    }
  },
  {
    propertyTypeId: [2],
    buildingTypeId: [4, 5],
    key: `condo`,
    caption: {
      default: `Condo`,
      us: `Condo`
    },
    pluralCaption: {
      default: `Condos`,
      us: `Condos`
    },
    seoCaption: {
      default: { single: `Condo`, plural: `Condos` },
      us: { single: `Condo`, plural: `Condos` }
    }
  },
  {
    propertyTypeId: [3],
    buildingTypeId: null,
    key: `recreational`,
    caption: {
      default: `Recreational`,
      us: `Recreational`
    },
    pluralCaption: {
      default: `Recreational properties`,
      us: `Recreational properties`
    },
    seoCaption: {
      default: {
        single: `Recreational Property`,
        plural: `Recreational Property`
      },
      us: {
        single: `Recreational Property`,
        plural: `Recreational Property`
      }
    }
  },
  {
    propertyTypeId: [4],
    buildingTypeId: null,
    key: `commercial`,
    caption: {
      default: `Commercial`,
      us: `Commercial`
    },
    pluralCaption: {
      default: `Commercial properties`,
      us: `Commercial properties`
    },
    seoCaption: {
      default: {
        single: `Commercial Property`,
        plural: `Commercial Property`
      },
      us: {
        single: `Commercial Property`,
        plural: `Commercial Property`
      }
    }
  },
  {
    propertyTypeId: [5],
    buildingTypeId: null,
    key: `other`,
    caption: {
      default: `Other`,
      us: `Other`
    },
    pluralCaption: {
      default: `Other properties`,
      us: `Other properties`
    },
    seoCaption: {
      default: {
        single: `Other Property`,
        plural: `Other Property`
      },
      us: {
        single: `Other Property`,
        plural: `Other Property`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [1],
    key: `detached-house`,
    caption: {
      default: `Detached House`,
      us: `Single Family Home`
    },
    pluralCaption: {
      default: `Detached houses`,
      us: `Single Family homes`
    },
    seoCaption: {
      default: {
        single: `House`,
        plural: `Houses`
      },
      us: {
        single: `House`,
        plural: `Houses`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [2],
    key: `semi-detached`,
    caption: {
      default: `Semi-Detached House`,
      us: `Duplex House`
    },
    pluralCaption: {
      default: `Semi-Detached Houses`,
      us: `Duplex Homes`
    },
    seoCaption: {
      default: {
        single: `House`,
        plural: `Houses`
      },
      us: {
        single: `House`,
        plural: `Houses`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [3, 5],
    key: `townhouse`,
    caption: {
      default: `Townhouse`,
      us: `Townhouse`
    },
    pluralCaption: {
      default: `Townhouses`,
      us: `Townhouses`
    },
    seoCaption: {
      default: {
        single: `Townhouse`,
        plural: `Townhouses`
      },
      us: {
        single: `Townhouse`,
        plural: `Townhouses`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [6],
    key: `vacant-land`,
    caption: {
      default: `Land`,
      us: `Land`
    },
    pluralCaption: {
      default: `Lands`,
      us: `Lands`
    },
    seoCaption: {
      default: {
        single: `Land`,
        plural: `Lands`
      },
      us: {
        single: `Land`,
        plural: `Lands`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [8],
    key: `parking`,
    caption: {
      default: `Parking`,
      us: `Parking`
    },
    pluralCaption: {
      default: `Parkings`,
      us: `Parkings`
    },
    seoCaption: {
      default: {
        single: `Parking`,
        plural: `Parkings`
      },
      us: {
        single: `Parking`,
        plural: `Parkings`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [9],
    key: `manufactured-mobile`,
    caption: {
      default: `Mobile home`,
      us: `Mobile home`
    },
    pluralCaption: {
      default: `Mobile homes`,
      us: `Mobile homes`
    },
    seoCaption: {
      default: {
        single: `Mobile home`,
        plural: `Mobile homes`
      },
      us: {
        single: `Mobile home`,
        plural: `Mobile homes`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [10],
    key: `retail`,
    caption: {
      default: `Retail`,
      us: `Retail`
    },
    pluralCaption: {
      default: `Retail properties`,
      us: `Retail properties`
    },
    seoCaption: {
      default: {
        single: `Retail Property`,
        plural: `Retail Property`
      },
      us: {
        single: `Retail Property`,
        plural: `Retail Property`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [11],
    key: `storage`,
    caption: {
      default: `Storage`,
      us: `Storage`
    },
    pluralCaption: {
      default: `Storages`,
      us: `Storages`
    },
    seoCaption: {
      default: {
        single: `Storage`,
        plural: `Storages`
      },
      us: {
        single: `Storage`,
        plural: `Storages`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [12],
    key: `offices`,
    caption: {
      default: `Offices`,
      us: `Offices`
    },
    pluralCaption: {
      default: `Offices`,
      us: `Offices`
    },
    seoCaption: {
      default: {
        single: `Office`,
        plural: `Offices`
      },
      us: {
        single: `Office`,
        plural: `Offices`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [13],
    key: `co-op-apartment`,
    caption: {
      default: `Co-Op Apartment`,
      us: `Co-Op Apartment`
    },
    pluralCaption: {
      default: `Co-Op apartments`,
      us: `Co-Op apartments`
    },
    seoCaption: {
      default: {
        single: `Co-Op Apartment`,
        plural: `Co-Op Apartments`
      },
      us: {
        single: `Co-Op Apartment`,
        plural: `Co-Op Apartments`
      }
    }
  },
  {
    propertyTypeId: null,
    buildingTypeId: [14],
    key: `industrial`,
    caption: {
      default: `Industrial`,
      us: `Industrial`
    },
    pluralCaption: {
      default: `Industrial properties`,
      us: `Industrial properties`
    },
    seoCaption: {
      default: {
        single: `Industrial Property`,
        plural: `Industrial Property`
      },
      us: {
        single: `Industrial Property`,
        plural: `Industrial Property`
      }
    }
  }
];
