import React, { Fragment } from "react";
import { Typography, Switch, Button } from "antd";

import { signOutFirebase } from "auth/users";
import { advisorOnline, advisorOffline, advisorTally } from "core/actions";
import { updateUserStatus, setStatus } from "api";

const { Text } = Typography;

const AuthControls = ({
  dispatch,
  online,
  firebaseUid,
  onlineAdvisors,
  total
}) => {
  if (!firebaseUid) {
    return null;
  }

  const received = typeof onlineAdvisors === "number";
  const moreAdvisorCount = onlineAdvisors - 1;
  const advisorCountWord = moreAdvisorCount === 1 ? "advisor" : "advisors";
  const verb = moreAdvisorCount === 1 ? "is" : "are";

  return (
    <Fragment>
      <div style={{ marginLeft: 36 }}>
        <Text strong>I am</Text>
        <Switch
          onClick={() => {
            const connectionStatus = online ? "offline" : "online";
            const payload = {
              connectionStatus,
              firebaseId: firebaseUid,
              setFrom: "dashboard",
              userType: "advisor"
            };

            updateUserStatus(firebaseUid, connectionStatus)
              .then(() =>
                online ? dispatch(advisorOffline()) : dispatch(advisorOnline())
              )
              .catch(() =>
                online ? dispatch(advisorOnline()) : dispatch(advisorOffline())
              )
              .then(() =>
                setStatus(payload).then((status) =>
                  dispatch(advisorTally(status))
                )
              )
              .catch(() =>
                setStatus(payload).then((status) =>
                  dispatch(advisorTally(status))
                )
              );
          }}
          checkedChildren="online"
          unCheckedChildren="offline"
          checked={online}
          style={{ verticalAlign: "baseline", marginLeft: 6 }}
        />
        {received && (
          <Text
            type={moreAdvisorCount ? "secondary" : "danger"}
            strong
            style={{ marginLeft: 36, verticalAlign: "middle" }}
          >
            {moreAdvisorCount} more {advisorCountWord} {verb} also online
          </Text>
        )}
      </div>
      <Button onClick={signOutFirebase} style={{ marginLeft: "auto" }}>
        Log out
      </Button>
    </Fragment>
  );
};

export default AuthControls;
