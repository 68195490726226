import React, { useState } from "react";
import { Button } from "antd";
import { startCase } from "lodash";

import { nobul_updateAgentFeedMatches } from "api/nobul_updateAgentFeedMatches";

const FeedMatchUpdateButton = ({
  status,
  userId,
  matchAgentIds,
  returnMatches = false
}) => {
  const [_status, set_status] = useState(status);
  const [_loading, set_loading] = useState(false);

  return (
    <Button
      block
      className="feed-match-dropdown"
      trigger={["click"]}
      loading={_loading}
      type={_status === "Matched" ? "primary" : "ghost"}
      onClick={(event) => {
        // const { value } = event.target;

        set_loading(true);
        set_status("Matching");

        nobul_updateAgentFeedMatches(userId, matchAgentIds, returnMatches)
          .then((response) => {
            console.log(response);
            set_loading(false);
            // TODO Update this based on response
            if (response === "Matched") {
              set_status("Unmatched");
            } else {
              set_status("Matched");
            }
          })
          .catch(() => set_loading(false));
      }}
    >
      {startCase(_status)}
    </Button>
  );
};

export default FeedMatchUpdateButton;
