import {
  FETCH_RECOMMENDED_PROPERTIES,
  SET_RECOMMENDED_PROPERTIES,
  RECOMMENDED_PROPERTIES_ERROR,
  RECOMMENDED_PROPERTIES_FAVORITE,
  SET_RECOMMENDED_PROPERTIES_PAGE
} from "../actionTypes";

const defaultState = { userId: "", fetching: false, page: 1, properties: [] };

// TODO: will manage favourited listings here
const recommendedPropertiesReducer = (state = defaultState, action) => {
  const { type, properties, userId, isFavorite, listingId, page } = action;

  switch (type) {
    case FETCH_RECOMMENDED_PROPERTIES:
      return {
        ...state,
        fetching: true
      };

    case SET_RECOMMENDED_PROPERTIES:
      return {
        ...state,
        userId,
        properties,
        page: 1,
        fetching: false
      };

    case RECOMMENDED_PROPERTIES_ERROR:
      return {
        ...state,
        page: 1,
        fetching: false
      };

    case RECOMMENDED_PROPERTIES_FAVORITE:
      return {
        ...state,
        properties: state.properties.map((property) => {
          if (listingId === property.listingId) {
            return { ...property, isFavorite };
          }
          return property;
        })
      };

    case SET_RECOMMENDED_PROPERTIES_PAGE:
      return {
        ...state,
        page
      };

    default:
      return state;
  }
};

export default recommendedPropertiesReducer;
