export const getNobulReviews = (url) =>
  fetch(url)
    .then((response) => response.json())
    .then((result) => {
      if (!result) {
        console.error("getNobulReviews failed");

        return [];
      }

      return result.reviews;
    })
    .catch((error) => console.error("getNobulReviews failed ", error));
