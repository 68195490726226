export const makeBounds = (latsLons) => {
  if (!latsLons.length) {
    return [
      [43.649674, -79.40147],
      [43.670575, -79.380452]
    ];
  }

  const sortedLats = latsLons[0].sort((a, b) => a - b);
  const sortedLons = latsLons[1].sort((a, b) => a - b);

  const minLat = sortedLats[0];
  const minLon = sortedLons[0];
  const maxLat = sortedLats[sortedLats.length - 1];
  const maxLon = sortedLons[sortedLons.length - 1];

  return [
    [minLat, minLon],
    [maxLat, maxLon]
  ];
};

export const getLatsLons = (properties) => {
  if (!properties || !properties.length) {
    return null;
  }

  const PROPERTIES_LENGTH = properties.length;

  const bounds = [];

  for (let index = 0; index < PROPERTIES_LENGTH; index++) {
    bounds.push([properties[index].lat, properties[index].lon]);
  }

  return bounds;
};
