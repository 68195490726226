import React from "react";
import dayjs from "dayjs";
import { startCase } from "lodash";
import { Tag, Table } from "antd";
import { convert2dollars } from "helpers";
import FeedMatchUpdateButton from "components/AgentMatches/helpers/FeedMatchUpdateButton";

// TODO Pending columns and data
// TODO Status updates as needed
export const getColumns = (tableName, userId) => {
  switch (tableName) {
    case "matched_agents":
      return [
        {
          title: "Agent Name",
          dataIndex: "agent_name",
          key: "agent_name"
        },
        {
          title: "Brokerage",
          dataIndex: "brokerage_name",
          key: "brokerage_name"
        },
        {
          title: "Agent ID",
          dataIndex: "agent_id",
          key: "agent_id",
          render: startCase
        },
        {
          title: "Feed",
          dataIndex: "feed",
          key: "feed"
        }
      ];

    case "past_transactions":
      return [
        {
          title: "MLS Number",
          dataIndex: "mls_numer",
          key: "mls_numer"
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
          render: (address) => {
            return typeof address === `string`
              ? address.split(", ").reduce((street, rest, index) => {
                  if (index === 0) {
                    return `${street}\n`;
                  }
                  return `${street}${index === 1 ? "\n" : ", "}${rest}`;
                })
              : `N/A`;
          }
        },
        {
          title: "Sale Date",
          dataIndex: "sale_date",
          key: "sale_date",
          render: (date) => dayjs(date).format("MMM DD, YYYY")
        },
        {
          title: "Close Price",
          dataIndex: "close_price",
          key: "close_price",
          render: convert2dollars
        },
        {
          title: "Client Type",
          dataIndex: "transaction_side",
          key: "transaction_side"
        },
        {
          title: "Listing",
          dataIndex: "pdp_url",
          key: "pdp_url",
          render: (url) => (
            <a href={url} target="_blank" rel="noopener noreferrer">
              View PDP
            </a>
          )
        }
      ];

    case "non_matches":
      return [
        {
          title: "Agent Name",
          dataIndex: "agent_name",
          key: "agent_name"
        },
        {
          title: "Brokerage",
          dataIndex: "brokerage_name",
          key: "brokerage_name"
        },
        {
          title: "Agent ID",
          dataIndex: "agent_id",
          key: "agent_id",
          render: startCase
        },
        {
          title: "Feed",
          dataIndex: "feed",
          key: "feed"
        },
        {
          title: "Match By",
          dataIndex: "match_type",
          key: "match_type",
          render: startCase
        },
        {
          title: "Status",
          dataIndex: "audit_status",
          key: "audit_status",
          render: (text) => <Tag color="magenta">{text}</Tag>
        }
      ];

    case "pending_review":
      return [
        {
          title: "Agent Name",
          dataIndex: "agent_name",
          key: "agent_name"
        },
        {
          title: "Brokerage",
          dataIndex: "brokerage_name",
          key: "brokerage_name"
        },
        {
          title: "Agent ID",
          dataIndex: "agent_id",
          key: "agent_id",
          render: startCase
        },
        {
          title: "Feed",
          dataIndex: "feed",
          key: "feed"
        },
        {
          title: "Match By",
          dataIndex: "match_type",
          key: "match_type"
        },
        {
          title: "Status",
          dataIndex: "audit_status",
          key: "audit_status",
          render: (text, record) => {
            return (
              <FeedMatchUpdateButton
                status={text}
                userId={userId}
                matchAgentIds={record.agent_id}
                returnMatches={false}
              />
            );
          }
        }
      ];

    default:
      return [];
  }
};

export const getTransactions = (data) => (
  <Table
    className="transactions-sub-table"
    dataSource={data["past_transactions"]}
    columns={getColumns("past_transactions")}
    rowKey={(data) => data.mls_numer}
    pagination={false}
  />
);
