import React, { useCallback, useEffect, useState } from "react";
import { Table, Typography, Button } from "antd";
import { capitalize } from "lodash";

import { addTabURL } from "routes/routeTabs";
import { connect } from "react-redux";
import { getBid, updateBids } from "core/actions";
import { nobul_agentBids } from "api";
import setBidStatusbadge from "helpers/setBidStatusbadge";

const { Title } = Typography;

const BidsTable = ({
  history,
  bids,
  agentId,
  agentName,
  getBid,
  updateBids
}) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: "Submitted",
      dataIndex: "submitted",
      key: "submitted"
    },
    {
      title: "Status",
      dataIndex: "bid_status",
      key: "bid_status",
      render: (text) => setBidStatusbadge(text)
    },
    {
      title: "Time Remaining",
      dataIndex: "time_remaining_to_bid",
      key: "time_remaining_to_bid"
    },
    {
      title: "Buy/Sell",
      dataIndex: "transaction_type",
      key: "transaction_type"
    },
    {
      title: "Location",
      dataIndex: "area_names[0]",
      key: "area_names[0]"
    },
    {
      title: "Type",
      dataIndex: "array_agg[0]",
      key: "array_agg[0]"
    },
    {
      title: "Price range",
      dataIndex: "budget",
      key: "budget",
      render: (budget) =>
        budget.buy && budget.sell
          ? `Buy: ${budget.buy} - Sell: ${budget.sell}`
          : `${capitalize((budget.buy && "Buy") || (budget.sell && "Sell"))}: ${
              budget.buy || budget.sell
            }`
    }
  ];

  const [loading, setLoading] = useState(true);

  const onRow = useCallback(
    (data, index) => {
      return {
        onClick: () => {
          history.push(addTabURL(history.location.pathname, index));
          getBid(index);
        }
      };
    },
    [history, getBid]
  );

  const fetchBids = useCallback(
    (withLoading) => {
      withLoading && setLoading(true);

      nobul_agentBids(agentId)
        .then(updateBids)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    },
    [agentId, updateBids]
  );

  useEffect(() => {
    fetchBids();
  }, [fetchBids]);

  return (
    <>
      <Title level={3}>
        {agentName}'s Bids{" "}
        <Button
          type="link"
          icon="reload"
          loading={loading}
          onClick={() => {
            fetchBids(true);
          }}
        >
          Refresh this list
        </Button>
      </Title>
      <Table
        bordered
        loading={loading}
        className="bids-table"
        dataSource={bids}
        columns={columns}
        rowKey={({ created_at }) => created_at}
        pagination={{ pageSize: 10 }}
        size="small"
        onRow={onRow}
      />
    </>
  );
};

const mapStateToProps = ({
  agentsView: {
    bids,
    agentProfile: { agentId, agentName }
  }
}) => ({
  bids,
  agentId,
  agentName
});

const mapDispatchToProps = {
  getBid,
  updateBids
};

export default connect(mapStateToProps, mapDispatchToProps)(BidsTable);
