export const nobul_searchUsers = (queryObj) => {
  return fetch(process.env.REACT_APP_SEARCH_USERS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(queryObj)
  })
    .then((response) => response.json())
    .then(({ success, data }) => {
      if (!success) {
        return [];
      }

      return data;
    })
    .catch((error) => console.error("nobul_userSearch failed", error));
};
