import {
  UPDATE_SEARCH_LIST,
  UPDATE_PATH_ID,
  UPDATE_PATH_DATA,
  GET_CURRENT_SEARCH
} from "core/actionTypes";

export const updateSearchList = (results) => {
  return {
    type: UPDATE_SEARCH_LIST,
    results
  };
};

export const updateUserTypeID = () => {
  return {
    type: UPDATE_PATH_ID
  };
};

export const updatePathData = (path) => {
  return {
    type: UPDATE_PATH_DATA,
    payload: path
  };
};

export const getCurrentSearch = (search) => {
  return {
    type: GET_CURRENT_SEARCH,
    search
  };
};
