import React from "react";
import AgentMatchesTables from "./AgentMatchesTables";

const AgentMatchesPendingRow = ({ matches, userId }) => (
  <AgentMatchesTables
    data={matches}
    userId={userId}
    tableName="matched_agents"
    title="Matches"
  />
);

export default AgentMatchesPendingRow;
