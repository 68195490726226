import {
  ADVISOR_AUTHENTICATED,
  ADVISOR_LOGGED_OUT,
  ADVISOR_ONLINE,
  ADVISOR_OFFLINE,
  ADVISOR_SIGNIN_PENDING,
  ADVISOR_SIGNOUT_PENDING,
  SIGNAL_ADVISOR_SIGNIN_FAILED,
  ADD_TO_CURRENT_THREADS,
  UPDATE_CURRENT_THREAD,
  SET_CURRENT_THREADS,
  SET_UNASSIGNED_THREADS,
  ADD_TO_UNASSIGNED_THREADS,
  UPDATE_UNASSIGNED_THREAD,
  SET_ACTIVE_THREAD,
  PRESET_ACTIVE_THREAD,
  UPDATE_ACTIVE_THREAD,
  TOGGLE_THREAD_DRAWER,
  ADVISOR_TALLY
} from "../actionTypes";

export const advisorAuthStatePending = (uid) => ({
  type: uid ? ADVISOR_SIGNIN_PENDING : ADVISOR_SIGNOUT_PENDING
});

export const advisorSigninFailedSignal = () => ({
  type: SIGNAL_ADVISOR_SIGNIN_FAILED
});

export const signInAdvisor = (payload) => ({
  type: ADVISOR_AUTHENTICATED,
  payload
});

export const signOutAdvisor = () => ({
  type: ADVISOR_LOGGED_OUT
});

export const advisorOnline = () => ({
  type: ADVISOR_ONLINE
});

export const advisorOffline = () => ({
  type: ADVISOR_OFFLINE
});

export const addToCurrentThreads = (thread) => ({
  type: ADD_TO_CURRENT_THREADS,
  payload: thread
});

export const updateCurrentThread = (message, threadId) => ({
  type: UPDATE_CURRENT_THREAD,
  payload: { message, threadId }
});

export const setCurrentThreads = (threads) => ({
  type: SET_CURRENT_THREADS,
  payload: threads
});

export const advisorTally = (payload) => ({
  type: ADVISOR_TALLY,
  payload
});

export const setUnassignedThreads = (threads) => ({
  type: SET_UNASSIGNED_THREADS,
  payload: threads
});

export const addToUnassignedThreads = (thread) => ({
  type: ADD_TO_UNASSIGNED_THREADS,
  payload: thread
});

export const updateUnassignedThread = (message, threadId) => ({
  type: UPDATE_UNASSIGNED_THREAD,
  payload: { message, threadId }
});

export const setActiveThread = (thread) => ({
  type: SET_ACTIVE_THREAD,
  payload: thread
});

export const presetActiveThread = (payload) => ({
  type: PRESET_ACTIVE_THREAD,
  payload
});

export const updateActivethread = (message) => ({
  type: UPDATE_ACTIVE_THREAD,
  payload: message
});

export const toggleThreadDrawer = (threadId) => {
  return {
    type: TOGGLE_THREAD_DRAWER,
    threadId
  };
};
