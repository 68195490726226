import React, { useState } from "react";
import { Input, Row, Button } from "antd";

import { GENERAL_MESSAGE_TYPE } from "../../../constants";
import { getJWTtoken } from "api";

import "./Messaging.scss";

const { TextArea } = Input;

const MessageInputSection = ({
  advisorFirebaseId,
  isCSR,
  receiverId,
  senderId,
  threadId,
  assignedTo,
  messageUser,
  history
}) => {
  const [content, setContent] = useState();

  const getTokenAndMessage = (event = {}) => {
    if (!event.shiftKey) {
      getJWTtoken().then(({ token }) =>
        messageUser(
          {
            threadId,
            assignedTo,
            senderId,
            receiverId,
            messageType: GENERAL_MESSAGE_TYPE,
            content
          },
          token
        ).then((thread) => {
          // if this was an init message, update the url
          if (!threadId && thread.threadId) {
            history.replace(`${thread.threadId}`);
          }

          return thread;
        })
      );
      setContent("");
    }
  };

  return (
    <Row type="flex" className="message-input-section">
      <TextArea
        allowClear
        rows={4}
        disabled={!advisorFirebaseId && !isCSR}
        value={content}
        onPressEnter={getTokenAndMessage}
        onChange={(event) => {
          const { value } = event.target;
          setContent(value !== "\n" ? value : "");
        }}
      />
      <Button
        disabled={!advisorFirebaseId && !isCSR}
        type="primary"
        onClick={getTokenAndMessage}
      >
        Send message
      </Button>
    </Row>
  );
};

export default MessageInputSection;
