import { Icon, Layout, Menu, message } from "antd";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { updatePathData } from "core/actions";
import { PopoverWithInput } from "widgets";
import { addCredentialsToBlacklist, getJWTtoken } from "api";
import { email, phone } from "constants/regex";

const { Sider } = Layout;
const { Item, Divider } = Menu;

const menuStyle = { height: "100%" };
const inputProps = {
  pattern: [phone, email].map((regex) => regex.source).join("|")
};

const DashSider = () => {
  const { type } = useParams();

  const dispatch = useDispatch();

  const blackListPhone = useCallback((value, buttonElement) => {
    getJWTtoken().then(({ token }) => {
      if (
        !value ||
        !buttonElement.parentElement.previousElementSibling
          .getElementsByTagName("input")[0]
          .checkValidity()
      ) {
        return message.warn("Please enter a valid phone number or email");
      }

      return addCredentialsToBlacklist(
        {
          [value.match(email) ? "offenderEmail" : "offenderPhone"]: value
        },
        token
      ).then((response) => {
        if (response.success) {
          message.success(response.message);
        } else {
          message.error(response.message);
        }
      });
    });
  }, []);

  const copyReviewLink = useCallback(() => {
    navigator.clipboard
      .writeText("https://nobul.page.link/leave-a-review")
      .then(() => message.success("Review link copied to clipboard"));
  }, []);

  const updatePath = useCallback(
    ({ key }) => {
      dispatch(updatePathData(key));
    },
    [dispatch]
  );

  return (
    <Sider>
      <Menu
        mode="inline"
        defaultSelectedKeys={[type]}
        selectedKeys={[type]}
        defaultOpenKeys={[type]}
        openKeys={[type]}
        style={menuStyle}
        onClick={updatePath}
      >
        <Item key="agents">
          <Link to="/agents/">
            <Icon type="qq" />
            Agents
          </Link>
        </Item>
        <Item key="consumers">
          <Link to="/consumers/">
            <Icon type="team" />
            Consumers
          </Link>
        </Item>
        <Item key="advisor">
          <Link to="/advisor/">
            <Icon type="aliwangwang" />
            Advisor
          </Link>
        </Item>
        <Divider />
        <Item key="copy" onClick={copyReviewLink}>
          <Icon type="copy" />
          Get Review Link
        </Item>
        <Item key="greviews">
          <Link to="/google-reviews">
            <Icon type="google" /> Google Reviews
          </Link>
        </Item>
        <Divider />
        <Item key="blacklist-number">
          <PopoverWithInput
            title="Enter a phone number or an email to blacklist"
            onBlacklist={blackListPhone}
            inputProps={inputProps}
          >
            <Link to="#">
              <Icon type="disconnect" />
              Blacklist credential
            </Link>
          </PopoverWithInput>
        </Item>
      </Menu>
    </Sider>
  );
};

export default DashSider;
