import React, { useState } from "react";
import { Popover, Tag } from "antd";
import ReactPlayer from "react-player";

import PopconfirmUpdater from "../UpdateAgentStatus/";
import PopconfirmVerifyVideo from "../UpdateAgentStatus/PopconfirmVerifyVideo";

import { makeVerifyButton, verifyRejectVideo } from "helpers";

const AgentVideoPopover = ({ text, record, color }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  // TODO: the payload is buggy right now. look into it
  const { video_data, user_name, user_key: userId } = record;
  const { provider, url, verificationStatus } = video_data[0];

  const toggledStatus =
    verificationStatus === "rejected" ? "verified" : "rejected";

  return (
    <Popover
      destroyTooltipOnHide
      placement="left"
      trigger="click"
      visible={showTooltip}
      content={
        <div style={{ textAlign: "center" }}>
          <ReactPlayer height={405} width={720} url={url} />
          <div className="video-link">
            <strong>Video URL: </strong>
            <a target="_blank" rel="noreferrer noopener" href={url}>
              {url}
            </a>
          </div>
          <PopconfirmUpdater
            key={url}
            PopconfirmForm={PopconfirmVerifyVideo}
            popconfirmFormProps={{ name: user_name.split(" ")[0] }}
            onConfirm={verifyRejectVideo}
            data={{
              verificationStatus: toggledStatus,
              userId,
              setShowTooltip
            }}
          >
            {makeVerifyButton(verificationStatus, "default", provider)}
          </PopconfirmUpdater>
        </div>
      }
    >
      <Tag
        style={{ cursor: "pointer" }}
        color={color}
        onClick={() => setShowTooltip((state) => !state)}
      >
        {text ? text.toUpperCase() : "NOT SUBMITTED"}
      </Tag>
    </Popover>
  );
};

export default AgentVideoPopover;
