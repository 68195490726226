export const getChannels = (userFirebaseId) =>
  fetch(
    `${process.env.REACT_APP_GET_CHANNELS}/${userFirebaseId}?source=csr-dashboard`,
    {
      method: "GET"
    }
  )
    .then((response) => response.json())
    .then((data) => {
      if (!data || !Object.keys(data).length) {
        return [];
      }

      return data;
    })
    .catch((error) => {
      console.error("Error getting communication data", error);
      return [];
    });
