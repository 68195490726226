import { nobul_searchUsers } from "api";
import { message } from "antd";

export const getMorePages = (currentSearch, offset) => {
  message.loading("Searching...", 0);

  return nobul_searchUsers({
    ...currentSearch,
    offset
  })
    .then((result) => {
      if (!result.length || !result) {
        message.destroy();
        message.info("No more results", 2.2);
      } else {
        message.destroy();
        message.success("Pages retreived", 2.2);
      }

      return result;
    })
    .catch((error) => {
      console.error("paginated search failed ", error);
      message.destroy();
      message.error("Searching failed");
    });
};
