import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Divider, Table, Badge, Progress, Tag } from "antd";

import RenderConnectionStatusBadge from "helpers/setConnectionStatusBadge";
import { Link } from "react-router-dom";
import { getMorePages } from "helpers/getMorePages";
import { getMoreAgents } from "core/actions";
import { renderTag, renderPhoneColumn } from "helpers";

import AgentVideoPopover from "widgets/AgentVideoPopover";
import { KickUserPopover } from "widgets/KickUserPopover";

import "./DashTables.scss";

const AgentListTable = ({
  agents,
  dispatch,
  loading,
  currentSearch,
  advisor
}) => {
  // trigger to prevent empty pagination
  const [limitReached, setLimitReached] = useState(false);

  const fetchAgents = useCallback(
    ({ current }) => {
      const pages = Math.ceil(agents.length / 10);
      const offset = pages * 10 + 50;

      // 2 page stagger before retreiving the next list of agents
      if (current > 2 && pages - 2 < current && !limitReached) {
        getMorePages(currentSearch, offset).then((moreAgents) => {
          if (!moreAgents || !moreAgents.length) {
            setLimitReached(true);
          } else {
            dispatch(getMoreAgents(moreAgents));
          }
        });
      }
    },
    [agents.length, currentSearch, dispatch, limitReached]
  );

  const onRowKey = useCallback((record) => record.user_key, []);

  const columns = useMemo(
    () => [
      {
        title: "Online",
        dataIndex: "connectionStatus",
        key: "connectionStatus",
        render: RenderConnectionStatusBadge,
        className: "connection-status"
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <div>
            <Link
              key={record.firebase_uid}
              to={`/agents/${record.firebase_uid}/profile`}
            >
              {record.user_name}
            </Link>
            <Divider className="dash-divider" />
            <span>
              Referral:{" "}
              {record.referralSigned
                ? `signed on ${record.referralSigned}`
                : "not signed"}
            </span>
          </div>
        )
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        className: "email-column"
      },
      {
        title: "Phone",
        dataIndex: "phone_number",
        key: "phone_number",
        render: renderPhoneColumn
      },
      {
        title: "Bids",
        dataIndex: "active_bids",
        key: "active_bids",
        render: (text, record) => (
          <Link key="bids" to={`/agents/${record.firebase_uid}/bids`}>
            <span style={{ whiteSpace: "nowrap" }}>View {text} Bids</span>
          </Link>
        )
      },
      {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: (tags, record) => (
          <span>{tags.map((tag) => renderTag(tag, record))}</span>
        )
      },
      {
        title: "Match",
        key: "status_audit",
        dataIndex: "status_audit",
        className: "match-status",
        render: (match) => {
          if (match === "AWAITING REVIEW") {
            return <Badge status="processing" />;
          }

          if (match === "CONFIRMED MATCH") {
            return <Badge status="success" />;
          }

          return <Badge status="warning" />;
        }
      },
      {
        title: "Profile",
        key: "profileCompletionScore",
        dataIndex: "profileCompletionScore",
        className: "profile-status",
        render: (percent) => (
          <Progress
            style={{ fontSize: 8 }}
            strokeLinecap="square"
            type="dashboard"
            width={40}
            percent={percent}
          />
        )
      },
      {
        title: "Video",
        dataIndex: "video_data[0][verificationStatus]",
        key: "video_data",
        render: (text, record) => {
          let color = (status) => {
            switch (status) {
              case "verified":
                return "blue";
              case "rejected":
                return "red";
              case "pending":
                return "cyan";
              default:
                return "";
            }
          };

          const { video_data } = record;
          const { verificationStatus } = video_data[0];

          if (!verificationStatus) {
            return (
              <Tag color={color(text)}>
                {text ? text.toUpperCase() : "NOT SUBMITTED"}
              </Tag>
            );
          }

          return (
            <AgentVideoPopover
              text={text}
              color={color(text)}
              record={record}
            />
          );
        }
      },
      {
        title: "Discipline",
        key: "kick",
        render: (text, record) => (
          <KickUserPopover
            user={record}
            advisor={advisor}
            dispatch={dispatch}
          />
        )
      }
    ],
    [advisor, dispatch]
  );

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={agents}
      rowKey={onRowKey}
      className="agents-table"
      onChange={fetchAgents}
    />
  );
};

// TODO: make this like consumers view
const mapStateToProps = ({
  agentsView: { agents },
  currentSearch,
  advisor
}) => ({
  agents,
  currentSearch,
  advisor
});

export default connect(mapStateToProps)(AgentListTable);
