import { NA_PHONE_NUMBERS } from "constants/regex";

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const matches = String(phoneNumber).match(NA_PHONE_NUMBERS);

    if (matches) {
      return matches.slice(1).join("-");
    }
  }

  if (typeof phoneNumber === `undefined` || phoneNumber === null) {
    return;
  }

  return String(phoneNumber);
};
