import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";

import DashLogin from "./DashLogin";

const { Content } = Layout;

const FirebaseAuthentication = ({
  dispatch,
  advisorFirebaseId,
  authState,
  children
}) => {
  if (!advisorFirebaseId) {
    return (
      <Layout>
        <Content
          className="flex center align-center"
          style={{ padding: "0 24px", backgroundColor: "white" }}
        >
          <DashLogin
            advisorFirebaseId={advisorFirebaseId}
            authState={authState}
            dispatch={dispatch}
          />
        </Content>
      </Layout>
    );
  }

  return children;
};

const mapStateToProps = ({
  advisor: { advisorFirebaseId, authState },
  pathData: { userType }
}) => ({
  advisorFirebaseId,
  authState,
  userType
});

export default connect(mapStateToProps)(FirebaseAuthentication);
