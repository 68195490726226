import React from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import DashHeader from "./components/DashHeader";
import DashLayout from "./components/DashLayout";
import FirebaseAuthentication from "./components/Auth";

function App({ history, match }) {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        refreshWhenHidden: false
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <DashHeader />
        <FirebaseAuthentication>
          <DashLayout history={history} match={match} />
        </FirebaseAuthentication>
      </Layout>
    </SWRConfig>
  );
}

const mapStateToProps = ({ advisor: { advisorFirebaseId, authStatus } }) => ({
  advisorFirebaseId,
  authStatus
});

export default withRouter(connect(mapStateToProps)(App));
