export const updateUserStatus = (userId, status) => {
  return fetch(process.env.REACT_APP_UPDATE_USER_STATUS, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `userId=${userId}&status=${status}`
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error(
        "updateUserStatus failed. Advisor Status not updated. ",
        error
      )
    );
};
