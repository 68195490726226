import React, { useCallback, useState } from "react";
import { Popover, Button, Input, Tag } from "antd";
import lowerCase from "lodash/lowerCase";

import {
  nobul_authUpdate,
  dispatchKickSocket,
  blacklistCredentials
} from "api";
import { updateUserTags } from "core/actions";

const KickUserBody = ({ user, onKick, onAuthUpdate, onBlacklistPhone }) => {
  const [reason, setReason] = useState();

  return (
    <div>
      <h4>Are you sure you want to discipline {user.user_name}?</h4>
      <Input.TextArea
        rows="4"
        placeholder="Enter your reason for suspending this user"
        onChange={(event) => setReason(event.target.value)}
      />
      <div style={{ marginTop: 8 }}>
        <Button
          size="small"
          type="danger"
          style={{ marginRight: 8 }}
          onClick={() => onAuthUpdate(reason)}
        >
          Kick and Suspend
        </Button>
        <Button
          size="small"
          type="ghost"
          style={{ marginRight: 8 }}
          onClick={onKick}
        >
          Just Kick
        </Button>
        <Button
          size="small"
          type="danger"
          style={{ marginRight: 8 }}
          onClick={() => onAuthUpdate(reason, `shadow_ban`)}
        >
          Shadow Ban
        </Button>
        <Button
          size="small"
          type="danger"
          style={{ marginRight: 8 }}
          onClick={onBlacklistPhone}
        >
          Blacklist Phone
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => onAuthUpdate(reason, `release_shadow_ban`)}
        >
          Release Shadow Ban and Blacklist
        </Button>
      </div>
    </div>
  );
};

export const KickUserPopover = ({ user, advisor, dispatch }) => {
  const onKick = useCallback(() => {
    dispatchKickSocket(user);
  }, [user]);

  const onAuthUpdate = useCallback(
    (reason, action = `suspend`) => {
      nobul_authUpdate(
        {
          firebaseId: user.firebase_uid,
          action,
          name: `${advisor.firstName} ${advisor.lastName}`,
          email: advisor.email,
          reason
        },
        user.user_name
      ).then(() => {
        const isRelease = action === `release_shadow_ban`;

        const _action = action.includes("shadow_ban")
          ? `shadow_banned`
          : `${lowerCase(action)}ed`;

        const tagData = {
          tags: isRelease
            ? ["shadow_banned", "blacklisted", "suspended"]
            : _action,
          tagAction: isRelease ? `pop` : `toggle`
        };

        dispatch(updateUserTags(user.firebase_uid, tagData));
      });
    },
    [
      user.firebase_uid,
      user.user_name,
      advisor.firstName,
      advisor.lastName,
      advisor.email,
      dispatch
    ]
  );

  const onBlacklistPhone = useCallback(
    () =>
      blacklistCredentials({ offenderId: user.user_key }).then(() =>
        dispatch(
          updateUserTags(user.firebase_uid, {
            tags: "blacklisted",
            tagAction: "toggle"
          })
        )
      ),
    [dispatch, user.firebase_uid, user.user_key]
  );

  return (
    <Popover
      content={
        <KickUserBody
          user={user}
          onKick={onKick}
          onAuthUpdate={onAuthUpdate}
          onBlacklistPhone={onBlacklistPhone}
        />
      }
      placement="left"
    >
      <Tag
        color="#fff"
        style={{
          cursor: `pointer`,
          color: `#ff5942`,
          borderColor: `#ffded9`
        }}
      >
        Actions
      </Tag>
    </Popover>
  );
};

export default KickUserPopover;
