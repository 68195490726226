// CHANGE TO CONSUMERS SIDE
import {
  UPDATE_CONSUMER_LIST,
  UPDATE_BID_SESSIONS,
  GET_CONSUMER_PROFILE,
  GET_BID_SESSION,
  GET_MORE_CONSUMERS,
  FETCHING_CONSUMER_BID_SESSIONS,
  UPDATE_USER_TAGS
} from "../actionTypes";

const defaultConsumersView = {
  consumers: [],
  consumerProfile: {},
  fetchingBidSessions: false,
  bidSessions: [],
  viewingBidSessions: []
};

const consumersReducer = (consumersView = defaultConsumersView, action) => {
  const { consumers } = consumersView;
  switch (action.type) {
    case UPDATE_CONSUMER_LIST:
      return {
        ...consumersView,
        consumers: [...action.consumers]
      };
    case GET_MORE_CONSUMERS:
      return {
        ...consumersView,
        consumers: consumers.concat(action.consumers)
      };
    case GET_CONSUMER_PROFILE:
      return {
        ...consumersView,
        consumerProfile: { ...action.profile }
      };
    case FETCHING_CONSUMER_BID_SESSIONS:
      return {
        ...consumersView,
        fetchingBidSessions: true
      };
    case UPDATE_BID_SESSIONS:
      return {
        ...consumersView,
        fetchingBidSessions: false,
        bidSessions: action.bidSessions
      };
    case GET_BID_SESSION:
      return {
        ...consumersView,
        viewingBidSessions: [...action.bids]
      };
    case UPDATE_USER_TAGS:
      return {
        ...consumersView,
        consumers: consumers.map((consumer) => {
          const tagsArr = [].concat(action.tagData.tags);
          const existingTags = tagsArr.filter(
            (tag) => !~consumer.tags.indexOf(tag)
          );

          const tags =
            action.tagData.tagAction === `pop`
              ? consumer.tags.filter((tag) => !~tagsArr.indexOf(tag))
              : consumer.tags.concat(existingTags);

          return consumer.firebase_uid === action.firebase_uid
            ? {
                ...consumer,
                tags
              }
            : consumer;
        })
      };
    default:
      return consumersView;
  }
};

export default consumersReducer;
