import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { Route } from "react-router-dom";

import { ConsumerProfileContainer } from "components/ConsumerProfile";
import { BidSessionDrawer } from "components/ConsumerProfile";
import CommunicationsMessages from "components/Communications/CommunicationsMessages";
import { nobul_userSettings, nobul_consumerBidSessions } from "api";
import {
  getConsumerProfile,
  fetchingConsumerBidSessions,
  updateBidSessions,
  getBidSession
} from "core/actions";

// either get the agent from the array or most likely make an api call for the profile
const ConsumerDrawer = ({ match, history }) => {
  const { tab, id } = match.params;

  const dispatch = useDispatch();
  const userId = useSelector(
    (state) => state.consumersView.consumerProfile.userId
  );

  const [loading, setLoading] = useState(true);

  const fetchBidSessions = useCallback(
    (withRefresh) => {
      dispatch(fetchingConsumerBidSessions());

      withRefresh && setLoading(true);

      nobul_consumerBidSessions(userId)
        .then((bidSessions) => {
          dispatch(updateBidSessions(bidSessions));

          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [dispatch, userId]
  );

  useEffect(() => {
    if (id) {
      nobul_userSettings(id, true)
        .then((profile) => {
          dispatch(getConsumerProfile(profile));

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);

          console.error(error);
        });

      return () => dispatch(getConsumerProfile({}));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (userId) {
      fetchBidSessions();

      return () => dispatch(updateBidSessions([]));
    }
  }, [dispatch, fetchBidSessions, userId]);

  return (
    <Drawer
      visible={Boolean(tab)}
      onClose={() => history.push("/consumers")}
      width="80%"
    >
      <ConsumerProfileContainer
        match={match}
        history={history}
        loading={loading}
        fetchBidSessions={fetchBidSessions}
        getBidSession={getBidSession}
      />
      <Route path="/:userRoute/:id/bids/:bid/">
        <BidSessionDrawer loading={loading} />
      </Route>
      <Route
        path="/:userRoute/:id/communications/:threadId"
        component={CommunicationsMessages}
      />
    </Drawer>
  );
};

export default ConsumerDrawer;
