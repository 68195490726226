import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import upperCase from "lodash/upperCase";

import { getMorePages, setBidStatusbadge, renderPhoneColumn } from "helpers";
import { getMoreConsumers } from "core/actions";
import { KickUserPopover } from "widgets/KickUserPopover";

const ConsumerTable = ({
  consumers,
  dispatch,
  loading,
  currentSearch,
  advisor
}) => {
  // trigger to prevent empty pagination
  const [limitReached, setLimitReached] = useState(false);

  const fetchConsumers = useCallback(
    ({ current }) => {
      const pages = Math.ceil(consumers.length / 10);
      const offset = pages * 10 + 50;

      // 2 page stagger before retreiving the next list of agents
      if (current > 2 && pages - 2 < current && !limitReached) {
        getMorePages(currentSearch, offset).then((moreConsumers) => {
          if (!moreConsumers || !moreConsumers.length) {
            setLimitReached(true);
          } else {
            dispatch(getMoreConsumers(moreConsumers));
          }
        });
      }
    },
    [consumers.length, currentSearch, dispatch, limitReached]
  );

  const onRowKey = useCallback((record) => record.user_key, []);

  const columns = useMemo(
    () => [
      {
        title: "Deal Date",
        dataIndex: "last_bid_session_started",
        key: "last_bid_session_started",
        render: (date, record) => {
          if (!date) {
            return new Date(record.created_at).toLocaleDateString();
          }
          return new Date(date).toLocaleDateString();
        }
      },
      {
        title: "Deal Status",
        dataIndex: "bid_session_status",
        key: "bid_session_status",
        render: (status) => setBidStatusbadge(status)
      },
      {
        title: "Name",
        dataIndex: "user_name",
        key: "user_name",
        render: (text, record) => (
          <Link
            key={record.firebase_uid}
            to={`/consumers/${record.firebase_uid}/profile`}
          >
            {text || `No Name`}
          </Link>
        )
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        className: "email-column"
      },
      {
        title: "Phone",
        dataIndex: "phone_number",
        key: "phone_number",
        render: renderPhoneColumn
      },
      {
        title: "Prov/State",
        dataIndex: "state_or_province",
        key: "state_or_province"
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => (
          <Link key="bidSessions" to={`/consumers/${record.firebase_uid}/bids`}>
            View Bid Sessions
          </Link>
        )
      },
      {
        title: "Tags",
        key: "tags",
        dataIndex: "tags",
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              let color;
              if (tag === "consumer") {
                return null;
              }
              if (tag === "buyer") {
                color = "geekblue";
              }
              if (tag === "booked viewing") {
                color = "green";
              }
              if (tag === "messaged agent") {
                color = "blue";
              }
              if (tag === "high intent") {
                color = "#00b2bf";
              }
              if (
                tag === "blocked" ||
                tag === "shadow banned" ||
                tag === "shadow_banned" ||
                tag === "suspended" ||
                tag === "blacklisted"
              ) {
                color = "red";
              }
              return (
                <Tag key={tag} color={color}>
                  {upperCase(tag)}
                </Tag>
              );
            })}
          </span>
        )
      },
      {
        title: "Discipline",
        key: "kick",
        render: (text, record) => (
          <KickUserPopover
            user={record}
            advisor={advisor}
            dispatch={dispatch}
          />
        )
      }
    ],
    [advisor, dispatch]
  );

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={consumers}
      rowKey={onRowKey}
      className="consumers-table"
      onChange={fetchConsumers}
    />
  );
};

const mapStateToProps = ({ consumersView, currentSearch, advisor }) => {
  const { consumers } = consumersView;

  return {
    consumers,
    currentSearch,
    advisor
  };
};

export default connect(mapStateToProps)(ConsumerTable);
