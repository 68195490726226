/*
  Form validation rules for ant.design

  Top level objects are the forms. Nested objects are the field decorators.
 */

export default {
  login: {
    email: {
      validateTrigger: "onBlur",
      rules: [
        {
          required: true,
          message: "Enter your Nobul email",
          pattern: /^\w+\.?\w+?((@nobul.com)|(@nobul.com.partial))$/i,
          whitespace: true
        }
      ]
    },
    password: {
      rules: [
        {
          required: true,
          message: "Enter your Nobul password"
        }
      ]
    },
    remember: {
      valuePropName: "checked",
      initialValue: true
    }
  }
};
