import React from 'react';
import { Input } from 'antd';

const { Search } = Input;

const SearchAgentByName = (props) => {
  return (
    <Search style={{ width: 320, verticalAlign: 'middle' }} placeholder="enter agent's name" onSearch={value => console.log(value)} enterButton />
   );
}

export default SearchAgentByName;