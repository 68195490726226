import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Divider, Tag } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  getAgentProfile,
  updateBids,
  signalGetADPDispatched,
  signalGetADPSuccess,
  signalGetADPFail,
  updateSearchList
} from "core/actions";
import { nobul_getADP, nobul_agentBids, nobul_searchUsers } from "api";

const UsersListTable = ({ agents, dispatch }) => {
  let history = useHistory();
  let userTypePath = history.location.pathname.split("/")[1];

  // const routeBased = (route) => {

  // };

  const getProfileThenBids = record => {
    dispatch(signalGetADPDispatched());
    nobul_getADP(record.firebase_uid)
      .then(profile => {
        dispatch(getAgentProfile(profile));
        dispatch(signalGetADPSuccess());
      })
      .then(() => nobul_agentBids(record.user_key))
      .then(bids => dispatch(updateBids(bids)))
      .catch(() => {
        dispatch(signalGetADPFail());
        history.push("/" + userTypePath + "/");
        // alert('Fetching User Profile Failed');
      });
  };

  const getBidsThenProfile = record => {
    nobul_agentBids(record.user_key)
      .then(bids => {
        dispatch(updateBids(bids));
        dispatch(signalGetADPDispatched());
      })
      .then(() => nobul_getADP(record.firebase_uid))
      .then(profile => {
        dispatch(getAgentProfile(profile));
      })
      .then(() => dispatch(signalGetADPSuccess()))
      .catch(() => {
        dispatch(signalGetADPFail());
        history.push("/" + userTypePath + "/");
        // alert('Fetching User Profile Failed');
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Link
          key={record.firebase_uid}
          to={`/${userTypePath}/${index}/profile`}
          onClick={() => getProfileThenBids(record.firebase_uid)}
        >
          {record.user_name}
        </Link>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <a href={`mailto:${record.email}`}>{record.email}</a>
      )
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => (
        <a href={`tel:+${record.phone_number}`}>{record.phone_number}</a>
      )
    },
    {
      title: "Details",
      key: "details",
      render: (text, record, index) => (
        <span>
          <Link
            key="profile"
            to={`/${userTypePath}/${index}/profile`}
            onClick={() => getProfileThenBids(record)}
          >
            View Profile
          </Link>
          <Divider type="vertical" />
          <Link
            key="bids"
            to={`/${userTypePath}/${index}/bids`}
            onClick={() => {
              getBidsThenProfile(record);
            }}
          >
            View Bids
          </Link>
        </span>
      )
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: tags => (
        <span>
          {tags.map((tag, index) => {
            let color;
            if (tag === "agent") {
              color = "geekblue";
            }
            if (tag === "unverified") {
              color = "volcano";
            }
            if (tag === "premium") {
              color = "green";
            }
            return (
              <Tag key={index} color={color}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      )
    }
  ];

  const [loadingData, setloadingData] = useState(true);

  useEffect(() => {
    nobul_searchUsers({ userTypeId: 2 })
      .then(agents => {
        dispatch(updateSearchList(agents));
        setloadingData(false);
      })
      .catch(error => console.error("error in AgentTable", error));
  }, [dispatch]);

  return (
    <Table
      bordered
      loading={loadingData}
      columns={columns}
      dataSource={agents}
      rowKey={record => record.user_key}
    />
  );
};

const mapStateToProps = ({ agentsView }) => {
  const { agents, bids } = agentsView;
  return {
    agents,
    bids
  };
};

export default connect(mapStateToProps)(UsersListTable);
