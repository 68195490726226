import { message } from "antd";
import startCase from "lodash/startCase";

export const addCredentialsToBlacklist = (payload, token) =>
  fetch(process.env.REACT_APP_ADD_CREDENTIALS_TO_BLACKLIST, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())
    .catch((error) => {
      message.error(
        error.response.message ||
          `Failed to blacklist ${startCase(Object.keys(payload)[0])}`
      );

      console.error(error);
    });
