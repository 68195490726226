import React from "react";
import { Badge } from "antd";

export const RenderConnectionStatusBadge = (text) => {
  switch (text) {
    case "online":
      return <Badge status="success" />;
    case "suspended":
      return <Badge status="warning" />;
    case "deleted":
      return <Badge status="error" />;
    default:
      return <Badge status="default" />;
  }
};

export default RenderConnectionStatusBadge;
