import React, { useState, useEffect, Fragment } from "react";
import {
  Skeleton,
  Card,
  Row,
  Col,
  Avatar,
  Typography,
  Divider,
  Badge,
  Popover,
  Descriptions
} from "antd";
import { connect } from "react-redux";
import { startCase } from "lodash";
import ReactPlayer from "react-player";

import { nobul_userSettings } from "api";
import { updateAgentProfile } from "core/actions";
import {
  makeVideoStatusBadge,
  makeVerifyButton,
  formatPhoneNumber
} from "helpers";

import PopconfirmUpdater from "widgets/UpdateAgentStatus";
import PopconfirmVerifyVideo from "widgets/UpdateAgentStatus/PopconfirmVerifyVideo";
import GenerateBacklink from "widgets/GenerateBacklink/";

import { verifyRejectVideo } from "helpers";
import user from "assets/user.png";

const { Title } = Typography;

// Presentational component
// receives the active tab as tab, tablist as tabs, and tab content as content
const AgentProfile = ({ tabs, content, agent, dispatch, match, history }) => {
  const { tab, id } = match.params;

  const [loading, setLoading] = useState(id !== agent.agentFirebaseId);
  const [activeTab, setActiveTab] = useState(tab);
  const [verificationButtonText, setVerificationButtonText] = useState(" ");
  const [videoButton, setVideoButton] = useState(null);
  const [verifyReject, setVerifyReject] = useState("verified");
  const [showTooltip, setShowTooltip] = useState(false);
  const [admins, setAdmins] = useState();

  useEffect(() => {
    setActiveTab(tab);
  }, [tab, activeTab]);

  useEffect(() => {
    if (id === agent.agentFirebaseId) {
      setLoading(false);
    }
  }, [agent.agentFirebaseId, id]);

  useEffect(() => {
    const { verificationStatus, provider } = agent.videoInfo || {};
    const { agentId: userId } = agent || {};

    const toggledStatus =
      verificationStatus === "rejected"
        ? "verified"
        : verificationStatus === "verified"
        ? "rejected"
        : verificationStatus;

    if (agent.agentId) {
      setVerificationButtonText(
        agent.videoInfo.verificationStatus === "rejected"
          ? "Confirm Video Verification"
          : "Confirm Video Rejection"
      );

      if (agent.videoInfo.url) {
        setVideoButton(
          <Fragment>
            <Badge
              status={makeVideoStatusBadge(agent.videoInfo.verificationStatus)}
              text={
                agent.videoInfo.url
                  ? `${startCase(agent.videoInfo.provider)} video ${
                      agent.videoInfo.verificationStatus
                    }`
                  : null
              }
            />
            <Divider type="vertical" />
            <Popover
              placement="bottom"
              trigger="click"
              visible={showTooltip}
              onVisibleChange={setShowTooltip}
              destroyTooltipOnHide
              content={
                <div style={{ textAlign: "center" }}>
                  <ReactPlayer
                    height={405}
                    width={720}
                    url={agent.videoInfo.url}
                  />
                  <div className="video-link">
                    <strong>Video URL: </strong>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={agent.videoInfo.url}
                    >
                      {agent.videoInfo.url}
                    </a>
                  </div>
                  <PopconfirmUpdater
                    key={agent.videoInfo.url}
                    PopconfirmForm={PopconfirmVerifyVideo}
                    popconfirmFormProps={{ name: agent.firstName }}
                    onConfirm={verifyRejectVideo}
                    data={{
                      verificationStatus: toggledStatus,
                      userId,
                      setShowTooltip
                    }}
                  >
                    {makeVerifyButton(verificationStatus, "default", provider)}
                  </PopconfirmUpdater>
                </div>
              }
            >
              {makeVerifyButton(verificationStatus)}
            </Popover>
          </Fragment>
        );

        // status for the next state. if rejected needs verification and vice versa
        setVerifyReject(
          agent.videoInfo.verificationStatus === "verified"
            ? "rejected"
            : "verified"
        );
      } else {
        setVideoButton(null);
      }
    }
  }, [agent, verificationButtonText, verifyReject, dispatch, showTooltip]);

  useEffect(() => {
    if (agent.agentFirebaseId) {
      nobul_userSettings(agent.agentFirebaseId).then(
        ({ admins, verifiedPhone }) => {
          setAdmins(admins);
          dispatch(updateAgentProfile({ verifiedPhone }));
        }
      );
    }
  }, [agent.agentFirebaseId, dispatch]);

  return (
    <Card
      className="agent-profile"
      tabList={tabs}
      activeTabKey={activeTab}
      onTabChange={(key) => {
        history.push(key);
        setActiveTab(key);
      }}
      defaultChecked="profile"
    >
      {activeTab === "profile" ? (
        <Skeleton loading={loading} avatar active>
          <Title level={3}>
            <Avatar
              style={{ marginRight: 16 }}
              src={content.avatar || user}
              alt="Icons made by Smashicons"
            />
            {content.name} - {content.link}{" "}
            {videoButton && <Divider type="vertical" />}
            {videoButton}
            <Divider type="vertical" />
            <GenerateBacklink
              brokerage={agent.brokerage?.brokerageName}
              agentFirebaseId={agent.agentFirebaseId}
              agentName={agent.agentName}
              agentReviews={agent.agent_reviews}
            />
            <a
              style={{ float: "right" }}
              target="NobulFrame"
              href={`${process.env.REACT_APP_NOBUL_DOMAIN}/login?mirroring=${agent.agentFirebaseId}`}
            >
              <small>Impersonate</small>
            </a>
          </Title>
          <Divider />
          <Row>
            <Col span={4} className="photo-column">
              <img
                className="profile-photo"
                alt="agent profile"
                src={content.avatar}
              />
            </Col>
            <Col span={20} className="details-column">
              {content[activeTab]}
            </Col>
          </Row>
          <Row>
            {Boolean(admins) &&
              admins.map((adminObj, index) => (
                <div key={index}>
                  <Divider />
                  <Descriptions
                    title="Admininstrator Details"
                    layout="vertical"
                  >
                    {Object.keys(adminObj).map((key) => (
                      <Descriptions.Item key={key} label={startCase(key)}>
                        {key === `phone`
                          ? formatPhoneNumber(adminObj[key])
                          : key === `includeAllNotifications`
                          ? adminObj[key]
                            ? `Yes`
                            : `No`
                          : key === `email`
                          ? adminObj[key]
                          : startCase(adminObj[key])}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
              ))}
          </Row>
        </Skeleton>
      ) : (
        <Skeleton loading={loading} active>
          {content[activeTab]}
        </Skeleton>
      )}
    </Card>
  );
};

const mapStateToProps = ({ currentSearch }) => {
  return {
    currentSearch
  };
};

export default connect(mapStateToProps)(AgentProfile);
