import {
  SIGNAL_SEARCH,
  SIGNAL_SEARCH_SUCCESS,
  SIGNAL_GETADP_DISPATCHED,
  SIGNAL_GETADP_SUCCESSFUL,
  SIGNAL_GETADP_FAILED,
  SIGNAL_FETCHING_MESSAGES,
  SIGNAL_FETCHED_MESSAGES,
  SIGNAL_FETCHING_MESSAGES_FAILED,
  SIGNAL_SENDING_MESSAGE,
  SIGNAL_SENDING_MESSAGE_FAILED
} from "../actionTypes";

// SIGNAL (no payload actions)
export const signalSearch = () => {
  return {
    type: SIGNAL_SEARCH
  };
};
export const signalSearchSuccess = () => {
  return {
    type: SIGNAL_SEARCH_SUCCESS
  };
};
export const signalGetADPDispatched = () => {
  return {
    type: SIGNAL_GETADP_DISPATCHED
  };
};
export const signalGetADPSuccess = () => {
  return {
    type: SIGNAL_GETADP_SUCCESSFUL
  };
};
export const signalGetADPFail = () => {
  return {
    type: SIGNAL_GETADP_FAILED
  };
};

export const signalFetchingMessages = () => ({
  type: SIGNAL_FETCHING_MESSAGES
});
export const signalFetchedMessages = () => ({
  type: SIGNAL_FETCHED_MESSAGES
});
export const signalFetchingMessagesFaled = () => ({
  type: SIGNAL_FETCHING_MESSAGES_FAILED
});
export const signalSendingMessage = () => ({
  type: SIGNAL_SENDING_MESSAGE
});
export const signalSendingMessageFailed = () => ({
  type: SIGNAL_SENDING_MESSAGE_FAILED
});
