import { stitchParams } from "../helpers/stitchParams";
import { message } from "antd";

export const nobul_getADP = (firebaseID) => {
  return fetch(process.env.REACT_APP_GET_ADP, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    body: stitchParams({
      consumerFirebaseID: "csr",
      agentFirebaseID: firebaseID,
      agentSettings: true
    })
  })
    .then((response) => response.json())
    .then((profile) => {
      if (!profile || profile.error) {
        message.error("Cannot fetch agent data at this time");
        return {};
      }

      (profile.verificationStatus === "blocked" ||
        profile.agentStatus === "deleted") &&
        message.error("This user has been blocked.");

      return profile;
    })
    .catch((error) => console.error("nobul_getADP failed", error));
};
