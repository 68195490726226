import {
  UPDATE_ACTIVE_THREADS,
  UPDATE_MESSAGE_IN_THREAD,
  ADD_MESSAGE_THREAD
} from "../actionTypes";

export const updateActiveThreads = (threads) => ({
  type: UPDATE_ACTIVE_THREADS,
  payload: threads
});
export const updateMessageInThread = (message, threadId) => ({
  type: UPDATE_MESSAGE_IN_THREAD,
  payload: {
    message,
    threadId
  }
});
export const addMessageThread = (message, initThread) => ({
  type: ADD_MESSAGE_THREAD,
  payload: message,
  initThread
});
