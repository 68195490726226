export const nobul_agentBids = (userKey, details = false) => {
  return fetch(process.env.REACT_APP_AGENT_BIDS, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    body: `agentId=${userKey}`
  })
    .then((response) => response.json())
    .then(({ success, data }) => {
      if (!success) {
        return [];
      }

      let { bids } = data;

      if (!bids) {
        return [];
      }

      if (details) {
        return data;
      }

      return bids;
    })
    .catch((error) => console.error("nobul_agentBids failed", error));
};
