import React, { useEffect, useMemo } from "react";
import { Row, Col, Typography } from "antd";
import { connect } from "react-redux";

import {
  fetchUserListings,
  setConsumerListings,
  consumerListingsError
} from "core/actions";
import { userListings } from "api";

import ConsumerListings from "./ConsumerListings";

import "./styles.scss";

const { Title } = Typography;

const ConsumerFavouritesAndSimilar = ({
  consumerProfile,
  consumerListings,
  dispatch
}) => {
  const {
    currentFirebaseUid,
    favouriteListings,
    favouriteListingsPage,
    recentlyViewed,
    recentlyViewedPage
  } = consumerListings;
  const { firebaseUid } = consumerProfile;

  const [viewedCols, faveCols] = useMemo(() => {
    const _viewed = recentlyViewed.length * 4;
    const _faves = favouriteListings.length * 4;

    if (_viewed) {
      if (!_faves) {
        return [20, 4];
      }

      if (_viewed < 16 && !_faves) {
        return [12, 12];
      }

      return [24, 24];
    }

    if (_faves) {
      if (!_viewed) {
        return [4, 20];
      }

      if (_faves < 16 && !_viewed) {
        return [12, 12];
      }

      return [24, 24];
    }

    return [10, 14];
  }, [recentlyViewed, favouriteListings]);

  useEffect(() => {
    if (currentFirebaseUid !== firebaseUid) {
      dispatch(fetchUserListings());
      userListings({
        firebaseuid: firebaseUid,
        favorite: true,
        recentlyViewed: true
      })
        .then((listings) =>
          dispatch(setConsumerListings(listings, firebaseUid))
        )
        .catch(() => dispatch(consumerListingsError()));
    }
  }, [firebaseUid, currentFirebaseUid, dispatch]);

  return (
    <Row className="consumer-favourites-similar">
      <Col span={viewedCols}>
        <Title level={4}>Recently Viewed</Title>
        <ConsumerListings
          pageKey="recentlyViewedPage"
          page={recentlyViewedPage}
          listings={recentlyViewed}
          dispatch={dispatch}
        />
      </Col>
      <Col span={faveCols}>
        <div className="consumer-favourites-row">
          <Title level={4}>Favourites</Title>
          <ConsumerListings
            pageKey="favouriteListingsPage"
            page={favouriteListingsPage}
            listings={favouriteListings}
            dispatch={dispatch}
          />
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ consumerListings }) => ({
  consumerListings
});

export default connect(mapStateToProps)(ConsumerFavouritesAndSimilar);
