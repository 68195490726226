import { saveAs } from "file-saver";

import { stitchParams } from "helpers";
import { getJWTtoken } from "./getJWTtoken";

export const generateAgentReport = (payload, filename) =>
  getJWTtoken().then(({ token }) =>
    fetch(
      `${process.env.REACT_APP_GENERATE_AGENT_REPORT}?${stitchParams(payload)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/text",
          Authorization: `Bearer ${token}`,
          filename
        }
      }
    )
      .then((response) => {
        if (!response || !response.ok) {
          throw new Error("No agent data returned from generateAgentReport");
        }

        const filename = response.headers
          .get("content-disposition")
          .split("=")[1];

        return { blob: response.blob(), filename };
      })
      .then(({ blob, filename }) => blob.then((csv) => saveAs(csv, filename)))
      .catch((error) =>
        console.error("generateAgentReport failed to generate CSV", error)
      )
  );
