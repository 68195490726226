import React from "react";
import { Icon } from "antd";

const ToggleFavouriteProperty = ({
  isFavorite,
  genericText = false,
  firstName,
  onClick,
  className = ""
}) => {
  const favourite = isFavorite ? "Favourited" : "Favourite";
  const text = `${favourite} for ${firstName}`;
  return (
    <span key="favourite" onClick={onClick} className={className}>
      <Icon
        theme="twoTone"
        twoToneColor={isFavorite ? "#eb2f96" : "#a2a2a2"}
        type="heart"
      />{" "}
      {genericText ? favourite : text}
    </span>
  );
};

export default ToggleFavouriteProperty;
