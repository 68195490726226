import React, { useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { Skeleton } from "antd";

import Profile from "./Profile";
import Bids from "./Bids";
import Communications from "./Communications";
import Recommendations from "./Recommendations";

const tabs = {
  profile: Profile,
  bids: Bids,
  communications: Communications,
  recommendations: Recommendations
};

const TabSwitch = ({ tab, loading, fetchBidSessions, getBidSession }) => {
  const component = useCallback(
    (props) => {
      const Tab = tabs[tab];

      return (
        <Tab
          fetchBidSessions={fetchBidSessions}
          getBidSession={getBidSession}
          {...props}
        />
      );
    },
    [fetchBidSessions, getBidSession, tab]
  );

  return (
    <Skeleton loading={loading} avatar={tab === `profile`} active>
      <Switch>
        <Route
          path={`/:type?/:id?/${tab}`}
          component={component}
          fetchBidSessions={fetchBidSessions}
          getBidSession={getBidSession}
        />
      </Switch>
    </Skeleton>
  );
};

export default TabSwitch;
