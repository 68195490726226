export const nobul_userSettings = (firebaseID, isCSR = false) => {
  return fetch(process.env.REACT_APP_USER_SETTINGS, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    body: `firebaseuid=${firebaseID}&isCSR=${isCSR}`
  })
    .then((response) => response.json())
    .then((consumer) => {
      if (!consumer) {
        return {};
      }
      return consumer;
    })
    .catch((error) => console.error("nobul_userSearch failed", error));
};
