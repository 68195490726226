export const STATE_ABBREVIATIONS = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

export const STATE_CAPITALS = [
  { name: "Montgomery", stateAbbrev: "AL" },
  { name: "Juneau", stateAbbrev: "AK" },
  { name: "Phoenix", stateAbbrev: "AZ" },
  { name: "Little Rock", stateAbbrev: "AR" },
  { name: "Sacramento", stateAbbrev: "CA" },
  { name: "Denver", stateAbbrev: "CO" },
  { name: "Hartford", stateAbbrev: "CT" },
  { name: "Dover", stateAbbrev: "DE" },
  { name: "Tallahassee", stateAbbrev: "FL" },
  { name: "Atlanta", stateAbbrev: "GA" },
  { name: "Honolulu", stateAbbrev: "HI" },
  { name: "Boise", stateAbbrev: "ID" },
  { name: "Springfield", stateAbbrev: "IL" },
  { name: "Indianapolis", stateAbbrev: "IN" },
  { name: "Des Moines", stateAbbrev: "IA" },
  { name: "Topeka", stateAbbrev: "KS" },
  { name: "Frankfort", stateAbbrev: "KY" },
  { name: "Baton Rouge", stateAbbrev: "LA" },
  { name: "Augusta", stateAbbrev: "ME" },
  { name: "Annapolis", stateAbbrev: "MD" },
  { name: "Boston", stateAbbrev: "MA" },
  { name: "Lansing", stateAbbrev: "MI" },
  { name: "Saint Paul", stateAbbrev: "MN" },
  { name: "Jackson", stateAbbrev: "MS" },
  { name: "Jefferson City", stateAbbrev: "MO" },
  { name: "Helena", stateAbbrev: "MT" },
  { name: "Lincoln", stateAbbrev: "NE" },
  { name: "Carson City", stateAbbrev: "NV" },
  { name: "Concord", stateAbbrev: "NH" },
  { name: "Trenton", stateAbbrev: "NJ" },
  { name: "Santa Fe", stateAbbrev: "NM" },
  { name: "Albany", stateAbbrev: "NY" },
  { name: "Raleigh", stateAbbrev: "NC" },
  { name: "Bismarck", stateAbbrev: "ND" },
  { name: "Columbus", stateAbbrev: "OH" },
  { name: "Oklahoma City", stateAbbrev: "OK" },
  { name: "Salem", stateAbbrev: "OR" },
  { name: "Harrisburg", stateAbbrev: "PA" },
  { name: "Providence", stateAbbrev: "RI" },
  { name: "Columbia", stateAbbrev: "SC" },
  { name: "Pierre", stateAbbrev: "SD" },
  { name: "Nashville", stateAbbrev: "TN" },
  { name: "Austin", stateAbbrev: "TX" },
  { name: "Salt Lake City", stateAbbrev: "UT" },
  { name: "Montpelier", stateAbbrev: "VT" },
  { name: "Richmond", stateAbbrev: "VA" },
  { name: "Olympia", stateAbbrev: "WA" },
  { name: "Charleston", stateAbbrev: "WV" },
  { name: "Madison", stateAbbrev: "WI" },
  { name: "Cheyenne", stateAbbrev: "WY" }
];

export const STATES = {
  AZ: "Arizona",
  AL: "Alabama",
  AK: "Alaska",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};
