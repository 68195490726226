// Typography
const header1_30 = {
  fontSize: "3rem",
  lineHeight: "3.8rem",
  fontWeight: 800
};

const header2_26 = {
  fontSize: "2.6rem",
  lineHeight: "3.3rem",
  fontWeight: 800
};

const header3_22 = {
  fontSize: "2.2rem",
  lineHeight: "2.8rem",
  fontWeight: 800
};

const header4_18 = {
  fontSize: "1.8rem",
  lineHeight: "2.3rem",
  fontWeight: 800
};

const paragraph16_normal = {
  fontSize: "1.6rem",
  lineHeight: "2rem",
  fontWeight: "normal"
};

const paragraph16_bold = {
  ...paragraph16_normal,
  fontWeight: 800
};

const smallText14_normal = {
  fontSize: "1.4rem",
  lineHeight: "1.8rem",
  fontWeight: "normal"
};

const smallText14_bold = {
  ...smallText14_normal,
  fontWeight: 800
};

const legalText12_normal = {
  fontSize: "1.2rem",
  lineHeight: "1.5rem",
  fontWeight: "normal"
};

const body16_normal = {
  fontSize: "1.6rem",
  lineHeight: "2.6rem",
  fontWeight: "normal"
};

export {
  header1_30,
  header2_26,
  header3_22,
  header4_18,
  paragraph16_normal,
  paragraph16_bold,
  smallText14_normal,
  smallText14_bold,
  legalText12_normal,
  body16_normal
};
