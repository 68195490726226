import React, { useState, useMemo, useCallback } from "react";
import { Popover, Icon, Timeline, Typography } from "antd";
import dayjs from "dayjs";

import { getAgentNotes } from "api";

import Communications from "components/Communications";

export const AgentNotesPopover = ({ contactId, agentId, contactFirstName }) => {
  const [notes, setNotes] = useState(
    <Timeline>
      <Timeline.Item dot={<Icon type="loading" />}>
        Fetching notes...
      </Timeline.Item>
    </Timeline>
  );

  const onVisibleChange = useCallback(
    (visible) =>
      visible &&
      getAgentNotes(contactId, agentId).then((notes) => {
        const timelineNotes = notes.length ? (
          notes.map((note) => (
            <Timeline.Item style={{ padding: "0 0 12px" }}>
              {note.note}
              <Typography.Paragraph type="secondary">
                <small>
                  {dayjs(note.timestamp).format("MMM D, YYYY (h:mma)")}
                </small>
              </Typography.Paragraph>
            </Timeline.Item>
          ))
        ) : (
          <Timeline.Item color="gray">No notes found</Timeline.Item>
        );

        setNotes(<Timeline>{timelineNotes}</Timeline>);
      }),
    [agentId, contactId]
  );

  const style = useMemo(() => ({ maxWidth: 420, overflowY: `auto` }), []);

  return (
    <Popover
      onVisibleChange={onVisibleChange}
      title={`Notes for ${contactFirstName}`}
      content={notes}
      placement="left"
      agentId={agentId}
      contactId={contactId}
      style={style}
    >
      <Icon type="audit" />
    </Popover>
  );
};

export const AgentCommunications = ({ match, history, agentId }) => {
  const render = useCallback(
    (contactId, record) => (
      <AgentNotesPopover
        agentId={agentId}
        contactId={contactId}
        contactFirstName={record.contactFirstName}
      />
    ),
    [agentId]
  );

  const notesColumn = useMemo(
    () => [
      {
        title: "Notes",
        dataIndex: "contactId",
        render
      }
    ],
    [render]
  );

  return (
    <Communications
      history={history}
      match={match}
      extraColumns={notesColumn}
    />
  );
};

export default AgentCommunications;
