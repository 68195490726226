import { SET_TOKEN } from "../actionTypes";

const jwtReducer = (state = { token: "", expiresAt: "" }, action) => {
  const { type, token, expiresAt } = action;

  switch (type) {
    case SET_TOKEN:
      return {
        token,
        expiresAt
      };

    default:
      return state;
  }
};

export default jwtReducer;
