import { stitchParams } from "../helpers";

export const userListings = (payload) =>
  fetch(`${process.env.REACT_APP_GET_USER_LISTINGS}?${stitchParams(payload)}`)
    .then((response) => response.json())
    .then((listings) => {
      if (!listings || typeof listings !== "object") {
        return {
          favouriteListings: [],
          recentlyViewed: [],
          watchlist: []
        };
      }

      // normalize nulls into arrays
      Object.keys(listings).forEach((key) => {
        if (!listings[key]) {
          listings[key] = [];
        }
      });

      return listings;
    })
    .catch((error) => {
      console.error("userListings errored out", error);

      return {
        favouriteListings: [],
        recentlyViewed: [],
        watchlist: []
      };
    });
