import React, { useCallback } from "react";
import { Select, Divider, List, Avatar } from "antd";
import { upperCase } from "lodash";

import nobulLogo from "assets/nobul-logo.svg";

const { Option } = Select;

const statusMap = [
  {
    message: (name) => (
      <p>
        Verify, reject, or unverify <strong>{name}'s</strong> review
      </p>
    ),
    label: "select status here",
    value: "pending"
  },
  {
    message: (name) => (
      <p>
        You are verifying a review by <strong>{name}</strong>
      </p>
    ),
    label: "verify",
    value: "verify"
  },
  {
    message: (name) => (
      <p>
        You are rejecting a review by <strong>{name}</strong>
      </p>
    ),
    label: "reject",
    value: "reject"
  },
  {
    message: (name) => (
      <p>
        You are unverifying a review by <strong>{name}</strong>
      </p>
    ),
    label: "unverify",
    value: "unverify"
  }
];

const PopconfirmVerifyReview = ({ name, advisor, data, setData }) => {
  const { firstName, lastName, userType } = advisor;
  const { isVerified, reviewerName } = data;

  const captureData = useCallback(
    (input) => {
      setData((state) => ({ ...state, isVerified: input }));
    },
    [setData]
  );

  const { message, value } =
    statusMap.find(({ value }) => value === isVerified) || statusMap[0];

  return (
    <div>
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={nobulLogo} />}
            title={`${firstName} ${lastName}`}
            description={`Nobul ${userType} verifying ${name}'s reviews`}
          />
        </List.Item>
      </List>
      <Divider style={{ margin: "4px 0 12px 0" }} />
      {message(reviewerName)}
      <Select
        style={{ display: "block", marginTop: 4 }}
        defaultActiveFirstOption
        defaultValue={value}
        onSelect={captureData}
      >
        {statusMap.map((status, index) => (
          <Option
            key={status.value}
            value={status.value}
            disabled={index === 0}
          >
            {upperCase(status.label)}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default PopconfirmVerifyReview;
