import React from "react";
import AgentMatchesTables from "./AgentMatchesTables";

const AgentMatchesNoMatchesRow = ({ nonMatches, userId }) => (
  <AgentMatchesTables
    data={nonMatches}
    userId={userId}
    tableName="non_matches"
    title="Non-matches"
  />
);

export default AgentMatchesNoMatchesRow;
