import React, { useCallback } from "react";
import { Select, Divider, List, Avatar, Input } from "antd";
import { upperCase, debounce } from "lodash";

import nobulLogo from "assets/nobul-logo.svg";
// import { advisorAuthStatePending } from "core/actions";

const { Option } = Select;

const debounceInput = debounce(
  (value, setData) => {
    setData((state) => ({
      ...state,
      statusReason: value
    }));
  },
  222,
  { trailing: true, leading: false }
);

const statusMap = [
  {
    message: () => <span>Select a status from the dropdown</span>,
    status: "Select a status"
  },
  {
    message: (name) => (
      <span>
        Are you sure you want to <strong>unverify</strong> {name}?
      </span>
    ),
    status: "unverified"
  },
  {
    message: (name) => (
      <span>
        Are you sure you want to <strong>verify</strong> {name}?
      </span>
    ),
    status: "verified"
  },
  {
    message: (name) => (
      <span>
        Are you sure you want set {name} to{" "}
        <strong>pending verification</strong>?
      </span>
    ),
    status: "pending"
  },
  {
    message: (name) => (
      <span>
        Are you sure you want to <strong>suspend</strong> {name}?
      </span>
    ),
    status: "suspended"
  },
  {
    message: (name) => (
      <span>
        Are you sure you want to set {name} to <strong>away</strong> (vacation)?
      </span>
    ),
    status: "away"
  }
];

const PopconfirmStatusForm = ({ name, advisor, data, setData }) => {
  const { firstName, lastName, userType } = advisor;
  const { agentStatusId } = data;
  const captureData = useCallback(
    (input) => {
      if (typeof input === "number") {
        setData((state) => ({ ...state, agentStatusId: input }));
      } else {
        const { value } = input.target;
        debounceInput(value, setData);
      }
    },
    [setData]
  );

  return (
    <div>
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={nobulLogo} />}
            title={`${firstName} ${lastName}`}
            description={`Nobul ${userType} updating agent ${name}`}
          />
        </List.Item>
      </List>
      <Divider style={{ margin: "4px 0 12px 0" }} />
      {statusMap[agentStatusId].message(name)}
      <Select
        style={{ display: "block", marginTop: 4 }}
        defaultActiveFirstOption
        defaultValue={agentStatusId}
        onSelect={captureData}
      >
        {statusMap.map((status, index) => (
          <Option key={status.status} value={index} disabled={index === 0}>
            {upperCase(status.status)}
          </Option>
        ))}
      </Select>
      <Divider style={{ margin: "24px 0 12px 0" }} />
      <span>Reason for status change</span>
      <Input
        style={{ marginTop: 4 }}
        className={
          data.agentStatusId === 4 && !data.statusReason.length
            ? "red-border"
            : ""
        }
        placeholder="mandatory for suspended"
        defaultValue={undefined}
        onChange={captureData}
      />
    </div>
  );
};

export default PopconfirmStatusForm;
