import React, { useCallback } from "react";
import {
  List,
  Avatar,
  Button,
  Skeleton,
  Descriptions,
  Result,
  Typography
} from "antd";
import dayjs from "dayjs";

import { processAgentTransactions } from "api";
import { updateAgentTransactions } from "core/actions";
import "./AgentTransactions.scss";

import Buy from "assets/icons/buy-thumb.jpg";
import Sell from "assets/icons/sell-thumb.jpg";
import Both from "assets/icons/buysell-thumb.jpg";

const avatars = {
  Both,
  Buy,
  Sell
};

// const transactionTexts = {
//   Both: "buyer and seller",
//   Buy: "buyer",
//   Sell: "seller"
// };

const transactionVerbs = {
  Both: "Bought and sold",
  Buy: "Bought",
  Sell: "Sold"
};

const { Title } = Typography;

const AgentTransactionsTab = ({
  dispatch,
  agent: { transactions, agentId, agentName },
  advisor: { email: verifiedByEmail }
}) => {
  const processTransactionsCb = useCallback(
    (action, transactionIds) =>
      processAgentTransactions({
        agentId,
        action,
        verifiedByEmail,
        transactionIds
      }),
    [agentId, verifiedByEmail]
  );

  return (
    <div className="transaction-history-tab">
      <Title level={3}>{agentName}'s Transactions</Title>
      <Skeleton active loading={!transactions}>
        {Boolean(transactions?.pending?.length) ? (
          <List
            bordered
            header={
              <div>
                <span>Pending Transactions</span>
                <Button
                  type="link"
                  size="small"
                  style={{ marginLeft: 12, fontWeight: "inherit" }}
                  onClick={() => {
                    const action = "verify";
                    const transactionIds = transactions.pending.map(
                      (transaction) => transaction.transactionId
                    );

                    processTransactionsCb(action, transactionIds).then((data) =>
                      dispatch(updateAgentTransactions(data))
                    );
                  }}
                >
                  Verify All
                </Button>
              </div>
            }
            itemLayout="horizontal"
            dataSource={transactions?.pending || []}
            pagination={{
              pageSize: 5,
              defaultPageSize: 5,
              hideOnSinglePage: true
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      const action = "verify";
                      const transactionIds = [item.transactionId];

                      processTransactionsCb(action, transactionIds).then(
                        (data) => dispatch(updateAgentTransactions(data))
                      );
                    }}
                  >
                    Verify
                  </Button>,
                  <Button
                    size="small"
                    type="danger"
                    ghost
                    onClick={() => {
                      const action = "reject";
                      const transactionIds = [item.transactionId];

                      processTransactionsCb(action, transactionIds).then(
                        (data) => dispatch(updateAgentTransactions(data))
                      );
                    }}
                  >
                    Reject
                  </Button>
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={avatars[item.transactionType]} />}
                />
                <Descriptions size="small">
                  {Boolean(item.address) && (
                    <Descriptions.Item label="Address">
                      {item.address}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.city) && (
                    <Descriptions.Item label="City">
                      {item.city}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.mlsNumber) && (
                    <Descriptions.Item label="MLS">
                      {item.mlsNumber}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.listTime) && (
                    <Descriptions.Item label="Listed On">
                      {dayjs(item.listTime).format("MMM D, YYYY h:mma")}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.saleDate) && (
                    <Descriptions.Item label="Sold On">
                      {dayjs(item.saleDate).format("MMM D, YYYY")}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.salePrice) && (
                    <Descriptions.Item label="Sale Price">
                      {item.salePrice.toLocaleString("en-us", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                      })}{" "}
                      ({item.percent}%)
                    </Descriptions.Item>
                  )}
                  {Boolean(item.transactionType) && (
                    <Descriptions.Item label="Transaction">
                      {item.transactionType}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.propertyType) && (
                    <Descriptions.Item label="Property">
                      {item.propertyType}
                    </Descriptions.Item>
                  )}
                  {Boolean(item.daysOnMarket) && (
                    <Descriptions.Item label="Days on Market">
                      {item.daysOnMarket}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </List.Item>
            )}
          />
        ) : null}

        {Boolean(transactions?.rejected?.length) ? (
          <List
            bordered
            header="Rejected Transactions"
            itemLayout="horizontal"
            dataSource={transactions?.rejected || []}
            pagination={{
              pageSize: 5,
              defaultPageSize: 5,
              hideOnSinglePage: true
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      const action = "verify";
                      const transactionIds = [item.transactionId];

                      processTransactionsCb(action, transactionIds).then(
                        (data) => dispatch(updateAgentTransactions(data))
                      );
                    }}
                  >
                    Verify
                  </Button>
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={avatars[item.transactionType]} />}
                  title={
                    <div>
                      <strong>{transactionVerbs[item.transactionType]}</strong>{" "}
                      a <strong>{item.propertyType}</strong> on{" "}
                      <strong>{item.address}</strong>,{" "}
                      <strong>{item.city}</strong> for{" "}
                      <strong>
                        {item.salePrice
                          ? item.salePrice.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0
                            })
                          : "Sale price N/A"}{" "}
                        {item.percent ? `(${item.percent}%)` : null}
                      </strong>{" "}
                    </div>
                  }
                  description={
                    <div>
                      <span>MLS: </span> <strong>{item.mlsNumber}</strong>
                      &nbsp;|&nbsp;
                      <span>
                        Submitted on{" "}
                        <strong>
                          {dayjs(item.submittedDate).format("ddd, MMM D, YYYY")}
                        </strong>
                      </span>
                      &nbsp;|&nbsp;
                      <span>
                        Listed on{" "}
                        <strong>
                          {dayjs(item.listTime).format(
                            "ddd, MMM D, YYYY h:mma"
                          )}
                        </strong>
                      </span>
                      &nbsp;|&nbsp;
                      <span>
                        Sold on{" "}
                        <strong>
                          {dayjs(item.saleDate).format("ddd, MMM D, YYYY")}
                        </strong>
                      </span>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        ) : null}

        {transactions?.list?.length ? (
          <List
            bordered
            header="Verified Transactions"
            loading={!transactions}
            itemLayout="horizontal"
            dataSource={transactions?.list || []}
            pagination={{
              pageSize: 5,
              defaultPageSize: 5,
              hideOnSinglePage: true
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    size="small"
                    type="danger"
                    ghost
                    onClick={() => {
                      const action = "reject";
                      const transactionIds = [item.transactionId];

                      processTransactionsCb(action, transactionIds).then(
                        (data) => dispatch(updateAgentTransactions(data))
                      );
                    }}
                  >
                    Reject
                  </Button>
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={avatars[item.transactionType]} />}
                  title={
                    <div>
                      <strong>{transactionVerbs[item.transactionType]}</strong>{" "}
                      a <strong>{item.propertyType}</strong> on{" "}
                      <strong>{item.address}</strong>,{" "}
                      <strong>{item.city}</strong> for{" "}
                      <strong>
                        {item.salePrice
                          ? item.salePrice.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0
                            })
                          : "Sale price N/A"}{" "}
                        {item.percent ? `(${item.percent}%)` : null}
                      </strong>{" "}
                    </div>
                  }
                  description={
                    <div>
                      <span>MLS: </span> <strong>{item.mlsNumber}</strong>
                      &nbsp;|&nbsp;
                      <span>
                        Submitted on{" "}
                        <strong>
                          {dayjs(item.submittedDate).format("ddd, MMM D, YYYY")}
                        </strong>
                      </span>
                      &nbsp;|&nbsp;
                      <span>
                        Listed on{" "}
                        <strong>
                          {dayjs(item.listTime).format(
                            "ddd, MMM D, YYYY h:mma"
                          )}
                        </strong>
                      </span>
                      &nbsp;|&nbsp;
                      <span>
                        Sold on{" "}
                        <strong>
                          {dayjs(item.saleDate).format("ddd, MMM D, YYYY")}
                        </strong>
                      </span>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Result title="No Transactions Available" />
        )}
      </Skeleton>
    </div>
  );
};

export default AgentTransactionsTab;
