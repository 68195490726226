import React, { useState } from "react";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";

// for ant.design forms
import decorators from "helpers/field-decorators";
import { signInFirebase } from "auth/users";
import { advisorSigninFailedSignal } from "core/actions";

const { login } = decorators;

const LoginForm = ({
  dispatch,
  form: { getFieldDecorator, validateFields }
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        validateFields((error, values) => {
          if (!error) {
            setLoading(true);

            const { email, password, remember } = values;

            signInFirebase(email, password, remember)
              .then((uid) => {
                if (!uid) {
                  message.error(
                    "Please double check the email or password, and try again.",
                    4
                  );
                  dispatch(advisorSigninFailedSignal());
                }
              })
              .catch((error) => {
                console.error("useFirebase signIn failed in LognForm ", error);
                message.error(
                  "Please double check the email or password and try again.",
                  4
                );
                setLoading(false);
                dispatch(advisorSigninFailedSignal());
              });
          } else {
            setLoading(false);
          }
        });
      }}
      className="login-form"
    >
      <Form.Item>
        {getFieldDecorator(
          "email",
          login.email
        )(
          <Input
            autoComplete="email"
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="email"
            placeholder="Your Nobul email"
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(
          "password",
          login.password
        )(
          <Input
            autoComplete="current-password"
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Your Nobul password"
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(
          "remember",
          login.remember
        )(<Checkbox>Keep me signed in</Checkbox>)}
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          className="login-form-submit"
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

const WiredLoginForm = Form.create({ name: "login" })(LoginForm);
export default WiredLoginForm;
