import {
  UPDATE_AGENT_LIST,
  UPDATE_BIDS,
  GET_AGENT_PROFILE,
  GET_BID,
  GET_MORE_AGENTS,
  UPDATE_PROFILE_VIDEO_STATUS,
  UPDATE_AGENT_STATUS_SEARCH,
  UPDATE_AGENT_STATUS_PROFILE,
  UPDATE_AGENT_REVIEW,
  UPDATE_PROFILE_VIDEO_STATUS_SEARCH,
  UPDATE_AGENT_TRANSACTIONS,
  UPDATE_USER_TAGS,
  UPDATE_AGENT_PROFILE
} from "../actionTypes";

import { AGENT_STATUSES } from "../../constants";

const defaultAgentsView = {
  agents: [],
  agentProfile: {},
  bids: [],
  viewingBid: {}
};

const agentsReducer = (agentsView = defaultAgentsView, action) => {
  const { agents, agentProfile } = agentsView;
  switch (action.type) {
    case UPDATE_AGENT_LIST:
      return {
        ...agentsView,
        agents: [...action.agents]
      };
    case GET_MORE_AGENTS:
      return {
        ...agentsView,
        agents: agents.concat(action.agents)
      };
    case GET_AGENT_PROFILE:
      return {
        ...agentsView,
        agentProfile: {
          ...action.profile,
          agent_reviews: {
            reviews:
              action.profile.agent_reviews.reviews?.map((review) => ({
                ...review,
                message: review.review,
                rate: review.starRating,
                author: review.reviewerName
              })) || []
          }
        }
      };
    case UPDATE_BIDS:
      return {
        ...agentsView,
        bids: [...action.bids]
      };
    case GET_BID:
      return {
        ...agentsView,
        ...agentsView.viewingBid,
        viewingBid: agentsView.bids[action.index] || {}
      };
    case UPDATE_AGENT_STATUS_SEARCH:
      const { user_key, status } = action;

      return {
        ...agentsView,
        agents: agents.map((agent) => {
          if (agent.user_key === user_key) {
            const tags = agent.tags.map((tag) => {
              if (!~AGENT_STATUSES.indexOf(tag)) {
                return tag;
              }
              return status;
            });
            return { ...agent, tags };
          }
          return agent;
        })
      };
    case UPDATE_AGENT_STATUS_PROFILE:
      return {
        ...agentsView,
        agentProfile: {
          ...agentsView.agentProfile,
          agentStatus: action.status
        }
      };
    case UPDATE_PROFILE_VIDEO_STATUS_SEARCH:
      return {
        ...agentsView,
        agents: agents.map((agent) => {
          if (agent.user_key === action.userId) {
            return {
              ...agent,
              profileCompletionScore:
                action.profileCompletionScore || agent.profileCompletionScore,
              video_data: agent.video_data.map((video) => ({
                ...video,
                verificationStatus: action.status
              }))
            };
          }
          return agent;
        })
      };
    case UPDATE_PROFILE_VIDEO_STATUS:
      return {
        ...agentsView,
        agentProfile: {
          ...agentsView.agentProfile,
          videoInfo: {
            ...agentsView.agentProfile.videoInfo,
            verificationStatus: action.status
          }
        }
      };

    case UPDATE_AGENT_REVIEW:
      const _nobulReviews = action.reviews.filter(
        (review) => review.platform === `nobul`
      );

      return {
        ...agentsView,
        agentProfile: {
          ...agentsView.agentProfile,
          agent_reviews: {
            ...agentsView.agentProfile.agent_reviews,
            reviews: _nobulReviews,
            total_reviews: _nobulReviews.length
          }
        }
      };
    case UPDATE_AGENT_TRANSACTIONS:
      return {
        ...agentsView,
        agentProfile: {
          ...agentsView.agentProfile,
          transactions: {
            ...agentsView.agentProfile.transactions,
            ...action.transactions
          }
        }
      };
    case UPDATE_USER_TAGS:
      return {
        ...agentsView,
        agents: agents.map((agent) => {
          const tagsArr = [].concat(action.tagData.tags);
          const existingTags = tagsArr.filter(
            (tag) => !~agent.tags.indexOf(tag)
          );

          const tags =
            action.tagData.tagAction === `pop`
              ? agent.tags.filter((tag) => !~tagsArr.indexOf(tag))
              : agent.tags.concat(existingTags);

          return agent.firebase_uid === action.firebase_uid
            ? {
                ...agent,
                tags
              }
            : agent;
        })
      };
    case UPDATE_AGENT_PROFILE:
      return {
        ...agentsView,
        agentProfile: {
          ...agentProfile,
          ...action.payload
        }
      };
    default:
      return agentsView;
  }
};

export default agentsReducer;
