import React from 'react';

const AgentSearchContainer = ({ SearchBar }) => {
  return (
    <div style={{ margin: 16 }}>
      <SearchBar />
    </div>
  );
}

export default AgentSearchContainer;