import { dashboardStore } from "../core";
import { setJWTtoken } from "../core/actions";

export const getJWTtoken = () => {
  const { dispatch, getState } = dashboardStore;

  const {
    jwt: { token, expiresAt },
    advisor: { firebaseUid }
  } = getState();

  if (expiresAt && Date.now() - new Date(expiresAt).getTime() < 36e5) {
    return Promise.resolve({
      token,
      expiresAt
    });
  }

  return fetch(process.env.REACT_APP_GET_JWT_TOKEN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ firebaseId: firebaseUid })
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || !data.token) {
        throw new Error("failed to generate JWT token");
      }

      const { token, expiresAt } = data;

      dispatch(setJWTtoken(token, expiresAt));

      return { token, expiresAt };
    })
    .catch((error) =>
      console.error("getJWTtoken didn't return a token", error)
    );
};
