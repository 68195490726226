import React from "react";
import { Tag, Popover, Typography, Badge } from "antd";
import startCase from "lodash/startCase";
import startsWith from "lodash/startsWith";

import { formatPhoneNumber } from "helpers";

export const renderPhoneColumn = (text, record) => {
  const phoneText = text ? (
    <a href={`tel:+${text}`}>{formatPhoneNumber(text)}</a>
  ) : (
    <Typography.Text type="danger">Phone #</Typography.Text>
  );

  const typeTag = (
    <Tag
      style={{ marginRight: 0 }}
      color={
        !text
          ? "red"
          : startsWith(record.phone_status, `otp`)
          ? `blue`
          : undefined
      }
    >
      {startCase(record.phone_status)}
    </Tag>
  );

  if (record.carrierData.countryCode !== `US`) {
    return (
      <span>
        {phoneText}
        <br />
        {typeTag}
      </span>
    );
  }

  return (
    <span>
      {phoneText}
      <br />
      <Popover
        title={`Carrier Info (${record.carrierData.countryCode})`}
        content={
          <div className="carrier-data-wrapper">
            <Typography.Paragraph>
              <Typography.Text strong>Carrier: </Typography.Text>
              {record.carrierData.carrierName}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text strong>Type: </Typography.Text>
              {record.carrierData.phoneNumberType}
            </Typography.Paragraph>
          </div>
        }
      >
        <Badge dot={true} color="geekblue">
          {typeTag}
        </Badge>
      </Popover>
    </span>
  );
};
