import React, { useMemo } from "react";
import { Marker, TileLayer } from "react-leaflet";
import { divIcon } from "leaflet";

import { mergeStreetAndUnit } from "helpers";

const LeafletMap = ({ properties }) => {
  const markers = useMemo(
    () =>
      properties
        ? properties.map(({ listingId, lat, lon, address, address_number }) => (
            <Marker
              icon={divIcon({
                html: "<div class='leaflet-pin'></div>"
              })}
              riseOnHover
              title={mergeStreetAndUnit(address, address_number)}
              bubblingMouseEvents={false}
              eventHandlers={{
                click: () => {
                  window.open(
                    `https://www.nobul.com/en/home-details/${listingId}`,
                    "RecommendedPropertyTab"
                  );
                }
              }}
              key={listingId}
              position={[lat, lon]}
            />
          ))
        : null,
    [properties]
  );

  return (
    <>
      <TileLayer
        url={process.env.REACT_APP_MAP_TILE_LAYER}
        attribution={
          '<a href="https://www.mapbox.com/about/maps/">&copy;Mapbox</a> <a href="http://www.openstreetmap.org/about/">&copy;OpenStreetMap</a>'
        }
      />
      {markers}
    </>
  );
};

export default LeafletMap;
