import { message } from "antd";
import { startCase } from "lodash";

import { verifyAgentVideo } from "api";
import {
  updateProfileVideoStatus,
  updateProfileVideoStatusSearch
} from "core/actions";

export const verifyRejectVideo = (e, data, advisor, dispatch) => {
  const { email: verifiedByEmail } = advisor;
  const { verificationStatus, userId, setShowTooltip } = data;

  let verifyError =
    verificationStatus !== "verified"
      ? "Video verification failed"
      : "Video rejection failed";

  let loadingMessage =
    verificationStatus === "verified" ? "Verifying Video" : "Rejecting Video";

  // loading indicator before success or error resolves
  message.loading({
    content: loadingMessage,
    duration: 0
  });

  const payload = {
    verifiedByEmail,
    verificationStatus,
    userId
  };

  verifyAgentVideo(payload)
    .then((response) => {
      // if the function failed, expect an empty string
      if (response) {
        const { msg, profileCompletionScore } = response;
        const statusMsg = msg.match(/^video \w+/)[0];
        message.destroy();
        setShowTooltip((state) => !state);
        message.success(startCase(statusMsg, 2.2));
        if (window.location.pathname === "/agents") {
          dispatch(
            updateProfileVideoStatusSearch(
              verificationStatus,
              profileCompletionScore,
              userId
            )
          );
        } else {
          dispatch(updateProfileVideoStatus(verificationStatus));
          dispatch(
            updateProfileVideoStatusSearch(
              verificationStatus,
              profileCompletionScore,
              userId
            )
          );
        }
      } else {
        message.error(verifyError);
      }
    })
    .catch((error) => {
      message.destroy();
      message.error(verifyError, 2.2);
      console.error("agentVideoVerification failed ", error);
    });
};
