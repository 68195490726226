import {
  CONSUMER_LISTINGS_ERROR,
  FETCH_CONSUMER_LISTINGS,
  SET_CONSUMER_LISTINGS,
  SET_CONSUMER_LISTINGS_PAGE
} from "../actionTypes";

const defaultState = {
  currentFirebaseUid: "",
  fetching: false,
  favouriteListingsPage: 1,
  similar_listings_page: 1,
  recentlyViewedPage: 1,
  watchlistPage: 1,
  favouriteListings: [],
  watchlist: [],
  similar_listings: [],
  recentlyViewed: []
};

const consumerListingsReducer = (state = defaultState, action) => {
  const { currentFirebaseUid, type, listings, page, pageKey } = action;

  switch (type) {
    case FETCH_CONSUMER_LISTINGS:
      return {
        ...state,
        fetching: true
      };

    case SET_CONSUMER_LISTINGS:
      return {
        ...state,
        ...listings,
        currentFirebaseUid,
        favouriteListingsPage: 1,
        similar_listings_page: 1,
        recentlyViewedPage: 1,
        watchlistPage: 1,
        fetching: false
      };

    case CONSUMER_LISTINGS_ERROR:
      return {
        ...state,
        favouriteListingsPage: 1,
        similar_listings_page: 1,
        recentlyViewedPage: 1,
        watchlistPage: 1,
        fetching: false
      };

    case SET_CONSUMER_LISTINGS_PAGE:
      return {
        ...state,
        [pageKey]: page
      };

    default:
      return state;
  }
};

export default consumerListingsReducer;
