import React, { useMemo } from "react";
import { Empty } from "antd";
import { MapContainer } from "react-leaflet";

import LeafletMap from "./LeafletMap";

import { getLatsLons } from "./helpers";

import "./styles.scss";

const LeafletMapContainer = ({ properties }) => {
  const bounds = useMemo(() => getLatsLons(properties), [properties]);

  return bounds ? (
    <MapContainer
      id="recommended-properties-map"
      bounds={bounds}
      boundsOptions={{
        padding: [12, 12]
      }}
      style={{ height: "100%", minHeight: 362 }}
    >
      <LeafletMap properties={properties} />
    </MapContainer>
  ) : (
    <Empty
      style={{ margin: "48px ​auto" }}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  );
};

export default LeafletMapContainer;
