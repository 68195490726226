import React from "react";
import { connect } from "react-redux";
import { BidsTable, AgentReviews } from "components/DashTables";
import { AgentProfile } from "./";
import { Descriptions, Typography, Divider, Tag } from "antd";
import upperCase from "lodash/upperCase";
import startCase from "lodash/startCase";
import kebabCase from "lodash/kebabCase";
import AgentMatches from "../AgentMatches";
import AgentTransactions from "../AgentTransactions";
// TODO: move this out to the tabs component (like consumer)
import AgentCommunications from "./tabs/Communications";

import { updateAgentStatus, allowZero, formatPhoneNumber } from "helpers";

import PopconfirmUpdater from "widgets/UpdateAgentStatus";
import PopconfirmStatusForm from "widgets/UpdateAgentStatus/PopconfirmStatusForm";

import { AGENT_STATUS_COLORS, AGENT_STATUSES } from "../../constants";

// Assets
import "./AgentProfile.scss";
import { ReactComponent as Badge } from "assets/safty-badge.svg";
import noAvatar from "assets/user-2.png";

const AgentProfileContainer = ({
  dispatch,
  match,
  history,
  agent,
  advisor
}) => {
  const { Item } = Descriptions;

  const updaterData = {
    statusReason: "",
    agentStatusId: 0,
    agentUserKey: agent.agentId
  };

  const tabList = [
    {
      key: "profile",
      tab: "Agent Profile"
    },
    {
      key: "bids",
      tab: "Agent Bids"
    },
    {
      key: "communications",
      tab: "Agent Communications"
    },
    {
      key: "reviews",
      tab: "Agent Reviews"
    },
    {
      key: "matches",
      tab: "Agent Matches"
    },
    {
      key: "transactions",
      tab: "Agent Transactions"
    }
  ];

  const profileKeys = [
    "email",
    "phoneNumber",
    "yearLicensed",
    "license",
    "agentStatus",
    "agentStatusReason",
    "buyRebate",
    "sellRebate",
    "brokerageName",
    "brokerageAddress",
    "languages",
    "firstName",
    "lastName",
    "yearsOfExperience",
    "areas",
    "buy_services",
    "sell_services",
    "message",
    "agentSpeciality",
    "transactions",
    "brokerageStateOrProvince",
    "isDocuSignVisited",
    "verifiedPhone",
    "referralSigned"
  ];
  const PROFILE_KEYS_LENGTH = profileKeys.length;

  let profileItems = [];

  // TODO: replace kebabCase with adp generating function
  const agentData = {
    loading: true,
    name: agent ? `${agent["firstName"]} ${agent["lastName"]}` : "Agent Smith",
    link: agent ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${
          process.env.REACT_APP_NOBUL_DOMAIN
        }/top-real-estate-agent/${kebabCase(
          [agent.firstName, agent.LastName].filter(Boolean)
        )}/${agent.agentFirebaseId}`}
      >
        View ADP
      </a>
    ) : null,
    avatar: agent && agent["avatarUrl"] ? agent["avatarUrl"] : noAvatar,
    profile: <Descriptions column={2} children={profileItems} />,
    bids: <BidsTable history={history} />,
    reviews: <AgentReviews reviews={agent.agent_reviews} />,
    communications: (
      <AgentCommunications
        history={history}
        match={match}
        agentId={agent.agentId}
      />
    ),
    matches: <AgentMatches history={history} match={match} />,
    transactions: (
      <AgentTransactions
        dispatch={dispatch}
        advisor={advisor}
        history={history}
        agent={agent}
      />
    )
  };

  // useEffect(() => {
  let buy_services = [];
  let sell_services = [];

  // this is synchronous and can throw weird errors if default values depend on payload
  if (agent) {
    for (let i = 0; i < PROFILE_KEYS_LENGTH; i++) {
      const field = profileKeys[i];
      let value = allowZero(agent[field]);

      if (Array.isArray(value)) {
        if (field === "buy_services") {
          buy_services = value.map((service, index) => {
            if (service.available) {
              return (
                <span key={service.service_name}>
                  <Typography.Text>{service.service_name}</Typography.Text>
                  {index < value.length - 1 && <Divider type="vertical" />}
                </span>
              );
            }
            return null;
          });
          value = buy_services;
        } else if (field === "sell_services") {
          sell_services = value.map((service, index) => {
            if (service.available) {
              return (
                <span key={service.service_name}>
                  <Typography.Text>{service.service_name}</Typography.Text>
                  {index < value.length - 1 && <Divider type="vertical" />}
                </span>
              );
            }
            return null;
          });
          value = sell_services;
        } else {
          value = value.map((item, index) => (
            <span key={item}>
              <Typography.Text>{item}</Typography.Text>
              {index < value.length - 1 && <Divider type="vertical" />}
            </span>
          ));
        }
      }

      if (field === "transactions") {
        value = agent.transactions?.total || "N/A";
      }

      if (field === "verifiedPhone") {
        value = startCase(agent.verifiedPhone) || "N/A";
      }

      if (field === "phoneNumber") {
        value = formatPhoneNumber(agent.phoneNumber);
      }

      if (field === "agentStatus") {
        value = (
          <PopconfirmUpdater
            key={agent.agentFirebaseId}
            PopconfirmForm={PopconfirmStatusForm}
            popconfirmFormProps={{ name: agent.firstName }}
            onConfirm={updateAgentStatus}
            data={updaterData}
          >
            <Tag
              color={
                AGENT_STATUS_COLORS[AGENT_STATUSES.indexOf(agent.agentStatus)]
              }
              style={{ cursor: "pointer" }}
            >
              {upperCase(agent.agentStatus)}
              {agent.agentStatus === "verified" && (
                <Badge
                  style={{
                    width: "auto",
                    height: "16px",
                    verticalAlign: "sub"
                  }}
                />
              )}
            </Tag>
          </PopconfirmUpdater>
        );
      }

      if (field === "isDocuSignVisited") {
        value = agent[field] ? "Visited" : "Not Visited";
      }

      if (field === "referralSigned") {
        value = agent[field] ? "Signed" : "Not Signed";
      }

      if (field === "license") {
        value = agent[field] || "N/A";
      }

      const title = (function (field) {
        switch (field) {
          case "rebateOffer":
            return "Rebate to Buyer";
          case "buyerCommission":
            return "Rebate to Seller";
          case "isDocuSignVisited":
            return "DocuSign Visited";
          case "verifiedPhone":
            return "Phone Status";
          default:
            return startCase(field);
        }
      })(field);

      if (field === "agentStatusReason" && !agent.agentStatusReason) {
        continue;
      } else {
        profileItems.push(
          <Item key={field} label={title}>
            {value}
          </Item>
        );
      }
    }
  }

  if (agent["profilePicturePath"]) {
    agentData.avatar = `${process.env.REACT_APP_AGENT_PROFILE_PHOTO}${agent["profilePicturePath"]}/avatar`;
  }

  agentData.loading = false;
  agentData.name = `${agent["firstName"]} ${agent["lastName"]}`;

  return (
    <AgentProfile
      tabs={tabList}
      content={agentData}
      agent={agent}
      match={match}
      history={history}
    />
  );
};

const mapStateToProps = ({ agentsView, advisor }) => {
  const { agentProfile } = agentsView;

  return {
    agent: agentProfile,
    advisor
  };
};

export default connect(mapStateToProps)(AgentProfileContainer);
