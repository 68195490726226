import React, { useCallback, useMemo } from "react";
import { capitalize } from "lodash";
import {
  Table,
  Drawer,
  Descriptions,
  Typography,
  Button,
  Icon,
  Popover,
  Divider
} from "antd";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import setBidStatusbadge from "helpers/setBidStatusbadge";

const { Item } = Descriptions;

const BidSessionDrawer = ({
  viewingBidSessions,
  bidSessions,
  firstName,
  lastName
}) => {
  const { bid } = useParams();
  const history = useHistory();

  const onRowKey = useCallback((record) => record.user_key, []);

  const columns = useMemo(
    () => [
      {
        title: "Status",
        dataIndex: "bid_status",
        key: "bid_status",
        render: (text) => setBidStatusbadge(text, true)
      },
      {
        title: "Autobid",
        dataIndex: "is_autobid",
        key: "is_autobid",
        render: (text, record) =>
          record.bid_status === "System-Bid_No-Quote" ||
          record.bid_status === "System-Bid_Declined"
            ? "Experiment"
            : text,
        sorter: (a, b) => a.is_autobid.localeCompare(b.is_autobid)
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: (a, b) => a.agent_name.localeCompare(b.agent_name)
      },
      {
        title: "Submit Time",
        dataIndex: "submit_time",
        key: "submit_time",
        sorter: (a, b) => a.submit_time.localeCompare(b.submit_time)
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
        sorter: (a, b) => a.score - b.score
      },
      {
        title: "Viewed",
        dataIndex: "user_viewed_agent_profile",
        key: "user_viewed_agent_profile",
        render: (text) => (text ? text : " ")
        // sorter: (a, b) => a.user_viewed_agent_profile.localeCompare(b.user_viewed_agent_profile),
      },
      {
        title: "Exp.",
        dataIndex: "years_of_experience",
        key: "years_of_experience",
        sorter: (a, b) => a.years_of_experience - b.years_of_experience
      },
      {
        title: "Rebate ($)",
        dataIndex: "rebate_cash",
        key: "rebate_cash",
        className: "no-wrap",
        render: (rebate) =>
          rebate.buy && rebate.sell
            ? `Buy: ${rebate.buy} - Sell: ${rebate.sell}`
            : `${capitalize(
                (rebate.buy && "Buy") || (rebate.sell && "Sell")
              )}: ${rebate.buy || rebate.sell}`
      },
      {
        title: "Rebate (%)",
        dataIndex: "rebate_offer",
        key: "rebate_offer",
        className: "no-wrap",
        render: (rebate) =>
          rebate.buy && rebate.sell
            ? `Buy: ${rebate.buy} - Sell: ${rebate.sell}`
            : `${capitalize(
                (rebate.buy && "Buy") || (rebate.sell && "Sell")
              )}: ${rebate.buy || rebate.sell}%`
      },
      {
        title: "Services",
        dataIndex: "services",
        key: "services",
        render: (text) =>
          text
            ? text.map((service, index) => (
                <span key={service}>
                  <Typography.Text>{service}</Typography.Text>
                  {index < text.length - 1 && <Divider type="vertical" />}
                </span>
              ))
            : "N/A"
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        render: (text, record) => (
          <Popover
            placement="left"
            title={`${record.agent_name}'s Message`}
            content={<p style={{ width: 320 }}>{text}</p>}
            trigger="click"
          >
            <Button
              disabled={!text}
              type={text ? "primary" : "file-unknown"}
              block
            >
              <Icon type="message" />
              {text ? "Show Message" : "No Message"}
            </Button>
          </Popover>
        )
      }
    ],
    []
  );

  const title =
    bidSessions.bid_session_status === "System-Bid_No-Quote" ? (
      <>
        Bid Request Experiment{" "}
        <Typography.Text code>
          <Icon type="experiment" style={{ color: "#00b2bf" }} /> No Default
          Quotes
        </Typography.Text>
      </>
    ) : (
      "Bid Request"
    );

  return (
    <Drawer
      visible={Boolean(bid)}
      closable
      width="85%"
      onClose={history.goBack}
    >
      <Typography.Title level={3}>{title}</Typography.Title>
      <Descriptions bordered layout="vertical" size="small">
        <Item label="Bid Status">
          {setBidStatusbadge(bidSessions[bid]["bid_session_status"])}
        </Item>
        <Item label="Deal Date">{bidSessions[bid]["bid_session_start"]}</Item>
        <Item label="Time Remaining to Close">
          {viewingBidSessions["time_remaining_to_bid"] || "N/A"}
        </Item>
        <Item label="Time Remaining to Accept">
          {bidSessions[bid]["acceptance_window_ends_in"]}
        </Item>
        <Item label="Client Name">
          {firstName} {lastName}
        </Item>
        <Item label="Buy/Sell">{bidSessions[bid]["transaction_type"]}</Item>
        <Item label="Location">{bidSessions[bid]["location"].join(", ")}</Item>
        {bidSessions[bid]["property_types"].sell && (
          <Item label="Selling property">
            {bidSessions[bid]["property_types"].sell.join(", ")}
          </Item>
        )}
        {bidSessions[bid]["property_types"].buy && (
          <Item label="Buying property">
            {bidSessions[bid]["property_types"].buy.join(", ")}
          </Item>
        )}
        {bidSessions[bid]["budget"].buy && (
          <Item label="Buying price">{bidSessions[bid]["budget"].buy}</Item>
        )}
        {bidSessions[bid]["budget"].sell && (
          <Item label="Selling price">{bidSessions[bid]["budget"].sell}</Item>
        )}
      </Descriptions>
      <Typography.Title level={3} style={{ marginTop: 24 }}>
        Agent Bids
      </Typography.Title>
      <Table
        bordered={false}
        dataSource={viewingBidSessions}
        columns={columns}
        rowKey={onRowKey}
        pagination={{ pageSize: 10 }}
        size="small"
      />
    </Drawer>
  );
};

const mapStateToProps = ({
  consumersView: {
    viewingBidSessions,
    bidSessions,
    consumerProfile: { firstName, lastName }
  }
}) => ({
  bidSessions,
  viewingBidSessions,
  firstName,
  lastName
});

export default connect(mapStateToProps)(BidSessionDrawer);
