import { message } from "antd";
import lowerCase from "lodash/lowerCase";

import { getJWTtoken } from "./getJWTtoken";

export const nobul_authUpdate = (payload, name) => {
  if (!payload.reason) {
    return message.warn(
      `Please enter a reason to ${lowerCase(payload.action)} ${name}`
    );
  }

  const key = payload.action;

  message.loading(
    {
      key,
      content: `${lowerCase(payload.action)} in process...`
    },
    0
  );

  return getJWTtoken().then(({ token }) =>
    fetch(`${process.env.REACT_APP_CALL_CLOUD_FUNCTION}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ name: "nobul_authUpdate", payload })
    })
      .then((response) => response.json())
      .then((response) => {
        message.destroy(key);

        if (response.error) {
          message.error(response.error || `Something went wrong...`);
        } else {
          message.success(response.message);
        }

        return response;
      })
      .catch((error) => {
        console.error(error);

        message.destroy(key);
        message.error(error.error);
      })
  );
};
