import React from "react";
import { Route } from "react-router-dom";

import {
  AdvisorCurrentThreads,
  AdvisorUnassignedThreads
} from "./AdvisorMessagesTable";
import CommunicationsMessages from "components/Communications/CommunicationsMessages";

export const AdvisorMessages = ({ history }) => {
  return (
    <div style={{ marginTop: 24 }}>
      <AdvisorCurrentThreads history={history} />
      <br />
      <br />
      <AdvisorUnassignedThreads history={history} title="Unassigned Messages" />
      <Route
        path="/:userRoute/:id/:threadId"
        component={CommunicationsMessages}
      />
    </div>
  );
};
