import {
  CONSUMER_LISTINGS_ERROR,
  FETCH_CONSUMER_LISTINGS,
  SET_CONSUMER_LISTINGS,
  SET_CONSUMER_LISTINGS_PAGE
} from "../actionTypes";

export const fetchUserListings = () => {
  return {
    type: FETCH_CONSUMER_LISTINGS
  };
};

export const setConsumerListings = (listings, currentFirebaseUid) => {
  return {
    type: SET_CONSUMER_LISTINGS,
    listings,
    currentFirebaseUid
  };
};

export const consumerListingsError = () => {
  return {
    type: CONSUMER_LISTINGS_ERROR
  };
};

export const setConsumerListingsPage = (page, pageKey) => {
  return {
    type: SET_CONSUMER_LISTINGS_PAGE,
    page,
    pageKey
  };
};
